import React, { useState } from 'react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from '@vercel/analytics/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as CustomThemeProvider } from './ThemeContext';  
import { AuthProvider, useAuth } from './context/AuthContext'; 
import LandingPage from './components/LandingPage';
import { Routes, Route, Link, useLocation, Navigate, Outlet } from 'react-router-dom';
import PromptBuilder from './components/PromptBuilder'; 
import { Edit, User, LogOut, Star, Settings, TestTubes, TestTubeDiagonal, FileText, ListOrdered, FileEdit, Youtube, Sparkles, CreditCard } from 'lucide-react';
import UserMenu from './components/UserMenu'; 
import EditPrompt from './components/EditPrompt';
import { ChevronRight } from '@mui/icons-material';
import ProductReviewsPage from './components/Articles/Pages/ProductReviewsPage';
import ListicleGenerator from './components/ListicleGenerator';
import ArticleRewritesPage from './components/Articles/Pages/ArticleRewritesPage';
import SingleArticle from './components/SingleArticle';
import UnauthorizedError from './components/UnauthorizedError';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import SubscriptionProtectedRoute from './components/SubscriptionProtectedRoute';
import PromptBuilderLanding from './components/PromptBuilderLanding';
import CustomPromptEditor from './components/CustomPromptEditor';
import TemplateEditor from './components/TemplateEditor';
import HomePage from './components/HomePage';
import FacebookPostGenerator from './components/FacebookPostGenerator';
import FacebookPostIdeas from './components/FacebookPostIdeas';


import {
  Avatar,
  Menu,
  MenuItem,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon, 
  ListItemText,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Container,
  CircularProgress,
  Button,
  Divider
} from '@mui/material';
import {
  Description as ArticleIcon,
  Image as ImageIcon, 
  Dashboard as DashboardIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import SavedPrompts from './components/SavedPrompts';
import ArticleGenerator from './components/ArticleGenerator';
import ImageGenerator from './components/ImageGenerator';
import UserDashboard from './components/UserDashboard';
import { useAuth0 } from '@auth0/auth0-react';
import Callback from './components/Callback';
import config from './config'; 
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import SubscriptionPlans from './components/SubscriptionPlans';
import DrawerContent from './components/DrawerContent';
import UrlRewriter from './components/URLRewriter';


const drawerWidth = 280;


function RequireAuth() {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

function PageTitle() {
  const location = useLocation();
  
  const getTitleWithIcon = (title, icon) => (
    <Box sx={{ marginLeft: '150px', display: 'flex', alignItems: 'center', gap: 1 }}>
      {icon}
      <Typography 
        variant="h6" 
        sx={{ 
          fontSize: '1.4rem',
          fontWeight: 600,
          color: '#E04C16',
          fontFamily: 'League Spartan',
        }}
      >
        {title}
      </Typography>
    </Box>
  );

  switch (location.pathname) {
    case '/prompts/new':
    case '/prompts/saved':
      return getTitleWithIcon('PromptLab', <TestTubeDiagonal size={24} color="#E04C16" />);
    case '/articles/single':
      return getTitleWithIcon('Single Article Creator', <FileText size={24} color="#E04C16" />);
    case '/articles/standard':
      return getTitleWithIcon('Bulk Article Creator', <FileText size={24} color="#E04C16" />);
    case '/articles/reviews':
      return getTitleWithIcon('Product Reviews', <Star size={24} color="#E04C16" />);
    case '/articles/lists':
      return getTitleWithIcon('Listicle Articles', <ListOrdered size={24} color="#E04C16" />);
    case '/articles/rewrites':
      return getTitleWithIcon('Article Rewrites', <FileEdit size={24} color="#E04C16" />);
    default:
      return getTitleWithIcon('Image Generator', <Sparkles size={24} color="#E04C16" />);
  }
}

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [promptMenuOpen, setPromptMenuOpen] = useState(false);
  const [articleMenuOpen, setArticleMenuOpen] = useState(false);

  const [isGenerating, setIsGenerating] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const { isLoading, isAuthenticated, logout, user } = useAuth0();
  const location = useLocation();

  const handleGeneratingState = (state) => {
    setIsGenerating(state);
  };

  
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  
  if (!isAuthenticated && location.pathname !== '/callback') {
    return <LandingPage />;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
      clearLocalStorage: true
    });
  };

  


return (
    <CustomThemeProvider>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            boxShadow: 'none',
            borderBottom: 'none',
            background: 'transparent',
          }}
        >
        <Toolbar sx={{ minHeight: '64px !important' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          {user && (
            <UserMenu
              user={user}
              onLogout={handleLogout}
            />
          )}
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth 
            },
          }}
        >
          <DrawerContent isGenerating={isGenerating} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </Drawer>
        
        {/* Desktop Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: isCollapsed ? 80 : drawerWidth,  
              transition: 'width 0.2s',
              boxShadow: '4px 0 8px -2px rgba(0, 0, 0, 0.1)',
              borderRight: 'none',
              overflowX: 'hidden',  
            },
          }}
          open
        >
          <DrawerContent isGenerating={isGenerating} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </Drawer>

      </Box>

      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: { xs: '8px', sm: '4px' },
        }}
      >
          <Container maxWidth="lg" disableGutters>
            <Routes>
              <Route path="/callback" element={<Callback />} />

             
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/subscription" element={<SubscriptionPlans />} />
             
              
              {/* Protected routes - only accessible when authenticated */}
              {isAuthenticated ? (
                <>
                <Route element={<SubscriptionProtectedRoute />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/articles/single" element={<SingleArticle onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/articles/standard" element={<ArticleGenerator onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/articles/reviews" element={<ProductReviewsPage onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/articles/lists" element={<ListicleGenerator onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/url-rewriter" element={<UrlRewriter onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/articles/rewrites" element={<ArticleRewritesPage onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/prompts/new" element={<PromptBuilderLanding />} />
                  <Route path="/prompts/builder" element={<PromptBuilder onGeneratingChange={handleGeneratingState} />} />
                  <Route path="/prompts/templates" element={<TemplateEditor />} />
                  <Route path="/prompts/saved" element={<SavedPrompts />} />
                  <Route path="/prompts/editor" element={<CustomPromptEditor />} />
                  <Route path="/prompts/edit/:promptId" element={<EditPrompt />} />
                  <Route path="/social/facebook" element={<FacebookPostGenerator />} />
                  <Route path="/social/facebookideas" element={<FacebookPostIdeas />} />
                  <Route path="/images" element={<ImageGenerator />} />
                  <Route path="/dashboard" element={<UserDashboard />} />

                 
                </Route>
                </>
              ) : (
                <Route path="*" element={<Navigate to="/" replace />} />
              )}
            </Routes>
          </Container>
          <SpeedInsights />
          <Analytics />
        </Box>
      </Box>
     
    </CustomThemeProvider>

  );
}

export default App;