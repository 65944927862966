import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './context/AuthContext';
import Auth0Wrapper from './Auth0Wrapper';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="dev-37mvwswd3e73pa07.eu.auth0.com"
        clientId="i4jaANoox38PiYlyOTC5e2XY8NlctjnC"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/callback`,
          audience: "https://TrevoWriter",
          scope: "openid profile email offline_access",
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
      >
        <AuthProvider>
          <Auth0Wrapper> 
            <App />
          </Auth0Wrapper>
        </AuthProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);