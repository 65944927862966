import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../utils/apiUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { 
    getAccessTokenSilently, 
    isAuthenticated: auth0IsAuthenticated,
    user,
    isLoading: auth0Loading
  } = useAuth0();
  
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [initialAuthDone, setInitialAuthDone] = useState(false);

  const cleanup = useCallback(() => {
    localStorage.removeItem('auth_token');
    setToken(null);
    apiClient.setAuthToken(null);
  }, []);

  const getToken = useCallback(async () => {
    try {
      // Wait for user to be available
      if (!user) {
        console.log('Waiting for user before getting token');
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      
      const newToken = await getAccessTokenSilently({
        authorizationParams: {
          // Add explicit audience and scope
          audience: 'https://TrevoWriter',  // Your API identifier
          scope: 'openid profile email'
        }
      });
      
      console.log('Token obtained successfully');
      setToken(newToken);
      apiClient.setAuthToken(newToken);
      return newToken;
    } catch (err) {
      console.error('Token error:', err);
      setError('Failed to get authentication token');
      cleanup();
      throw err;
    }
}, [getAccessTokenSilently, cleanup, user]);

useEffect(() => {
  const initAuth = async () => {
    console.log('Init auth state:', { auth0Loading, auth0IsAuthenticated, userSub: user?.sub });
    
    if (!auth0Loading && auth0IsAuthenticated && user) {
      try {
        const token = await getToken();
        
        // Check if this is a new database signup
        const isDbConnection = user.sub?.startsWith('auth0|');
        const userCreatedAt = new Date(user.created_at || user.updated_at);
        const isRecentlyCreated = Date.now() - userCreatedAt.getTime() < 60000;
        
        console.log('User auth details:', {
          isDbConnection,
          sub: user?.sub,
          hasToken: !!token
        });
          if (isDbConnection && isRecentlyCreated && !initialAuthDone) {
            // This is a new database signup
            setIsNewUser(true);
            navigate('/subscription', { 
              state: { newSignup: true },
              replace: true 
            });
          } else {
            // Check subscription status for existing users
            try {
              const response = await apiClient.request({
                method: 'GET',
                url: '/api/stripe/subscription'
              });
              
              const subscription = response?.subscription;
              if (!subscription?.status === 'active') {
                navigate('/subscription', { 
                  state: { needsSubscription: true },
                  replace: true 
                });
              }
            } catch (error) {
              console.error('Subscription check error:', error);
              navigate('/subscription', { 
                state: { error: 'Failed to verify subscription status' },
                replace: true
              });
            }
          }
          
          setInitialAuthDone(true);
        } catch (err) {
          console.error('Error initializing auth:', err);
        }
      } else if (!auth0Loading) {
        cleanup();
      }
      setLoading(false);
    };

    initAuth();
  }, [auth0IsAuthenticated, auth0Loading, user, getToken, cleanup, navigate, initialAuthDone]);

  const authenticatedRequest = useCallback(async (requestFn) => {
    if (!auth0IsAuthenticated) {
      throw new Error('User not authenticated');
    }

    try {
      const currentToken = token || await getToken();
      
      try {
        return await requestFn(currentToken);
      } catch (err) {
        if (err.message?.includes('401') || err.message?.includes('expired')) {
          const newToken = await getToken();
          return await requestFn(newToken);
        }
        throw err;
      }
    } catch (err) {
      console.error('Authentication error:', err);
      throw new Error(err.message || 'Authentication failed');
    }
  }, [auth0IsAuthenticated, getToken, token]);

  return (
    <AuthContext.Provider value={{
      token,
      loading: loading || auth0Loading,
      error,
      user,
      isAuthenticated: auth0IsAuthenticated,
      isNewUser,
      authenticatedRequest,
      clearError: () => setError(null),
      cleanup
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;