import React, { useState, useEffect, useRef } from 'react';
import { 
  Canvas,
  IText,
  Rect,
  Circle,
  Triangle,
  Image,
  filters
} from 'fabric';
import { useAIModels } from '../hooks/useAIModels';
import { useAuth } from '../context/AuthContext';
import aiClient from '../api/clients/AiClient';
import apiClient from '../utils/apiUtils';
import PageHeader from './PageHeader';
import imageClient from '../api/clients/ImageClient';
import InlineMemeGenerator from './InLineMemeGenerator';

import { 
  FileText, 
  Image as ImageIcon, 
  Type, 
  Square, 
  Circle as CircleIcon, 
  Triangle as TriangleIcon, 
  Trash2, 
  Download,
  ImagePlus,
  Wand2,
  Bold,
  Italic,
  Paintbrush,
  PenTool,
  Maximize,
  Palette,
  RefreshCw,
  X,
  ZoomIn,
  ZoomOut
} from 'lucide-react';

const formatCaptionForStyle = (caption, style) => {
  switch (style) {
    case 'classic':
      return caption.toUpperCase();
    case 'modern':
    case 'gen-z':
      return caption.toLowerCase();
    default:
      return caption;
  }
};

const FacebookPostGenerator = () => {
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); 
  const [textColor, setTextColor] = useState('#ffffff');
  const [fontSize, setFontSize] = useState(40);
  const [fontFamily, setFontFamily] = useState('Inter');
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [strokeColor, setStrokeColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [canvasSize, setCanvasSize] = useState('1200x630');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [shadowOffsetX, setShadowOffsetX] = useState(0);
  const [shadowOffsetY, setShadowOffsetY] = useState(0);
  const [shadowBlur, setShadowBlur] = useState(0);
  const [shadowColor, setShadowColor] = useState('#000000');
  const [isMemeModalOpen, setIsMemeModalOpen] = useState(false);
  const [memeTemplates, setMemeTemplates] = useState([]);
  const [loadingMemes, setLoadingMemes] = useState(false);
  const [contrast, setContrast] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [brightness, setBrightness] = useState(0);
  const [fillColor, setFillColor] = useState('#ff0000');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    severity: 'success',
    message: ''
  });
  const [captionOptions, setCaptionOptions] = useState([]);
  const [lastMemeOptions, setLastMemeOptions] = useState({
    style: 'classic',
    tone: 'funny',
    inputMode: 'image',
    textDescription: '',
    customNotes: ''
  });
  const [zoomLevel, setZoomLevel] = useState(1);
  const { authenticatedRequest } = useAuth();
  const { models: availableModels } = useAIModels(authenticatedRequest);

  // Fetch meme templates from Imgflip API
  useEffect(() => {
    const fetchMemeTemplates = async () => {
      setLoadingMemes(true);
      try {
        const response = await fetch('https://api.imgflip.com/get_memes');
        const data = await response.json();
        if (data.success) {
          setMemeTemplates(data.data.memes);
        } else {
          console.error('Failed to fetch memes:', data.error_message);
        }
      } catch (error) {
        console.error('Error fetching meme templates:', error);
      } finally {
        setLoadingMemes(false);
      }
    };

    fetchMemeTemplates();
  }, []);

  // Initialize Fabric.js canvas
  useEffect(() => {
    if (!canvasRef.current) return;
  
    const fabricCanvas = new Canvas(canvasRef.current, {
      width: 800,
      height: 600,
      backgroundColor: '#f0f0f0',
      preserveObjectStacking: true,
      selection: true,
    });
  
    fabricCanvas.on('selection:created', (e) => {
      const obj = e.selected[0];
      setSelectedObject(obj);
      if (obj.type === 'image') {
        setSelectedImage(obj);
      }
      updateProperties(obj);
    });
  
    fabricCanvas.on('selection:updated', (e) => {
      const obj = e.selected[0];
      setSelectedObject(obj);
      if (obj.type === 'image') {
        setSelectedImage(obj);
      }
      updateProperties(obj);
    });
  
    fabricCanvas.on('selection:cleared', () => {
      setSelectedObject(null);
    });
  
    const handleKeyDown = (e) => {
      const activeElement = document.activeElement;
      const activeObject = fabricCanvas.getActiveObject();
  
      if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
        return;
      }
  
      if (!activeObject) return;
  
      if (activeObject.type === 'i-text' && activeObject.isEditing && e.key === 'Backspace') {
        return;
      }
  
      if ((e.key === 'Delete' || e.key === 'Backspace') && activeObject) {
        deleteSelected(fabricCanvas);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    setCanvas(fabricCanvas);
  
    return () => {
      fabricCanvas.dispose();
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Helper function to update properties based on selected object
  const updateProperties = (obj) => {
    if (obj instanceof IText) {
      setTextColor(obj.fill || '#ffffff');
      setFontSize(obj.fontSize || 40);
      setFontFamily(obj.fontFamily || 'Inter');
      setStrokeColor(obj.stroke || '#000000');
      setStrokeWidth(obj.strokeWidth || 0);
      setBackgroundColor(obj.backgroundColor || null);
      setIsBold(obj.fontWeight === 'bold');
      setIsItalic(obj.fontStyle === 'italic');
      if (obj.shadow) {
        setShadowOffsetX(obj.shadow.offsetX || 0);
        setShadowOffsetY(obj.shadow.offsetY || 0);
        setShadowBlur(obj.shadow.blur || 0);
        setShadowColor(obj.shadow.color || '#000000');
      } else {
        setShadowOffsetX(0);
        setShadowOffsetY(0);
        setShadowBlur(0);
        setShadowColor('#000000');
      }
    } else if (obj instanceof Image) {
      const filterList = obj.filters || [];
      setContrast(filterList.find(f => f.type === 'Contrast')?.contrast || 0);
      setSaturation(filterList.find(f => f.type === 'Saturation')?.saturation || 0);
      setBrightness(filterList.find(f => f.type === 'Brightness')?.brightness || 0);
    } else if (obj instanceof Rect || obj instanceof Circle || obj instanceof Triangle) {
      setFillColor(obj.fill || '#ff0000');
      setStrokeColor(obj.stroke || '#000000');
      setStrokeWidth(obj.strokeWidth || 0);
    }
    setOpacity(obj.opacity || 1);
  };

  // Handle canvas size and zoom changes
  useEffect(() => {
    if (!canvas) return;
  
    const [width, height] = canvasSize.split('x').map(Number);
    canvas.setDimensions({ width, height });
  
    canvas.getObjects().forEach((obj) => {
      fitObjectToCanvas(obj);
    });
  
    canvasRef.current.style.transform = `scale(${zoomLevel})`;
    canvas.requestRenderAll();
  }, [canvas, canvasSize, zoomLevel]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file || !canvas) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const imgUrl = event.target.result;
      const imgElement = document.createElement('img');
      imgElement.src = imgUrl;
      imgElement.onload = () => {
        const imgInstance = new Image(imgElement, {
          selectable: true,
          hasControls: true,
          hasBorders: true,
          lockUniScaling: false,
        });
        fitObjectToCanvas(imgInstance);
        canvas.add(imgInstance);
        canvas.setActiveObject(imgInstance);
        canvas.requestRenderAll();
      };
    };
    reader.readAsDataURL(file);
    e.target.value = '';
  };

  // Add meme image from Imgflip
  const addMemeImage = (url) => {
    if (!canvas) return;

    const imgElement = document.createElement('img');
    imgElement.crossOrigin = 'Anonymous';
    imgElement.src = url;
    imgElement.onload = () => {
      const imgInstance = new Image(imgElement, {
        selectable: true,
        hasControls: true,
      });
      fitObjectToCanvas(imgInstance);
      canvas.add(imgInstance);
      canvas.setActiveObject(imgInstance);
      canvas.requestRenderAll();
      setIsMemeModalOpen(false);
    };
    imgElement.onerror = (err) => {
      console.error("Failed to load meme image:", err);
    };
  };

  // Add text
  const addText = () => {
    if (!canvas) return;

    const text = new IText('Edit Me', {
      left: canvas.width / 2,
      top: canvas.height / 2,
      originX: 'center',
      originY: 'center',
      fontSize: 40,
      fontFamily: 'Inter',
      fill: '#ffffff',
      stroke: '#000000',
      strokeWidth: 0,
      textAlign: 'center',
      backgroundColor: null,
      selectable: true,
      editable: true,
      hasControls: true,
    });

    canvas.add(text);
    canvas.setActiveObject(text);
    canvas.requestRenderAll();
  };

  // Add rectangle
  const addRectangle = () => {
    if (!canvas) return;

    const rect = new Rect({
      left: canvas.width / 2,
      top: canvas.height / 2,
      originX: 'center',
      originY: 'center',
      width: 100,
      height: 100,
      fill: '#ff0000',
      stroke: '#000000',
      strokeWidth: 2,
      selectable: true,
      hasControls: true,
    });

    canvas.add(rect);
    canvas.setActiveObject(rect);
    canvas.requestRenderAll();
  };

  // Add circle
  const addCircle = () => {
    if (!canvas) return;

    const circle = new Circle({
      left: canvas.width / 2,
      top: canvas.height / 2,
      originX: 'center',
      originY: 'center',
      radius: 50,
      fill: '#00ff00',
      stroke: '#000000',
      strokeWidth: 2,
      selectable: true,
      hasControls: true,
    });

    canvas.add(circle);
    canvas.setActiveObject(circle);
    canvas.requestRenderAll();
  };

  // Add triangle
  const addTriangle = () => {
    if (!canvas) return;

    const triangle = new Triangle({
      left: canvas.width / 2,
      top: canvas.height / 2,
      originX: 'center',
      originY: 'center',
      width: 100,
      height: 100,
      fill: '#0000ff',
      stroke: '#000000',
      strokeWidth: 2,
      selectable: true,
      hasControls: true,
    });

    canvas.add(triangle);
    canvas.setActiveObject(triangle);
    canvas.requestRenderAll();
  };

  // Delete selected object
  const deleteSelected = (fabricCanvas) => {
    const activeObject = fabricCanvas.getActiveObject();
    if (activeObject) {
      fabricCanvas.remove(activeObject);
      fabricCanvas.discardActiveObject();
      fabricCanvas.requestRenderAll();
      setSelectedObject(null);
    }
  };

  // Save canvas as PNG
  const saveImage = () => {
    if (!canvas) return;
    const dataUrl = canvas.toDataURL({
      format: 'png',
      quality: 1,
    });
    const link = document.createElement('a');
    link.download = 'facebook-post.png';
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Rotate selected object
  const rotateObject = (angle) => {
    if (!selectedObject || !canvas) return;
    selectedObject.rotate(selectedObject.angle + angle);
    canvas.requestRenderAll();
  };

  // Flip selected object
  const flipObject = (direction) => {
    if (!selectedObject || !canvas) return;
    if (direction === 'horizontal') {
      selectedObject.flipX = !selectedObject.flipX;
    } else if (direction === 'vertical') {
      selectedObject.flipY = !selectedObject.flipY;
    }
    canvas.requestRenderAll();
  };

  // Fit object to canvas bounds
  const fitObjectToCanvas = (obj) => {
    if (!canvas) return;

    const scaleX = (canvas.width * 0.9) / obj.width;
    const scaleY = (canvas.height * 0.9) / obj.height;
    const scale = Math.min(scaleX, scaleY);
    obj.scale(scale);
    obj.set({
      left: (canvas.width - obj.width * scale) / 2,
      top: (canvas.height - obj.height * scale) / 2,
    });
  };

  // Handle canvas size change
  const handleSizeChange = (e) => {
    const size = e.target.value;
    setCanvasSize(size);
  };

  // Zoom functions
  const zoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.1, 2)); // Max zoom 200%
  };

  const zoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.1, 0.1)); // Min zoom 10%
  };

  const fitToView = () => {
    const container = document.querySelector('.canvas-container');
    const canvasEl = canvasRef.current;
    if (container && canvasEl) {
      const scale = Math.min(
        (container.clientWidth - 40) / canvasEl.width,
        (container.clientHeight - 40) / canvasEl.height
      );
      setZoomLevel(scale);
    }
  };

  useEffect(() => {
    const initializeAI = async () => {
      if (availableModels.length > 0) {
        try {
          const keys = await authenticatedRequest(() => apiClient.getApiKeys());
          
          aiClient.setApiKeys(keys);
          aiClient.updateModelConfigs(availableModels);
          
          imageClient.setAvailableModels(availableModels);
          imageClient.setAiClient(aiClient);
          imageClient.setApiKeys(keys);
          
        } catch (error) {
          console.error('Error initializing AI client:', error);
          setError('Failed to initialize AI services');
        }
      }
    };
  
    initializeAI();
  }, [availableModels, authenticatedRequest]);

  useEffect(() => {
    if (!selectedObject || !canvas) return;

    if (selectedObject instanceof IText) {
      selectedObject.set({
        fill: textColor,
        fontSize,
        fontFamily,
        stroke: strokeColor,
        strokeWidth,
        backgroundColor: backgroundColor === 'transparent' ? null : backgroundColor,
        opacity,
        fontWeight: isBold ? 'bold' : 'normal',
        fontStyle: isItalic ? 'italic' : 'normal',
        shadow: shadowOffsetX || shadowOffsetY || shadowBlur ? {
          color: shadowColor,
          offsetX: shadowOffsetX,
          offsetY: shadowOffsetY,
          blur: shadowBlur,
        } : null,
      });
    } else if (selectedObject instanceof Image) {
      const filterList = [];
      if (contrast !== 0) filterList.push(new filters.Contrast({ contrast }));
      if (saturation !== 0) filterList.push(new filters.Saturation({ saturation }));
      if (brightness !== 0) filterList.push(new filters.Brightness({ brightness }));
      selectedObject.filters = filterList;
      selectedObject.applyFilters();
      selectedObject.set({ opacity });
    } else if (selectedObject instanceof Rect || selectedObject instanceof Circle || selectedObject instanceof Triangle) {
      selectedObject.set({
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth,
        opacity,
      });
    }
    canvas.requestRenderAll();
  }, [
    textColor, fontSize, fontFamily, strokeColor, strokeWidth, backgroundColor, opacity,
    isBold, isItalic, shadowOffsetX, shadowOffsetY, shadowBlur, shadowColor,
    contrast, saturation, brightness, fillColor, selectedObject, canvas
  ]);

  const fonts = [
    'Inter', 'Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Georgia', 'Verdana', 'Impact',
    'Montserrat', 'Roboto', 'Open Sans', 'Lato', 'Poppins', 'Raleway', 'Oswald', 'Playfair Display'
  ];

  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };

 const handleGenerateMemeText = async (options) => {
   const { inputMode, textDescription, style, tone, customNotes } = options;
 
   if (inputMode === 'image' && (!selectedImage || !(selectedImage instanceof Image))) {
     setError('Please select an image first');
     return;
   }
   if (inputMode === 'text' && !textDescription.trim()) {
     setError('Please provide a text description');
     return;
   }
 
   setIsGenerating(true);
 
   try {
     let promptInput;
     if (inputMode === 'image') {
       const imageElement = selectedImage._element;
       const MAX_SIZE = 800;
       const tempCanvas = document.createElement('canvas');
       let width = imageElement.width;
       let height = imageElement.height;
 
       if (width > height) {
         if (width > MAX_SIZE) {
           height *= MAX_SIZE / width;
           width = MAX_SIZE;
         }
       } else {
         if (height > MAX_SIZE) {
           width *= MAX_SIZE / height;
           height = MAX_SIZE;
         }
       }
 
       tempCanvas.width = width;
       tempCanvas.height = height;
       const ctx = tempCanvas.getContext('2d');
       ctx.imageSmoothingEnabled = true;
       ctx.imageSmoothingQuality = 'high';
       ctx.drawImage(imageElement, 0, 0, width, height);
 
       const base64Image = tempCanvas.toDataURL('image/jpeg', 0.6);
       promptInput = `Image data: ${base64Image}`;
     } else {
       promptInput = `User description: ${textDescription}`;
     }
 
     const auxModel = getAuxiliaryModel();
     if (!auxModel) {
       throw new Error('No AI model available');
     }
 
     
     let imageDescription = textDescription || '';
 
     if (inputMode === 'image') {
       const imageDescResponse = await aiClient.generateArticleContent({
         modelId: 'gpt-4o-mini',
         customPrompt: `Describe this image in 1-2 sentences. Focus on the main subjects, emotions, and scene. Keep it simple but informative.
 
         IMAGE DATA: ${promptInput}`,
         temperature: 0.6, 
       });
 
       imageDescription = imageDescResponse.trim();
       console.log('Generated Image Description:', imageDescription);
     }
 
     
    const response = await aiClient.generateArticleContent({
      modelId: auxModel,
      customPrompt: `You're a ruthless meme mastermind. Your only goal: 
      create 20 absolutely savage, viral meme captions that could dominate Reddit, Twitter, and Instagram.
    
      IMAGE DESCRIPTION: "${imageDescription}"
    
      🔥 MEME RULES:  
      - Captions must be **brutal, witty, unexpected, or absurd**  
      - If the image is dark, **lean into it** (no sugarcoating)  
      - If it's awkward, **make it worse**  
      - If it's wholesome, **twist it slightly**  
      - Use **short, high-impact phrasing (3-8 words max)**  
      - **No lazy humor** (avoid "When you..." or obvious jokes)  
      - Use "/" if the text should be split (TOP/BOTTOM format)  
    
      🎭 **STYLE OPTIONS:**  
      - Classic: **ALL CAPS, aggressive and punchy**  
      - Modern: **lowercase, dry humor, internet-style sarcasm**  
      - Wholesome: **Soft twist, still funny but warm**  
      - Surreal: **Weird, chaotic, darkly absurd**  
      - Gen Z: **Chaotic slang, unpredictable**  
    
      🎭 **TONE OPTIONS:**  
      - Funny: **Gut-busting, unexpected twists**  
      - Sarcastic: **Biting, ironic, dry AF**  
      - Relatable: **Too real, painfully accurate**  
      - Dramatic: **Over-the-top, "this is the end" energy**  
    
      ⚡ **EXAMPLES:**  
      (for a dog looking guilty)  
      - "lawyer up / it's over"  
      - "me pretending I didn't see it"  
      - "5 mins ago: 'trust me bro'"  
      - "silence is violence"  
    
      (for a kid in front of a burning house)  
      - "insurance? / never heard of it"  
      - "literally just vibes"  
      - "the family reunion went well"  
      - "not my fault / not my problem"  
    
      🎲 **EXTRA WILDCARD:**
      If you feel inspired, throw in a **totally unexpected, chaotic wildcard caption.**
    
      🔥 Now GO. Generate 20 **hilarious, viral-ready captions** that could break the internet.
      Return them in this format:
    
      1. Caption one  
      2. Caption two  
      3. Caption three  
      [...up to 20]`,
    
      temperature: 0.85, 
    });
    
 
    const captionList = response
    .trim()
    .split('\n')
    .map(line => line.replace(/^\d+\.\s*/, '').trim())  
    .map(line => line.replace(/^["']|["']$/g, ''))  
    .filter(line => line.length > 0);
  
       
     console.log('Generated Meme Captions:', captionList);
 
     const selectedCaption = captionList[0] || 'NO CAPTION GENERATED';
     const [topText, bottomText] = selectedCaption.includes('/') 
       ? selectedCaption.split('/').map(t => t.trim()) 
       : [selectedCaption, null];
 
     /** Apply Meme Text to Fabric.js */
     const textStyle = style === 'classic' ? {
       fontSize: 36,
       fontFamily: 'Impact',
       strokeWidth: 2,
       shadow: { color: 'rgba(0,0,0,0.6)', blur: 4, offsetX: 2, offsetY: 2 }
     } : style === 'modern' || style === 'gen-z' ? {
       fontSize: 30,
       fontFamily: 'Arial',
       strokeWidth: 0,
       shadow: { color: 'rgba(0,0,0,0.3)', blur: 2, offsetX: 1, offsetY: 1 }
     } : {
       fontSize: 32,
       fontFamily: 'Arial',
       strokeWidth: 1,
       shadow: { color: 'rgba(0,0,0,0.4)', blur: 3, offsetX: 1, offsetY: 1 }
     };
 
     const topTextObj = new IText(topText, {
       left: canvas.width / 2,
       top: 30,
       originX: 'center',
       originY: 'top',
       ...textStyle,
       fill: '#ffffff',
       stroke: '#000000',
       textAlign: 'center',
       backgroundColor: null,
       selectable: true,
       editable: true,
       hasControls: true
     });
     canvas.add(topTextObj);
 
     if (bottomText) {
       const bottomTextObj = new IText(bottomText, { 
         left: canvas.width / 2,
         top: canvas.height - 30,
         originX: 'center',
         originY: 'bottom',
         ...textStyle,
         fill: '#ffffff',
         stroke: '#000000',
         textAlign: 'center',
         backgroundColor: null,
         selectable: true,
         editable: true,
         hasControls: true
       });
       canvas.add(bottomTextObj); 
       canvas.setActiveObject(bottomTextObj); 
     } else {
       canvas.setActiveObject(topTextObj);
     }
 
     canvas.requestRenderAll();
 
     /** Save Captions & Notify User */
     setCaptionOptions(captionList);
     setLastMemeOptions(options); 
     setNotification({
       open: true,
       message: 'Pick your favorite from 20 captions!',
       severity: 'success'
     });
 
   } catch (error) {
     console.error('Error generating meme text:', error);
     setError(`Failed to generate meme text: ${error.message}`);
   } finally {
     setIsGenerating(false);
   }
 };
 
  
 const handleSelectCaption = (caption, options) => {
   if (!canvas) return;
 
   canvas.getObjects().forEach(obj => {
     if (obj instanceof IText) {
       canvas.remove(obj);
     }
   });
 
   const [topText, bottomText] = caption.includes('/') ? 
     caption.split('/').map(t => t.trim()) : 
     [caption, null];
 
   const textStyle = lastMemeOptions.style === 'classic' ? {
     fontSize: 36,
     fontFamily: 'Impact',
     strokeWidth: 2,
     shadow: { color: 'rgba(0,0,0,0.6)', blur: 4, offsetX: 2, offsetY: 2 }
   } : lastMemeOptions.style === 'modern' || lastMemeOptions.style === 'gen-z' ? {
     fontSize: 30,
     fontFamily: 'Arial',
     strokeWidth: 0,
     shadow: { color: 'rgba(0,0,0,0.3)', blur: 2, offsetX: 1, offsetY: 1 }
   } : {
     fontSize: 32,
     fontFamily: 'Arial',
     strokeWidth: 1,
     shadow: { color: 'rgba(0,0,0,0.4)', blur: 3, offsetX: 1, offsetY: 1 }
   };
 
   const topTextObj = new IText(topText, {
     left: canvas.width / 2,
     top: 30,
     originX: 'center',
     originY: 'top',
     ...textStyle,
     fill: '#ffffff',
     stroke: '#000000',
     textAlign: 'center',
     backgroundColor: null,
     selectable: true,
     editable: true,
     hasControls: true
   });
   canvas.add(topTextObj);
 
   if (bottomText) {
     const bottomTextObj = new IText(bottomText, { // Bug: should be bottomText
       left: canvas.width / 2,
       top: canvas.height - 30,
       originX: 'center',
       originY: 'bottom',
       ...textStyle,
       fill: '#ffffff',
       stroke: '#000000',
       textAlign: 'center',
       backgroundColor: null,
       selectable: true,
       editable: true,
       hasControls: true
     });
     canvas.add(bottomTextObj); // Bug: should be bottomTextObj
     canvas.setActiveObject(bottomTextObj); // Bug: should be bottomTextObj
   } else {
     canvas.setActiveObject(topTextObj);
   }
 
   canvas.requestRenderAll();
 };

  return (
    <div className="flex flex-col h-screen font-inter m-0 p-0">
      <PageHeader 
        category="Social Content"
        title="Facebook Image Creator" 
        description="Design custom images for Facebook posts and ads with our easy-to-use image editor. Add text, shapes, and images, then customize colors and fonts. You can also upload the latest meme images and add your own custom text"
        icon={<ImageIcon size={24} color="#E04D16" />} 
      />
      
      {/* Main content area takes remaining height */}
      <div className="flex flex-1 overflow-hidden h-[calc(100%-64px)] m-0 p-0">
        {/* Sidebar Tools (Left) */}
        <div className="w-16 bg-white shadow-lg flex flex-col items-center py-4 gap-4 shrink-0">
          <div className="group relative">
            <ImageIcon size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={() => fileInputRef.current?.click()} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Upload Image
            </span>
          </div>
          <div className="group relative">
            <Type size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={addText} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Add Text
            </span>
          </div>
          <div className="group relative">
            <ImagePlus size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={() => setIsMemeModalOpen(true)} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Add Meme Image
            </span>
          </div>
          <div className="group relative">
            <Square size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={addRectangle} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Add Rectangle
            </span>
          </div>
          <div className="group relative">
            <CircleIcon size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={addCircle} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Add Circle
            </span>
          </div>
          <div className="group relative">
            <TriangleIcon size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={addTriangle} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Add Triangle
            </span>
          </div>
          <div className="group relative">
            <Trash2 size={24} color={selectedObject ? '#E04D16' : '#A0A0A0'} className="cursor-pointer hover:opacity-80 transition" onClick={() => deleteSelected(canvas)} disabled={!selectedObject} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Delete
            </span>
          </div>
          <div className="group relative">
            <Download size={24} color="#E04D16" className="cursor-pointer hover:opacity-80 transition" onClick={saveImage} />
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity z-20">
              Save Image
            </span>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            className="hidden"
            onChange={handleImageUpload}
          />
        </div>
  
        {/* Main content: Canvas and Right Sidebar */}
        <div className="flex flex-1 overflow-hidden">
          {/* Canvas Area */}
          <div className="flex flex-col flex-1 overflow-hidden">
             {/* Horizontal Toolbar (Appears when object is selected) */}

           {selectedObject && (
             <div className="bg-white shadow-md p-3 flex items-center gap-4 border-b border-gray-200 flex-wrap">
               {/* Group 1: Text Styling */}
               {selectedObject.type === 'i-text' && (
                 <div className="flex items-center gap-3">
                   <select
                     value={fontFamily}
                     onChange={(e) => setFontFamily(e.target.value)}
                     className="p-1 border border-gray-300 rounded text-sm"
                   >
                     {fonts.map((font) => (
                       <option key={font} value={font} style={{ fontFamily: font }}>{font}</option>
                     ))}
                   </select>
                   <input
                     type="number"
                     value={fontSize}
                     onChange={(e) => setFontSize(Number(e.target.value))}
                     min="10"
                     max="200"
                     className="w-16 p-1 border border-gray-300 rounded text-sm"
                   />
                   <button
                     onClick={() => setIsBold(!isBold)}
                     className={`p-1 rounded ${isBold ? 'bg-[#E04D16] text-white' : 'bg-gray-100'}`}
                   >
                     <Bold size={16} />
                   </button>
                   <button
                     onClick={() => setIsItalic(!isItalic)}
                     className={`p-1 rounded ${isItalic ? 'bg-[#E04D16] text-white' : 'bg-gray-100'}`}
                   >
                     <Italic size={16} />
                   </button>
                 </div>
               )}
           
               {/* Group 2: Colors */}
               <div className="flex items-center gap-3">
                 <div className="flex items-center gap-1">
                   <Paintbrush size={16} className="text-[#E04D16]" />
                   <input
                     type="color"
                     value={textColor}
                     onChange={(e) => setTextColor(e.target.value)}
                     className="w-6 h-6 p-0 border-0 rounded cursor-pointer"
                   />
                 </div>
                 <div className="flex items-center gap-1">
                   <Palette size={16} className="text-[#E04D16]" />
                   <input
                     type="color"
                     value={backgroundColor || '#ffffff'}
                     onChange={(e) => setBackgroundColor(e.target.value)}
                     className="w-6 h-6 p-0 border-0 rounded cursor-pointer"
                   />
                   <button onClick={() => setBackgroundColor(null)} className="text-xs px-1 bg-gray-200 rounded">X</button>
                 </div>
               </div>
           
               {/* Group 3: Stroke */}
               <div className="flex items-center gap-2">
                 <PenTool size={16} className="text-[#E04D16]" />
                 <input
                   type="number"
                   value={strokeWidth}
                   onChange={(e) => setStrokeWidth(Number(e.target.value))}
                   min="0"
                   max="20"
                   className="w-16 p-1 border border-gray-300 rounded text-sm"
                 />
               </div>
           
               {/* Group 4: Canvas Size */}
               <div className="flex items-center gap-2">
                 <Maximize size={16} className="text-[#E04D16]" />
                 <input
                   type="number"
                   value={canvasSize.split('x')[0]}
                   onChange={(e) => setCanvasSize(`${Math.max(1, parseInt(e.target.value) || 0)}x${canvasSize.split('x')[1]}`)}
                   min="1"
                   max="4000"
                   className="w-16 p-1 border border-gray-300 rounded text-sm"
                 />
                 <span className="text-gray-500">×</span>
                 <input
                   type="number"
                   value={canvasSize.split('x')[1]}
                   onChange={(e) => setCanvasSize(`${canvasSize.split('x')[0]}x${Math.max(1, parseInt(e.target.value) || 0)}`)}
                   min="1"
                   max="4000"
                   className="w-16 p-1 border border-gray-300 rounded text-sm"
                 />
                 <select
                   onChange={handleSizeChange}
                   value=""
                   className="p-1 border border-gray-300 rounded text-sm"
                 >
                   <option value="">Presets</option>
                   <option value="1200x630">FB Post (1200×630)</option>
                   <option value="940x788">FB Ad (940×788)</option>
                   <option value="1080x1080">IG Post (1080×1080)</option>
                   <option value="1080x1920">IG Story (1080×1920)</option>
                   <option value="1200x1200">Square (1200×1200)</option>
                   <option value="1500x500">Twitter Header (1500×500)</option>
                   <option value="1280x720">YouTube Thumb (1280×720)</option>
                 </select>
               </div>
           
               {/* Group 5: Zoom Controls */}
               <div className="flex items-center gap-2">
                 <button
                   onClick={zoomIn}
                   className="p-1 text-[#E04D16] rounded hover:bg-gray-200 transition"
                   title="Zoom In"
                 >
                   <ZoomIn size={16} />
                 </button>
                 <button
                   onClick={zoomOut}
                   className="p-1 text-[#E04D16] rounded hover:bg-gray-200 transition"
                   title="Zoom Out"
                 >
                   <ZoomOut size={16} />
                 </button>
                 <button
                   onClick={fitToView}
                   className="p-1 text-[#E04D16] rounded hover:bg-gray-200 transition"
                   title="Fit to View"
                 >
                   <Maximize size={16} />
                 </button>
               </div>
             </div>
           )}
  
            {/* Canvas */}
           <div className="flex-1 flex items-center justify-center p-4 overflow-auto bg-gray-50 relative">
             <div className="relative border rounded-lg shadow-lg overflow-hidden bg-white inline-block">
               
               <div 
                 className="canvas-container"
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   minHeight: '500px',
                   maxWidth: '100%',
                 }}
               >
                 <canvas 
                   ref={canvasRef}
                   style={{
                     transformOrigin: 'center center',
                     transition: 'transform 0.3s ease',
                     maxWidth: 'none',
                   }}
                 />
               </div>
             </div>
           </div>
          </div>
          
          {/* Right Sidebar: Meme Text Options and Caption Grid */}
          {(selectedObject instanceof Image || captionOptions.length > 0) && (
            <div className="w-1/4 bg-white shadow-lg p-4 flex flex-col gap-4 border-l border-gray-200 max-h-[calc(100vh-64px)] overflow-y-auto">
              {/* Meme Text Options */}
              {selectedImage && (
                <InlineMemeGenerator
                  onGenerate={handleGenerateMemeText}
                  isGenerating={isGenerating}
                  disabled={!selectedImage || !(selectedImage instanceof Image)}
                />
              )}
          
              {/* Caption Grid (Single Column with Independent Scrollbar) */}
              {captionOptions.length > 0 && (
                <div className="flex flex-col gap-2 overflow-y-auto">
                  <div className="flex items-center justify-between sticky top-0 bg-white py-2 z-10">
                    <h3 className="text-lg font-semibold">Choose a Caption</h3>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          console.log('Refresh button clicked', { 
                            selectedImage: selectedImage ? selectedImage.type : 'null', 
                            isGenerating, 
                            lastMemeOptions 
                          });
                          if (selectedImage && selectedImage instanceof Image) {
                            handleGenerateMemeText(lastMemeOptions);
                          } else {
                            console.log('Cannot refresh: No valid image selected');
                          }
                        }}
                        disabled={!selectedImage || !(selectedImage instanceof Image) || isGenerating}
                        className={`p-1 rounded transition ${
                          !selectedImage || !(selectedImage instanceof Image) || isGenerating
                            ? 'cursor-not-allowed opacity-50'
                            : 'hover:bg-gray-100'
                        }`}
                        title="Refresh Captions"
                      >
                        <RefreshCw size={20} color={!selectedImage || !(selectedImage instanceof Image) || isGenerating ? "#A0A0A0" : "#E04D16"} />
                      </button>
                      <button
                        onClick={() => setCaptionOptions([])}
                        className="p-1 hover:bg-gray-100 rounded transition"
                        title="Clear Captions"
                      >
                        <X size={20} color="#E04D16" className="hover:opacity-80" />
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    {captionOptions.map((caption, index) => {
                      const [top, bottom] = caption.split('/').map(t => t?.trim());
                      return (
                        <button
                          key={index}
                          onClick={() => handleSelectCaption(caption, lastMemeOptions)}
                          className={`
                            p-3 bg-white hover:bg-gray-50 border border-gray-200 
                            rounded-lg shadow-sm transition-all duration-200 
                            flex flex-col items-center justify-center 
                            cursor-pointer hover:border-[#E04D16] hover:shadow-md
                            ${lastMemeOptions.style === 'classic' ? 'font-["Impact"] uppercase tracking-wide' :
                              lastMemeOptions.style === 'modern' || lastMemeOptions.style === 'gen-z' ? 'font-sans lowercase' :
                              'font-sans'}
                          `}
                        >
                          <span className="text-sm font-bold">{top}</span>
                          {bottom && (
                            <span className="text-sm font-bold mt-1">{bottom}</span>
                          )}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
  
      {/* Meme Modal */}
      {isMemeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-800">Select a Meme Image</h3>
              <button
                onClick={() => setIsMemeModalOpen(false)}
                className="text-gray-600 hover:text-gray-800 text-2xl font-bold"
              >
                ×
              </button>
            </div>
            {loadingMemes ? (
              <p className="text-center text-gray-600">Loading memes...</p>
            ) : memeTemplates.length > 0 ? (
              <div className="grid grid-cols-3 gap-4">
                {memeTemplates.map((meme) => (
                  <img
                    key={meme.id}
                    src={meme.url}
                    alt={meme.name}
                    className="w-full h-40 object-cover rounded-lg cursor-pointer hover:opacity-80 transition"
                    onClick={() => addMemeImage(meme.url)}
                  />
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-600">No memes available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FacebookPostGenerator;