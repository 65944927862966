import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Collapse,
  Divider,
  FormControlLabel,  
  Checkbox, 
  Switch,
  ListSubheader,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  FileText,
  HelpCircle,
  X,
  Eye,
  GripVertical,
  Plus,
  Trash2,
  Edit2,
  Wand2,
  Settings,
  ChevronDown,
  ChevronRight,
  Image, 
  CheckCircle as CheckCircleIcon,
  User as UserIcon,
  CheckCircle,
  AlertCircle,
  Link, 
} from 'lucide-react';
import { styled } from '@mui/material/styles';
import PageHeader from './PageHeader';
import ImageControls from './ImageControls';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import WordPressClient from '../utils/WordPressClient';
import aiClient from '../api/clients/AiClient';
import imageClient from '../api/clients/ImageClient';
import { formatContent } from '../utils/contentFormatting';
import { useAIModels } from '../hooks/useAIModels';
import ModelSelector from './ModelSelector';

const ArticleResponseDialog = ({ 
  open, 
  onClose, 
  success, 
  error, 
  articleData,
  onPreview 
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        color: success ? 'success.main' : 'error.main'
      }}>
        {success ? (
          <>
            <CheckCircle size={24} />
            Article Generated Successfully
          </>
        ) : (
          <>
            <AlertCircle size={24} />
            Generation Failed
          </>
        )}
      </DialogTitle>
      
      <DialogContent>
        {success ? (
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" gutterBottom>
              Your article has been successfully generated and uploaded to WordPress as a draft.
            </Typography>
           
          </Box>
        ) : (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error || 'An unknown error occurred while generating the article.'}
          </Alert>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        {success && (
          <Button 
            onClick={onPreview}
            variant="outlined"
            sx={{ mr: 'auto' }}
          >
            Preview Article
          </Button>
        )}
        <Button 
          onClick={onClose}
          variant={success ? "contained" : "outlined"}
        >
          {success ? 'Done' : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledContent = styled(DialogContent)(({ theme }) => ({
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    margin: '1rem 0',
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
    fontWeight: 600,
  },
  '& p': {
    marginBottom: '1rem',
    lineHeight: 1.7,
  },
}));

const ArticlePreviewDialog = ({ open, onClose, article }) => {
  if (!article) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: { minHeight: '80vh' }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}>
        <Typography variant="h6">Article Preview</Typography>
        <IconButton onClick={onClose} size="small">
          <X size={20} />
        </IconButton>
      </DialogTitle>

      <StyledContent>
        <Typography variant="h4" gutterBottom>
          {article.title}
        </Typography>
        
        {article.featuredImage && (
          <Box sx={{ my: 2 }}>
            <img 
              src={article.featuredImage} 
              alt={article.title}
              style={{ 
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px'
              }} 
            />
          </Box>
        )}

        <Box 
          sx={{ mt: 3 }}
          dangerouslySetInnerHTML={{ __html: article.content }}
        />
      </StyledContent>

      <DialogActions sx={{ 
        borderTop: '1px solid',
        borderColor: 'divider',
        px: 3,
        py: 2
      }}>
        <Button onClick={onClose}>
          Close Preview
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const SingleArticle = ({onGeneratingChange}) => {

  const { authenticatedRequest, error: authError, loading: authLoading } = useAuth();

  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);
  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };

const [isGenerating, setIsGenerating] = useState(false);
const [navigationTarget, setNavigationTarget] = useState(null);
const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
const [navDialogOpen, setNavDialogOpen] = useState(false);


  
  const [optimizeForSearch, setOptimizeForSearch] = useState(false);

  const [generationStatus, setGenerationStatus] = useState('');
  const [generationSteps, setGenerationSteps] = useState([
    { id: 'init', text: 'Initializing content generation...', status: 'pending' },
    { id: 'content', text: 'Generating article content...', status: 'pending' },
    { id: 'images', text: 'Processing images...', status: 'pending' },
    { id: 'upload', text: 'Publishing to WordPress...', status: 'pending' }
  ]);
  

  // Basic form state
  const [title, setTitle] = useState('');
  const [subheadings, setSubheadings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [expandedSubheading, setExpandedSubheading] = useState(null);

  // WordPress settings
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(false);


  // AI settings
  const [model, setModel] = useState(''); 

  const [prompts, setPrompts] = useState({
    systemTemplates: [],
    userPrompts: []
  });
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [isGeneratingSubheadings, setIsGeneratingSubheadings] = useState(false);

  // Image settings
  const [includeImages, setIncludeImages] = useState(false);
  const [featuredImage, setFeaturedImage] = useState(false);
  const [featuredImageModel, setFeaturedImageModel] = useState('fal-ai/flux/schnell');
  const [featuredImageStyle, setFeaturedImageStyle] = useState('realistic_image'); 
  const [subheadingImages, setSubheadingImages] = useState(false);
  const [subheadingImageModel, setSubheadingImageModel] = useState('fal-ai/flux/schnell');
  const [dimension, setDimension] = useState('square');
  const [subheadingImageStyle, setSubheadingImageStyle] = useState('realistic_image');
  const [maxSubheadings, setMaxSubheadings] = useState(5);
  const [imageFrequency, setImageFrequency] = useState('every');
  const [featuredImagePrompt, setFeaturedImagePrompt] = useState('');
  const [wpClient, setWpClient] = useState(null);


  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [generatedArticle, setGeneratedArticle] = useState(null);

  // Preview state
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState(null);

  useEffect(() => {
    if (!authLoading) {  
      fetchInitialData();
      initializeServices();
    }
  }, [authLoading]);

  useEffect(() => {
    const initializeAI = async () => {
      if (availableModels.length > 0) {
        try {
          const keys = await authenticatedRequest(() => apiClient.getApiKeys());
          
          // Initialize AI client
          aiClient.setApiKeys(keys);
          aiClient.updateModelConfigs(availableModels);
          
          // Initialize image client
          imageClient.setAvailableModels(availableModels);
          imageClient.setAiClient(aiClient);
          imageClient.setApiKeys(keys);
          
          // Set initial model if none selected
          if (!model && availableModels.length > 0) {
            const defaultModel = availableModels[0].value;
            console.log('Setting default model:', defaultModel);
            setModel(defaultModel);
          }
  
        } catch (error) {
          console.error('Error initializing AI client:', error);
          setError('Failed to initialize AI services');
        }
      }
    };
  
    initializeAI();
  }, [availableModels, authenticatedRequest]);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories();
      fetchUsers();
      setSelectedUser(''); 
    }
  }, [selectedSite]);
  

  useEffect(() => {
    if (!authLoading && availableModels.length > 0) {
      console.log('Available AI Models:', {
        total: availableModels.length,
        models: availableModels.map(m => ({
          value: m.value,
          label: m.label,
          provider: m.provider
        }))
      });
    }
  }, [authLoading, availableModels]);

  


  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isGenerating) {
        e.preventDefault();
        setNavDialogOpen(true);
        e.returnValue = '';
        return '';
      }
    };
  
    const handleUnload = (e) => {
      if (isGenerating) {
        e.preventDefault();
        setNavDialogOpen(true);
        return false;
      }
    };
  
    const handlePopState = (event) => {
      if (isGenerating) {
        event.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        setNavDialogOpen(true);
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    window.addEventListener('popstate', handlePopState);
    
    if (isGenerating) {
      window.history.pushState(null, '', window.location.pathname);
    }
  
    onGeneratingChange?.(isGenerating);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('popstate', handlePopState);
      onGeneratingChange?.(false);
    };
  }, [isGenerating, onGeneratingChange]);

  const initializeServices = async () => {
    try {
      const keys = await authenticatedRequest(() => apiClient.getApiKeys());
      
      if (!keys.openai && !keys.anthropic && !keys.fal && !keys.openrouter) {
        throw new Error("No API keys configured. Please add required API keys in settings.");
      }
  
      // Initialize image generation services
      if (keys.fal) {
        imageClient.setApiKeys(keys);
        console.log('Image generation services initialized');
      } else {
        console.warn('Fal.ai API key not configured - image generation will be disabled');
        setFeaturedImage(false);
      }
  
    } catch (error) {
      console.error("Failed to initialize services:", error);
      const errorMessage = error.message.includes('API keys') 
        ? error.message 
        : 'Failed to initialize services. Please check your API keys in settings.';
      setError(errorMessage);
    }
  };

const LeaveConfirmationDialog = ({ open, onClose, onConfirm }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Leave Page?</DialogTitle>
    <DialogContent>
      <Typography>
        Article is being generated. If you leave now, all progress will be lost.
      </Typography>
      <Typography color="error" sx={{ mt: 2 }}>
        Are you sure you want to leave this page?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Stay on Page
      </Button>
      <Button onClick={onConfirm} color="error" variant="contained">
        Leave Page
      </Button>
    </DialogActions>
  </Dialog>
);

const NavigationDialog = () => (
  <Dialog open={navDialogOpen} onClose={() => setNavDialogOpen(false)}>
    <DialogTitle>Leave Page?</DialogTitle>
    <DialogContent>
      <Typography>
        Article is being generated. If you leave now, all progress will be lost.
      </Typography>
      <Typography color="error" sx={{ mt: 2 }}>
        Are you sure you want to leave this page?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setNavDialogOpen(false)}>
        Stay on Page
      </Button>
      <Button
        onClick={() => {
          setIsGenerating(false);
          setNavDialogOpen(false);
          window.history.back();
        }}
        color="error"
        variant="contained"
      >
        Leave Page
      </Button>
    </DialogActions>
  </Dialog>
);



  const fetchInitialData = async () => {
    localStorage.removeItem('availableModels');
   
    try {
      const [sitesData, promptsData] = await Promise.all([
        authenticatedRequest(() => apiClient.getWordPressSites()),
        authenticatedRequest(() => apiClient.getSavedPrompts())
      ]);
  
      setSites(sitesData);
      if (sitesData.length > 0) {
        setSelectedSite(sitesData[0].id);
      }
      
      // Update this part to handle the new prompt structure
      setPrompts({
        systemTemplates: promptsData.system_templates || [],
        userPrompts: promptsData.user_prompts || []
      });
    } catch (err) {
      setError('Failed to load initial data');
    }
  };

  const fetchUsers = async () => {
    if (!selectedSite) return;
    setLoadingUsers(true);
    try {
      const site = sites.find(s => s.id === selectedSite);
      if (!site) throw new Error('Selected site not found');
      
      const wpClient = new WordPressClient(site);
      const credentials = await authenticatedRequest(() => 
        apiClient.getWordPressCredentials(selectedSite)
      );
      wpClient.setCredentials(credentials.username, credentials.password);
      
      const usersData = await wpClient.getUsers();
      setUsers(usersData);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users');
    } finally {
      setLoadingUsers(false);
    }
  };

  const fetchCategories = async () => {
    if (!selectedSite) return;
    try {
      const categoriesData = await authenticatedRequest(() => 
        apiClient.getCategories(selectedSite)
      );
      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to load categories');
    }
  };

  const handleGenerateSubheadings = async () => {
    if (!title.trim()) {
      setError('Please enter an article title first');
      return;
    }
  
    setIsGeneratingSubheadings(true);
    setError(null);
  
    try {
      if (!availableModels.length) {
        throw new Error('No AI models available');
      }
  
      const auxModel = getAuxiliaryModel();
      if (!auxModel) {
        throw new Error('No auxiliary model available');
      }
  
      const prompt = `Create 5-7 logical subheadings for an article titled "${title}". 
        The subheadings should:
        - Follow a natural progression
        - Cover the main aspects of the topic
        - Be clear and descriptive
        Return only the subheadings, one per line.`;
  
      console.log('Using auxiliary model:', auxModel);
      
      const response = await aiClient.generateArticleContent({
        title,
        modelId: auxModel,
        customPrompt: prompt
      });
  
      const newSubheadings = response
        .split('\n')
        .filter(line => line.trim())
        .map((text, index) => ({
          id: `subheading-${Date.now()}-${index}`,
          text: text.replace(/^#+ /, '').trim(),
          notes: '',
          wordCount: 200
        }));
  
      setSubheadings(currentSubheadings => [...currentSubheadings, ...newSubheadings]);
    } catch (err) {
      console.error('Subheading generation error:', err);
      setError('Failed to generate subheadings: ' + err.message);
    } finally {
      setIsGeneratingSubheadings(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(subheadings);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSubheadings(items);
  };

  const handleAddSubheading = () => {
    const newSubheading = {
      id: `subheading-${Date.now()}`,
      text: '',
      notes: '',
      wordCount: 200,
      generateImage: false,  
      imagePrompt: '',     
    };
    setSubheadings([...subheadings, newSubheading]);
    setExpandedSubheading(newSubheading.id);
  };

  const handleDeleteSubheading = (id) => {
    setSubheadings(subheadings.filter(sh => sh.id !== id));
  };

  const handleUpdateSubheading = (id, field, value) => {
    console.log('Updating subheading:', { id, field, value });
    setSubheadings(prevSubheadings => {
      const newSubheadings = prevSubheadings.map(sh => 
        sh.id === id ? { ...sh, [field]: value } : sh
      );
      console.log('Updated subheadings:', newSubheadings);
      return newSubheadings;
    });
  };

  const handlePreviewPrompt = (promptId) => {
    const prompt = [
      ...prompts.systemTemplates,
      ...prompts.userPrompts
    ].find(p => p.id === promptId);
    
    if (prompt) {
      setPreviewPrompt(prompt);
      setPreviewOpen(true);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsGenerating(true);
    setError(null);
  
    setGenerationSteps([
      { id: 'init', text: 'Initializing content generation...', status: 'pending' },
      { id: 'content', text: 'Generating article content...', status: 'pending' },
      { id: 'images', text: 'Processing images...', status: 'pending' },
      { id: 'upload', text: 'Publishing to WordPress...', status: 'pending' },
    ]);
  
    const updateStepStatus = (stepId, status, error = null) => {
      setGenerationSteps((prev) =>
        prev.map((step) =>
          step.id === stepId ? { ...step, status, error } : step
        )
      );
    };
  
    try {
      setGenerationStatus('Starting article generation...');
      updateStepStatus('init', 'pending');
  
      const prompt = [...prompts.systemTemplates, ...prompts.userPrompts].find(
        (p) => p.id === selectedPrompt
      );
      if (!prompt) throw new Error('Selected prompt template not found');
  
      const createStructuredPrompt = (
        title,
        subheadings,
        promptTemplate,
        optimizeForSearch
      ) => {
        const subheadingsText = subheadings
          .map((sh, index) => {
            let section = `${index + 1}. ${sh.text}`;
            if (sh.notes) section += `\nNotes: ${sh.notes}`;
            section += `\nTarget word count: ${sh.wordCount} words`;
            return section;
          })
          .join('\n');
  
        const searchOptimizationInstructions = optimizeForSearch
          ? `
  Additional Search Optimization Requirements:
  - Identify and naturally incorporate semantic variations of the main topic
  - Structure content to address common user questions and search intents
  - Include relevant LSI (Latent Semantic Indexing) keywords naturally
  - Ensure comprehensive coverage of related subtopics
  - Structure headings and subheadings to target featured snippets
  - Include clear definitions and explanations of key concepts
  - Use natural language that matches search queries
  `
          : '';
  
        return `${promptTemplate.prompt_text}
  
  Article Title: ${title}
  
  Required Structure:
  ${subheadingsText}
  
  Additional Requirements:
  - Follow the exact subheading structure provided above
  - Maintain the specified word count for each section
  - Use the section notes as guidance for content
  ${searchOptimizationInstructions}`;
      };
  
      updateStepStatus('init', 'completed');
      updateStepStatus('content', 'pending');
  
      const structuredPrompt = createStructuredPrompt(
        title,
        subheadings,
        prompt,
        optimizeForSearch
      );
  
      setGenerationStatus(`Generating article content for "${title}"`);
      const rawContent = await aiClient.generateArticleContent({
        title,
        modelId: model,
        customPrompt: structuredPrompt,
      });
  
      updateStepStatus('content', 'completed');
      updateStepStatus('images', 'pending');
  
      let featuredMediaId = null;
      let processedContent = formatContent(rawContent);
  
      const site = sites.find((s) => s.id === selectedSite);
      if (!site) throw new Error('Selected site not found');
  
      const wpClient = new WordPressClient(site);
      const credentials = await authenticatedRequest(() =>
        apiClient.getWordPressCredentials(selectedSite)
      );
      wpClient.setCredentials(credentials.username, credentials.password);
  
      // Handle featured image
      if (featuredImage) {
        setGenerationStatus(`Generating featured image for "${title}"`);
        try {
          const imageOptions = {
            prompt: featuredImagePrompt || title, // Default to title if no user prompt
            model: featuredImageModel,
            dimension,
            style: featuredImageStyle,
            enhancePrompt: !featuredImagePrompt, // Enhance only if no user prompt
            context: { articleTitle: title }, // Pass article title for context
          };
  
          console.log('Featured image options:', imageOptions);
  
          const imageData = await imageClient.generateImage(imageOptions);
  
          if (imageData?.image_url) {
            setGenerationStatus('Uploading featured image...');
            const response = await fetch(imageData.image_url);
            const blob = await response.blob();
            const formData = new FormData();
            formData.append('file', blob, `featured-${Date.now()}.png`);
            formData.append('siteId', selectedSite);
  
            const uploadResult = await authenticatedRequest(() =>
              apiClient.uploadMedia(formData)
            );
            if (uploadResult?.media_id) {
              featuredMediaId = uploadResult.media_id;
            }
          }
        } catch (error) {
          console.error('Featured image error:', error);
          setGenerationStatus('Featured image generation failed, continuing...');
        }
      }
  
      // Process subheading images
      const hasImageSubheadings = subheadings.some((sh) => sh.generateImage);
      if (hasImageSubheadings) {
        console.log('Found subheadings with images, starting image generation...');
        setGenerationStatus('Processing subheading images...');
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(processedContent, 'text/html');
        const contentHeadings = Array.from(doc.querySelectorAll('h2'));
        console.log('Found content headings:', contentHeadings.map((h) => h.textContent));
  
        const cleanText = (text) =>
          text
            .replace(/^[-#0-9.]+\s*/, '')
            .replace(/^[A-Z\s]+:\s*/, '')
            .replace(/:/g, '')
            .toLowerCase()
            .trim();
  
        for (const subheading of subheadings) {
          if (!subheading.generateImage) continue;
  
          const cleanedSubheadingText = cleanText(subheading.text);
          console.log('Looking for match for cleaned subheading:', cleanedSubheadingText);
  
          const matchingHeading = contentHeadings.find((h) => {
            const headingText = cleanText(h.textContent);
            return (
              headingText.includes(cleanedSubheadingText) ||
              cleanedSubheadingText.includes(headingText)
            );
          });
  
          if (matchingHeading) {
            try {
              const imageOptions = {
                prompt: subheading.imagePrompt || matchingHeading.textContent, // Default to subheading text if no user prompt
                model: subheadingImageModel,
                dimension,
                style: subheadingImageStyle,
                enhancePrompt: !subheading.imagePrompt, // Enhance only if no user prompt
                context: { 
                  articleTitle: title, 
                  sectionNotes: subheading.notes || '' 
                }, // Pass title and notes for context
              };
  
              console.log(`Subheading image options for "${matchingHeading.textContent}":`, imageOptions);
  
              const imageData = await imageClient.generateImage(imageOptions);
  
              if (imageData?.image_url) {
                const response = await fetch(imageData.image_url);
                const blob = await response.blob();
                const formData = new FormData();
                formData.append('file', blob, `subheading-${Date.now()}.png`);
                formData.append('siteId', selectedSite);
  
                const uploadResult = await authenticatedRequest(() =>
                  apiClient.uploadMedia(formData)
                );
  
                if (uploadResult?.media_id) {
                  const img = doc.createElement('img');
                  img.src = uploadResult.url;
                  img.alt = matchingHeading.textContent;
                  img.className = `wp-image-${uploadResult.media_id}`;
                  img.style.cssText = 'max-width: 100%; height: auto; margin: 20px 0;';
                  matchingHeading.parentNode.insertBefore(img, matchingHeading.nextSibling);
                }
              }
            } catch (error) {
              console.error(`Failed to process image for "${matchingHeading.textContent}":`, error);
              setGenerationStatus(`Image generation failed for "${matchingHeading.textContent}", continuing...`);
            }
          } else {
            console.log(`No match found for subheading "${subheading.text}"`);
          }
        }
        processedContent = doc.body.innerHTML;
      }
  
      updateStepStatus('images', 'completed');
  
      // Final WordPress upload
      setGenerationStatus('Creating WordPress post...');
      const post = await wpClient.createPost({
        title,
        content: processedContent,
        categoryId: category,
        featuredMediaId: featuredMediaId,
        status: 'draft',
      });
  
      updateStepStatus('upload', 'completed');
      setGenerationStatus('✓ Article published successfully!');
  
      setGeneratedArticle({
        title,
        content: processedContent,
        featuredImage: featuredMediaId ? await wpClient.getMediaUrl(featuredMediaId) : null,
        link: post.link,
        postId: post.id,
      });
  
      setResponseDialogOpen(true);
      setError(null);
    } catch (err) {
      console.error('Error generating article:', err);
      const failedStep = generationSteps.find((step) => step.status === 'pending');
      if (failedStep) {
        updateStepStatus(failedStep.id, 'error', err.message);
      }
  
      setError(err.message || 'Failed to generate article');
      setGenerationStatus('Generation failed. Please check the error message and try again.');
      setResponseDialogOpen(true);
    } finally {
      setLoading(false);
      setIsGenerating(false);
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {/* Page Header with Help Button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <PageHeader 
          category="Content Creation"
          title="Create In-Depth Article"
          description="Create structured, AI-powered articles with customizable sections, images, and formatting. Perfect for producing well-organized, professional content for your WordPress site."
          icon={<FileText size={24} color="#E04C16" />} 
        />
        <Button
          onClick={() => setHelpDialogOpen(true)}
          startIcon={<HelpCircle size={18} />}
          sx={{
            bgcolor: '#E04C16',
            color: 'white',
            borderRadius: '20px',
            px: 3,
            '&:hover': {
              bgcolor: '#c43d0f'
            }
          }}
        >
          How to Use
        </Button>
      </Box>

      <Grid container spacing={3}>
        {/* Left Column - Article Setup */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <form onSubmit={handleSubmit}>
              {/* WordPress Settings */}
<Grid container spacing={2}>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <FormControl fullWidth required>
          <InputLabel>WordPress Site</InputLabel>
          <Select
            value={selectedSite}
            label="WordPress Site"
            onChange={(e) => setSelectedSite(e.target.value)}
            disabled={loading}
          >
            {sites.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {site.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Tooltip 
        title="Select the WordPress site where you want to publish this article." 
        placement="right"
        sx={{ mt: 1.5 }}
      >
       <IconButton size="small">
        <HelpCircle size={18} />
      </IconButton>
      </Tooltip>
    </Box>
  </Grid>
 
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <FormControl fullWidth required>
          <InputLabel>Post Author</InputLabel>
          <Select
            value={selectedUser}
            label="Post Author"
            onChange={(e) => setSelectedUser(e.target.value)}
            disabled={isGenerating || loading || !selectedSite || loadingUsers}
            renderValue={(selected) => {
              const user = users.find(u => u.id === selected);
              if (!user) return '';
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  {loadingUsers ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      component="img"
                      src={user.avatar_urls['24']}
                      alt={`${user.name}'s avatar`}
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        flexShrink: 0
                      }}
                      onError={(e) => {
                        e.target.src = `data:image/svg+xml,${encodeURIComponent(
                          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>'
                        )}`;
                      }}
                    />
                  )}
                  <Typography>{user.name}</Typography>
                </Box>
              );
            }}
          >
            {loadingUsers ? (
              <MenuItem disabled>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <CircularProgress size={20} />
                  <Typography>Loading users...</Typography>
                </Box>
              </MenuItem>
            ) : users.length === 0 ? (
              <MenuItem disabled>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <UserIcon size={20} />
                  <Typography>No users available</Typography>
                </Box>
              </MenuItem>
            ) : (
              users.map((user) => (
                <MenuItem 
                  key={user.id} 
                  value={user.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    minHeight: 40
                  }}
                >
                  <Box
                    component="img"
                    src={user.avatar_urls['48']}
                    alt={`${user.name}'s avatar`}
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: '50%',
                      flexShrink: 0
                    }}
                    onError={(e) => {
                      e.target.src = `data:image/svg+xml,${encodeURIComponent(
                        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>'
                      )}`;
                    }}
                  />
                  <Typography>{user.name}</Typography>
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Box>
      <Tooltip 
        title="Choose which WordPress user will be shown as the author of this article." 
        placement="right"
        sx={{ mt: 1.5 }}
      >
        <IconButton size="small">
          <HelpCircle size={18} />
        </IconButton>
      </Tooltip>
    </Box>
  </Grid>

        
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <FormControl fullWidth required>
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
            disabled={isGenerating || loading || !selectedSite}
          >
            {categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Tooltip 
        title="Select the category where this article will be organized in WordPress." 
        placement="right"
        sx={{ mt: 1.5 }}
      >
        <IconButton size="small">
        <HelpCircle size={18} />
      </IconButton>
      </Tooltip>
    </Box>
  </Grid>

  <Grid item xs={12}>
  <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
    <Box sx={{ flexGrow: 1 }}>
      <ModelSelector
        value={model}
        onChange={(e) => setModel(e.target.value)}
        required
        tooltip="Choose the AI model that will generate your article content. Different models have different strengths and capabilities."
        label="AI MODEL"
        disabled={isGenerating || loading || modelsLoading}
        showDescription={true}
      />
      {modelsError && (
        <FormHelperText error>
          {modelsError}
        </FormHelperText>
      )}
    </Box>
  </Box>
</Grid>
<Grid item xs={12}>
  <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'start', flexGrow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
      <FormControl fullWidth required>
  <InputLabel>Writing Style Template</InputLabel>
  <Select
    value={selectedPrompt}
    label="Writing Style Template"
    onChange={(e) => setSelectedPrompt(e.target.value)}
    disabled={isGenerating || loading}
  >
    {[
      // System Templates Section
      prompts.systemTemplates?.length > 0 && (
        <ListSubheader key="system-header" sx={{fontWeight: 600,fontSize: '0.875rem'
        }}>Default Prompts</ListSubheader>
      ),
      // System Template Items
      ...(prompts.systemTemplates?.map((prompt) => (
        <MenuItem 
          key={prompt.id} 
          value={prompt.id}
        >
          <Box>
            <Typography variant="subtitle2">{prompt.name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {prompt.settings?.description || 'System template'}
            </Typography>
          </Box>
        </MenuItem>
      )) || []),
      
      // User Templates Section
      prompts.userPrompts?.length > 0 && (
        <ListSubheader 
        key="user-header"
        sx={{ 
          fontWeight: 600,
          fontSize: '0.875rem'
        }}
      >
        My Saved Prompts
      </ListSubheader>
      ),
      // User Template Items
      ...(prompts.userPrompts?.map((prompt) => (
        <MenuItem key={prompt.id} value={prompt.id}>
          {prompt.name}
        </MenuItem>
      )) || [])
    ].filter(Boolean)}
  </Select>
</FormControl>
        {selectedPrompt && (
          <IconButton 
            onClick={() => handlePreviewPrompt(selectedPrompt)}
            sx={{ height: '56px' }}
          >
            <Eye size={20} />
          </IconButton>
        )}
      </Box>
    </Box>
    <Tooltip 
      title="Select a template that defines the tone, style, and structure of your article. You can preview templates to see their settings." 
      placement="right"
      sx={{ mt: 1.5 }}
    >
      <IconButton size="small">
        <HelpCircle size={18} />
      </IconButton>
    </Tooltip>
  </Box>
</Grid>
  

  <Grid item xs={12}>
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          label="Article Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          disabled={isGenerating || loading}
        />
      </Box>
      <Tooltip 
        title="Enter your article title. A good title is clear, engaging, and accurately reflects the content." 
        placement="right"
        sx={{ mt: 1.5 }}
      >
        <IconButton size="small">
          <HelpCircle size={18} />
        </IconButton>
      </Tooltip>
    </Box>
    
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      bgcolor: 'background.paper',
      p: 1.5,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider'
    }}>
      <FormControlLabel
        control={
          <Switch
            checked={optimizeForSearch}
            onChange={(e) => setOptimizeForSearch(e.target.checked)}
            disabled={isGenerating || loading}
          />
        }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>Optimize for Search Intent</Typography>
            <Tooltip 
              title="When enabled, the AI will structure content to better match search intent and include relevant semantic variations of key topics." 
              placement="right"
            >
              <IconButton size="small">
                <HelpCircle size={16} />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
    </Box>
  </Box>
</Grid>
                             
<Grid item xs={12}>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <FormControlLabel
      control={
        <Checkbox
          checked={featuredImage}
          onChange={(e) => setFeaturedImage(e.target.checked)}
          disabled={isGenerating || loading}
        />
      }
      label="Generate Featured Image"
    />
    <Tooltip title="Custom prompt will override AI-generated prompt">
      <IconButton size="small">
        <HelpCircle size={18} />
      </IconButton>
    </Tooltip>
  </Box>
  
  {featuredImage && (
  <Box sx={{ mt: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={2}
          label="Featured Image Prompt (optional)"
          placeholder="Leave blank to let AI generate based on article title and content"
          value={featuredImagePrompt}
          onChange={(e) => setFeaturedImagePrompt(e.target.value)}
          disabled={isGenerating}
        />
      </Grid>
     
      <Grid item xs={12}>
      <ImageControls
       disabled={isGenerating}
  model={featuredImageModel}
  setModel={setFeaturedImageModel}
  style={featuredImageStyle}
  setStyle={setFeaturedImageStyle}
  dimension={dimension}
  setDimension={setDimension}
  loading={loading}
/>

</Grid>
    </Grid>
  </Box>
)}
</Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                    <Button
                      onClick={handleGenerateSubheadings}
                      startIcon={<Wand2 />}
                      variant="contained"
                      disabled={isGenerating || !title || isGeneratingSubheadings}
                      sx={{ flexGrow: 1 }}
                    >
                      {isGeneratingSubheadings ? 'Generating...' : 'Generate Subheadings'}
                    </Button>
                    <Button
                      disabled={isGenerating}
                      onClick={handleAddSubheading}
                      startIcon={<Plus />}
                      variant="outlined"
                    >
                      Add Custom Subheading
                    </Button>
                  </Box>

                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="subheadings">
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{ 
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          {subheadings.map((subheading, index) => (
                            <Draggable
                              key={subheading.id}
                              draggableId={subheading.id}
                              index={index}
                            >
                              {(provided) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  sx={{
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    '&:last-child': {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'flex-start',
                                    width: '100%'
                                  }}>
                                    <Box
                                      {...provided.dragHandleProps}
                                      sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        mr: 2,
                                        cursor: 'grab'
                                      }}
                                    >
                                      <GripVertical size={20} />
                                    </Box>

                                    <Box sx={{ flexGrow: 1 }}>
                                                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                      <TextField
                                                          disabled={isGenerating}
                                                          fullWidth
                                                          value={subheading.text}
                                                          onChange={(e) => handleUpdateSubheading(
                                                              subheading.id,
                                                              'text',
                                                              e.target.value
                                                          )}
                                                          variant="standard"
                                                          sx={{ mr: 2 }}
                                                      />
                                                      <Tooltip title="Expand section settings">
                                                          <IconButton
                                                              onClick={() => setExpandedSubheading(
                                                                  expandedSubheading === subheading.id ? null : subheading.id
                                                              )}
                                                              size="small"
                                                          >
                                                              {expandedSubheading === subheading.id ?
                                                                  <ChevronDown size={20} /> :
                                                                  <ChevronRight size={20} />
                                                              }
                                                          </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Generate image for this section">
                                                          <IconButton
                                                              onClick={() => handleUpdateSubheading(
                                                                  subheading.id,
                                                                  'generateImage',
                                                                  !subheading.generateImage
                                                              )}
                                                              size="small"
                                                              color={subheading.generateImage ? "primary" : "default"}
                                                          >
                                                              <Image size={20} />
                                                          </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Delete section">
                                                          <IconButton
                                                              onClick={() => handleDeleteSubheading(subheading.id)}
                                                              size="small"
                                                              color="error"
                                                          >
                                                              <Trash2 size={20} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </Box>

                                                  <Collapse in={expandedSubheading === subheading.id}>
                                                      <Box sx={{ mt: 2 }}>
                                                          <TextField
                                                              disabled={isGenerating}
                                                              fullWidth
                                                              multiline
                                                              rows={2}
                                                              label="Section Notes (optional)"
                                                              placeholder="Add notes or key points for this section..."
                                                              value={subheading.notes}
                                                              onChange={(e) => handleUpdateSubheading(
                                                                  subheading.id,
                                                                  'notes',
                                                                  e.target.value
                                                              )}
                                                              sx={{ mb: 2 }}
                                                          />
                                                          <TextField
                                                              disabled={isGenerating}
                                                              type="number"
                                                              label="Target Word Count"
                                                              value={subheading.wordCount}
                                                              onChange={(e) => handleUpdateSubheading(
                                                                  subheading.id,
                                                                  'wordCount',
                                                                  parseInt(e.target.value) || 0
                                                              )}
                                                              InputProps={{ inputProps: { min: 50 } }}
                                                              sx={{ mb: 2 }}
                                                          />
                                                          {/* Replace the existing subheading.generateImage section in the Collapse component */}
                                                      {/* Find this code block in SingleArticle.js, inside the Collapse component */}
{subheading.generateImage && (
  <Box sx={{ mt: 2 }}>
    <TextField
      disabled={isGenerating}
      fullWidth
      multiline
      rows={2}
      label="Image Prompt (optional)"
      placeholder="Leave blank for AI to generate based on section content"
      value={subheading.imagePrompt || ''}
      onChange={(e) => handleUpdateSubheading(
        subheading.id,
        'imagePrompt',
        e.target.value
      )}
      sx={{ mb: 2 }}
    />
    
    <ImageControls
     disabled={isGenerating}
      model={subheadingImageModel}
      setModel={setSubheadingImageModel}
      style={subheadingImageStyle}
      setStyle={setSubheadingImageStyle}
      dimension={dimension}
      setDimension={setDimension}
      loading={loading}
    />
  </Box>
)}
                                                      </Box>
                                                  </Collapse>
                                    </Box>
                                  </Box>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>

               

                {/* Error Display */}
                <Grid item xs={12}>
                  {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {error}
                    </Alert>
                  )}
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={isGenerating || !title || subheadings.length === 0 || loading}
                  >
                    {loading ? 'Generating Article...' : 'Generate Article'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

       {/* Right Column - Preview & Status */}
<Grid item xs={12} md={5}>
  <Paper sx={{ p: 3, height: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Article Structure Preview
    </Typography>
    <Box sx={{ mb: 3 }}>
      <Typography 
        variant="h6" 
        color="primary" 
        gutterBottom 
        sx={{ mb: 3 }}
      >
        {title || 'Your Article Title'}
      </Typography>
      {loading ? (
  <Box sx={{ mb: 3 }}>
    <Typography 
      variant="subtitle1" 
      sx={{ mb: 2, fontWeight: 500 }}
    >
      Generation Progress:
    </Typography>
    {generationSteps.map((step) => (
      <Box 
        key={step.id}
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          mb: 1.5 
        }}
      >
        {step.status === 'pending' && (
          <CircularProgress size={16} />
        )}
        {step.status === 'completed' && (
          <CheckCircle 
            size={16} 
            color="success" 
            style={{ color: '#2e7d32' }} 
          />
        )}
        {step.status === 'error' && (
          <X 
            size={16}
            style={{ color: '#d32f2f' }}
          />
        )}
        <Typography 
          variant="body1" 
          sx={{ 
            fontSize: '1rem',
            color: step.status === 'error' ? 'error.main' : 
                   step.status === 'completed' ? 'success.main' : 
                   'text.secondary'
          }}
        >
          {step.text}
          {step.error && (
            <Typography 
              component="span" 
              color="error" 
              sx={{ ml: 1, fontSize: '0.875rem' }}
            >
              ({step.error})
            </Typography>
          )}
        </Typography>
      </Box>
    ))}
    <Typography 
      variant="body1" 
      color="primary" 
      sx={{ mt: 2, fontSize: '1rem' }}
    >
      {generationStatus}
    </Typography>
  </Box>
) : (
 
        subheadings.length > 0 ? (
          <List>
            {subheadings.map((subheading, index) => (
              <ListItem 
                key={subheading.id} 
                sx={{ 
                  px: 0,
                  mb: 2,
                  display: 'block'
                }}
              >
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 1
                  }}
                >
                  {`${index + 1}. ${subheading.text || 'Untitled Section'}`}
                </Typography>
                <Typography 
                  variant="body1" 
                  color="text.secondary"
                  sx={{ fontSize: '1rem' }}
                >
                  {subheading.notes && (
                    <>
                      Notes: {subheading.notes}
                      <br />
                    </>
                  )}
                  Target: {subheading.wordCount} words
                  {subheading.generateImage && (
                    <>
                      <br />
                      {subheading.imagePrompt ? 
                        `Custom image prompt: ${subheading.imagePrompt}` : 
                        'AI-generated image enabled'
                      }
                    </>
                  )}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography 
            color="text.secondary" 
            sx={{ fontSize: '1rem' }}
          >
            No subheadings added yet. Use the generate button or add custom subheadings.
          </Typography>
        )
      )}
    </Box>
  </Paper>
</Grid>

<Dialog 
  open={helpDialogOpen}
  onClose={() => setHelpDialogOpen(false)}
  PaperProps={{
    sx: {
      position: 'fixed',
      right: 32,
      top: '50%',
      transform: 'translateY(-50%)',
      m: 0,
      width: 400,
      maxWidth: '90vw',
      maxHeight: '80vh',
      borderRadius: 2
    }
  }}
>
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    p: 2,
    borderBottom: '1px solid',
    borderColor: 'divider'
  }}>
    <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
      How to Use Single Article Creator
    </DialogTitle>
    <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
      <X size={18} />
    </IconButton>
  </Box>
 <DialogContent>
   {/* Getting Started */}
   <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
     Getting Started
   </Typography>
   <Typography variant="body2" sx={{ mb: 4, lineHeight: 1.6 }}>
     Welcome to the article creation tool! This page lets you build a structured article using AI-powered features or your own custom inputs. Start by entering an article title, then choose to either generate AI-suggested subheadings or create your own custom sections. You can also add images—either AI-generated using different quality models or with your own prompts—to enhance your article. Customize everything to fit your vision before generating the final piece!
   </Typography>
 
   {/* Creating Your Article Structure */}
   <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
     Creating Your Article Structure
   </Typography>
   <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
     Follow these steps to build your article’s foundation:
   </Typography>
   <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
     <li style={{ marginBottom: '8px' }}>
       <strong>Enter your article title:</strong> Type a clear, specific title in the title field (e.g., "The Benefits of Remote Work"). This sets the focus for your article and guides AI suggestions.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Generate subheadings or add custom ones:</strong> Click "Generate Subheadings" to let the AI suggest a structure based on your title (powered by the auxiliary AI model selected in the sidebar), or manually add your own sections by clicking "Add Section."
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Reorder sections:</strong> Use the drag-and-drop feature to rearrange subheadings in a logical order that flows well for readers.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Add notes and word count targets:</strong> For each section, include notes to guide the AI (e.g., "Mention productivity stats") and set a target word count (e.g., 200 words) to control length.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Customize with images:</strong> Enable images for the featured section or specific subheadings by clicking the image icon to the right of each subheading. Expand the section to choose from different image models (cheaper models may produce lower-quality images) or enter your own prompt. AI-generated image prompts use the auxiliary AI model from the sidebar.
     </li>
   </Typography>
 
   {/* Customizing Sections */}
   <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
     Customizing Sections
   </Typography>
   <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
     Fine-tune each section to match your vision:
   </Typography>
   <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
     <li style={{ marginBottom: '8px' }}>
       <strong>Edit subheading text:</strong> Click the subheading text to modify it directly (e.g., change "Introduction" to "Why Remote Work Matters").
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Add notes:</strong> Use the notes field to provide specific instructions for the AI, like "Include a real-world example" or "Focus on environmental benefits."
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Set target word counts:</strong> Enter a number (e.g., 150 or 300 words) to ensure each section meets your desired length.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Enable images:</strong> Click the image icon next to a subheading to add an image, then expand the section to select an image model or enter a custom prompt. Higher-quality models cost more but deliver better results.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Add or remove sections:</strong> Click "Add Section" to expand your article or the trash icon to delete unnecessary ones.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Reorder with drag and drop:</strong> Adjust the sequence by dragging sections up or down to improve the article’s flow.
     </li>
   </Typography>
 
   {/* Best Practices */}
   <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
     Best Practices for Great Results
   </Typography>
   <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
     Maximize the tool’s potential with these tips:
   </Typography>
   <Typography component="ul" variant="body2" sx={{ pl: 2, lineHeight: 1.6 }}>
     <li style={{ marginBottom: '8px' }}>
       <strong>Use clear, descriptive subheadings:</strong> Instead of "Overview," try "Key Advantages of Solar Energy" to guide the AI and engage readers.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Balance section lengths:</strong> Aim for consistent word counts (e.g., 200-300 words per section) to maintain a smooth reading experience.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Add specific notes:</strong> Include details like "Cite a study" or "Use a positive tone" to shape the AI’s output more precisely.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Choose image models wisely:</strong> Select a higher-quality image model for professional results, especially for featured images. Cheaper models are faster but may lack detail or accuracy.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Select the right auxiliary AI model:</strong> Subheading generation and AI image prompts rely on the auxiliary AI model chosen in the sidebar. Pick a capable model for better suggestions and relevant images.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Review the structure preview:</strong> Before generating the full article, check the preview to ensure the layout, details, and image settings align with your goals.
     </li>
     <li style={{ marginBottom: '8px' }}>
       <strong>Use AI suggestions as a starting point:</strong> Let the AI generate subheadings or images, then tweak them to fit your unique voice or needs.
     </li>
   </Typography>
 </DialogContent>
      </Dialog>

     {/* Preview Dialog */}
     <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {previewPrompt?.name}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {previewPrompt?.prompt_text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
      <ArticleResponseDialog
        open={responseDialogOpen}
        onClose={() => setResponseDialogOpen(false)}
        success={!!generatedArticle}
        error={error}
        articleData={generatedArticle}
        onPreview={() => {
          setResponseDialogOpen(false);
          setPreviewDialogOpen(true);
        }}
      />

      <ArticlePreviewDialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        article={generatedArticle}
      />
       <LeaveConfirmationDialog
      open={leaveDialogOpen}
      onClose={() => setLeaveDialogOpen(false)}
      onConfirm={() => {
        setIsGenerating(false);
        setLeaveDialogOpen(false);
        if (navigationTarget) {
          window.location.href = navigationTarget;
        }
      }}
    />
    <NavigationDialog />
    </Box>
  );
};

export default SingleArticle;