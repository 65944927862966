import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Tilt } from 'react-tilt';
import Particles from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { 
  TestTubeDiagonal, 
  FileText, 
  Star, 
  ListOrdered, 
  Image,
  Link2,
  PenLine,
  Bot,
  Sparkles, // Replace Hand with Sparkles
  Facebook,
} from 'lucide-react';

const HomePage = () => {
  const tools = [
    { title: "Create New Prompt", description: "Craft custom prompts in PromptLab.", icon: PenLine, path: "/prompts/new", color: "border-orange-500" },
    { title: "Single Article", description: "Build detailed articles with ease.", icon: FileText, path: "/articles/single", color: "border-blue-500" },
    { title: "Bulk Generator", description: "Mass-produce quality articles fast.", icon: Bot, path: "/articles/standard", color: "border-green-500" },
    { title: "Product Reviews", description: "Generate Amazon review roundups.", icon: Star, path: "/articles/reviews", color: "border-yellow-500" },
    { title: "Listicle Writer", description: "Create engaging listicles.", icon: ListOrdered, path: "/articles/lists", color: "border-purple-500" },
    { title: "URL Rewriter", description: "Rewrite content uniquely.", icon: Link2, path: "/url-rewriter", color: "border-indigo-500" },
    { title: "Image Generator", description: "AI-crafted images for your work.", icon: Image, path: "/images", color: "border-pink-500" },
    { title: "Saved Prompts", description: "Manage your prompt library.", icon: TestTubeDiagonal, path: "/prompts/saved", color: "border-teal-500" },
    { title: "FB Image/Meme Generator", description: "Eye-catching Facebook visuals.", icon: Image, path: "/social/facebook", color: "border-blue-600" },
    { title: "Facebook Ideas", description: "Inspiration for FB posts.", icon: PenLine, path: "/social/facebookideas", color: "border-orange-600" },
  ];

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesOptions = {
    particles: {
      number: { value: 50, density: { enable: true, value_area: 800 } },
      color: { value: "#666666" },
      shape: { type: "circle" },
      opacity: { value: 0.3, random: true },
      size: { value: 3, random: true },
      move: { enable: true, speed: 1, direction: "none", random: true, out_mode: "out" },
    },
    interactivity: {
      events: { onhover: { enable: true, mode: "repulse" }, onclick: { enable: true, mode: "push" } },
      modes: { repulse: { distance: 100 }, push: { quantity: 4 } },
    },
  };

  return (
    <div className="min-h-screen bg-white text-gray-900 overflow-hidden relative">
      {/* Particles Background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
        className="absolute inset-0 z-0"
      />

      {/* Hero Section */}
      <div className="relative z-10 pt-20 pb-16 text-center">
        
        <p className="mt-4 text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto">
          Your AI-powered content creation home 
        </p>
       
      </div>

      {/* Tools Grid */}
      <div className="relative z-10 max-w-7xl mx-auto px-6 pb-16">
       
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {tools.map((tool) => (
            <Tilt key={tool.path} options={{ max: 25, scale: 1.05, speed: 300 }}>
              <Link
                to={tool.path}
                className={`block p-6 bg-gray-800 rounded-xl border-2 ${tool.color} hover:bg-gray-700 transition-all duration-300 shadow-xl hover:shadow-2xl glow-effect`}
              >
                <tool.icon size={32} className="text-white mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">{tool.title}</h3>
                <p className="text-sm text-gray-300">{tool.description}</p>
              </Link>
            </Tilt>
          ))}
        </div>
      </div>

      {/* Facebook Section */}
      <div className="relative z-10 max-w-4xl mx-auto px-6 pb-16">
        <div className="bg-gray-800 p-6 rounded-xl shadow-xl flex flex-col md:flex-row items-center gap-6 border-2 border-orange-500">
          <div className="w-16 h-16 rounded-full bg-orange-500 flex items-center justify-center shrink-0">
            <Facebook size={32} className="text-white" />
          </div>
          <div className="flex-1 text-center md:text-left">
            <h3 className="text-2xl font-bold text-white mb-2">Join the Mission</h3>
            <p className="text-gray-300 mb-4">
              Connect with Scripti users in our Facebook group for official support and tips.
            </p>
            <a
              href="https://www.facebook.com/groups/660096973209653"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-3 bg-orange-500 text-white rounded-full font-medium hover:bg-orange-600 transition-all duration-300"
            >
             Join Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;