import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Box,
  Alert
} from '@mui/material';
import SecureCredentialsField from './SecureCredentialsField';

const AmazonSettings = ({ onSave, initialData, isLoading }) => {
  const [formData, setFormData] = useState({
    api_key: '',
    secret_key: '',
    tracking_id: ''
  });

  const [fieldErrors, setFieldErrors] = useState({
    api_key: '',
    secret_key: '',
    tracking_id: ''
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (initialData) {
      setFormData({
        api_key: initialData.api_key || '',
        secret_key: initialData.secret_key || '',
        tracking_id: initialData.tracking_id || ''
      });
    }
  }, [initialData]);

  const handleChange = (field) => (e) => {
    const newValue = e.target.value;
    setFormData(prev => ({
      ...prev,
      [field]: newValue
    }));
    // Clear any error for this field when it's being edited
    setFieldErrors(prev => ({
      ...prev,
      [field]: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setFieldErrors({
      api_key: '',
      secret_key: '',
      tracking_id: ''
    });

    const updatedSettings = {};
    
    if (formData.api_key && formData.api_key !== '••••••••') {
      updatedSettings.api_key = formData.api_key;
    }
    if (formData.secret_key && formData.secret_key !== '••••••••') {
      updatedSettings.secret_key = formData.secret_key;
    }
    if (formData.tracking_id) {
      updatedSettings.tracking_id = formData.tracking_id;
    }

    try {
      await onSave(updatedSettings);
    } catch (err) {
      if (err.response?.data?.detail) {
        // Handle structured validation errors
        if (Array.isArray(err.response.data.detail)) {
          const newFieldErrors = {};
          err.response.data.detail.forEach(error => {
            if (error.loc && error.loc[1]) {
              newFieldErrors[error.loc[1]] = error.msg;
            }
          });
          setFieldErrors(newFieldErrors);
        } else {
          // Handle simple error message
          setError(err.response.data.detail);
        }
      } else {
        setError('Failed to save settings');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        
        <Grid item xs={12}>
          <SecureCredentialsField
            label="Access Key ID"
            value={formData.api_key}
            onChange={handleChange('api_key')}
            disabled={isLoading}
            error={Boolean(fieldErrors.api_key)}
            helperText={fieldErrors.api_key}
          />
        </Grid>

        <Grid item xs={12}>
          <SecureCredentialsField
            label="Amazon Secret Key"
            value={formData.secret_key}
            onChange={handleChange('secret_key')}
            disabled={isLoading}
            error={Boolean(fieldErrors.secret_key)}
            helperText={fieldErrors.secret_key}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Amazon Tracking ID"
            value={formData.tracking_id}
            onChange={handleChange('tracking_id')}
            disabled={isLoading}
            error={Boolean(fieldErrors.tracking_id)}
            helperText={fieldErrors.tracking_id || "Format: username-20"}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              fullWidth
              sx={{ borderRadius: 2, px: 3, backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
            >
              Save Amazon Settings
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default AmazonSettings;