import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  Grid,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  Card,
  CardContent,
  Tooltip,
  Snackbar,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  FileText,
  Copy,
  Download,
  RefreshCw,
  CheckCircle,
  HelpCircle
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import PageHeader from './PageHeader';
import apiClient from '../utils/apiUtils';
import aiClient from '../api/clients/AiClient';
import { useAIModels } from '../hooks/useAIModels';
import FacebookPostCard from './FacebookPostCard';


const postTypes = [
  { value: 'general', label: 'General Posts' },
  { value: 'sensational', label: 'Sensational Revelations', 
    prompt: 'Make the posts attention-grabbing and surprising, using phrases like "You won\'t believe..." or "Shocking truth about..."' },
  { value: 'secrets', label: 'Secret Techniques', 
    prompt: 'Frame the posts as insider knowledge or expert secrets, using phrases like "Little-known method..." or "Expert secret for..."' },
  { value: 'dangers', label: 'Hidden Dangers', 
    prompt: 'Focus on warning about lesser-known risks or dangers, using phrases like "Warning:" or "Hidden danger of..."' },
  { value: 'mistakes', label: 'Common Mistakes to Avoid', 
    prompt: 'Highlight mistakes and their solutions, using phrases like "Stop doing..." or "Biggest mistake when..."' },
  { value: 'tips', label: 'Pro Tips & Hacks', 
    prompt: 'Share practical tips and clever solutions, using phrases like "Quick hack for..." or "Pro tip:"' },
  { value: 'myths', label: 'Myth Busting', 
    prompt: 'Debunk common misconceptions, using phrases like "The truth about..." or start with "Common myth:"' },
  { value: 'psychology', label: 'Psychology & Behavior', 
    prompt: 'Focus on human behavior and psychology insights, using phrases like "Why people..." or "The psychology of..."' }
];

const postStyles = [
    { 
      value: 'conversational', 
      label: 'Conversational',
      description: 'Friendly, engaging posts with emojis and questions'
    },
    { 
      value: 'headline', 
      label: 'Attention-Grabbing Headlines',
      description: 'Bold, dramatic headlines with asterisks and capitalization'
    },
    { 
      value: 'listicle', 
      label: 'Listicle Format',
      description: 'Numbered lists highlighting key points'
    },
    { 
      value: 'dramatic', 
      label: 'Dramatic/Sensational',
      description: 'Emphasis on shock value and urgency'
    }
  ];

const FacebookPostIdeas = () => {
  // Basic state
  const [keyword, setKeyword] = useState('');
  const [postType, setPostType] = useState('general');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedIdeas, setGeneratedIdeas] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [postStyle, setPostStyle] = useState('conversational');
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Auth and API hooks
  const { authenticatedRequest, error: authError, loading: authLoading } = useAuth();
  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);
  const [model, setModel] = useState('');

  useEffect(() => {
    const initializeAI = async () => {
      if (availableModels.length > 0) {
        try {
          const keys = await authenticatedRequest(() => apiClient.getApiKeys());
          aiClient.setApiKeys(keys);
          
          // Update AI client with model configurations
          aiClient.updateModelConfigs(availableModels);
          
          // Set initial model if none selected
          if (!model && availableModels.length > 0) {
            const defaultModel = availableModels[0].value;
            console.log('Setting default model:', defaultModel);
            setModel(defaultModel);
          }
    
        } catch (error) {
          console.error('Error initializing AI client:', error);
          setError('Failed to initialize AI services');
        }
      }
    };
  
    initializeAI();
  }, [availableModels, authenticatedRequest, model]);

  useEffect(() => {
    if (!authLoading) {
      initializeServices();
    }
  }, [authLoading]);

  const initializeServices = async () => {
    try {
      const keys = await authenticatedRequest(() => apiClient.getApiKeys());
      
      if (!keys.openai && !keys.anthropic && !keys.openrouter) {
        throw new Error("No API keys configured. Please add required API keys in settings.");
      }
      
      aiClient.setApiKeys(keys);
    } catch (error) {
      console.error("Failed to initialize services:", error);
      setError(error.message || 'Failed to initialize services');
    }
  };

  // Show loading state while initializing
  if (authLoading || modelsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };

  const getModelId = (selectedModel) => {
    // Map the UI model selection to the actual model ID
    switch (selectedModel) {
      case 'claude':
        return 'claude-3-sonnet-20240222';
      case 'gpt-4':
        return 'gpt-4-turbo-preview';
      default:
        return selectedModel; // For OpenRouter models, use the value directly
    }
  };


  const generateIdeas = async () => {
    if (!keyword.trim()) {
      setError('Please enter a keyword or topic');
      return;
    }
  
    setLoading(true);
    setError(null);
    setGeneratedIdeas([]);
  
    try {
      const auxModel = getAuxiliaryModel();
      if (!auxModel) {
        throw new Error('No AI models available');
      }
  
      const selectedType = postTypes.find(type => type.value === postType);
      const typeSpecificPrompt = selectedType?.prompt || '';
  
      const stylePrompts = {
        conversational: `
          For each post:
          - Use a friendly, conversational tone
          - Include emojis and hashtags
          - Ask engaging questions
          - Keep posts between 50-200 characters
          - Make them sound natural and casual
        `,
        headline: `
          For each post:
          - Use bold formatting with asterisks (**)
          - Create attention-grabbing headlines
          - Include numbers and strong statements
          - Focus on dramatic reveals and shocking facts
          - Format as bold statements
        `,
        listicle: `
          Generate ONLY attention-grabbing listicle headlines. Each post should:
          - Start with a number (e.g., "5 Ways...", "7 Shocking...")
          - Use asterisks for bold formatting (**)
          - Focus on list-style titles ONLY
          - Do NOT include the actual list items
          - Format as: "**7 Shocking Ways Your Dog is Trying to Tell You Something!**"
          
          Example formats:
          "**5 Hidden Dangers Lurking in Your Dog's Food!**"
          "**10 Secret Signs Your Cat is Actually Unhappy**"
          "**7 Common Household Items That Could Kill Your Pet**"
        `,
        dramatic: `
          For each post:
          - Use dramatic language and urgency
          - Include words like "Shocking", "Warning", "Must-Know"
          - Add exclamation marks for emphasis
          - Create a sense of importance and immediacy
          - Focus on urgent, attention-grabbing content
        `
      };
  
      const prompt = `Generate 20 engaging Facebook post ideas about "${keyword}". ${typeSpecificPrompt}
      
      ${stylePrompts[postStyle]}
      
      Important Instructions:
      - Return ONLY the finished post ideas, one per line
      - Do NOT include any explanations, thinking, or meta-commentary
      - Do NOT include phrases like "Here are..." or "Now I will..."
      - Do NOT explain your process or reasoning
      - Do NOT acknowledge or explain the task
      - Do NOT include any introductory or concluding text
      - Just output the 20 post ideas directly, nothing else
      
      Additional requirements:
      - Make content compelling and shareable
      - Match the selected post type: ${selectedType.label}
      - For headline, listicle, and dramatic styles, use ** for emphasis
      - Each post should be a complete, ready-to-use message`;
  
      const response = await aiClient.generateArticleContent({
        title: '',
        modelId: auxModel,
        customPrompt: prompt
      });
  
      const ideas = response
        .split('\n')
        .filter(line => {
          const cleanLine = line.trim();
         
          const metaPatterns = [
            /^Alright,\s*so/i,
            /^First,\s*I\s*need/i,
            /^I\s*should/i,
            /^Next,\s*I\s*need/i,
            /^I'll\s*start/i,
            /^I\s*also\s*need/i,
            /^Let's/i,
            /^Now,\s*I/i,
            /^Here\s*are/i,
            /the\s*user\s*wants/i,
            /they\s*specified/i,
            /content\s*creator/i,
            /social\s*media\s*manager/i,
            /target\s*audience/i
          ];
          
          
          return !metaPatterns.some(pattern => pattern.test(cleanLine));
        })
        .map(line => {
          
          let cleanLine = line.replace(/^(\d+\.\s+)(?=\*\*|[A-Z])/, '').trim();
          cleanLine = cleanLine.replace(/\*\*/g, '');
          return cleanLine;
        })
        .filter(line => line.length > 0 && line.length < 200); 
      
      setGeneratedIdeas(ideas);
    } catch (err) {
      console.error('Generation error:', err);
      setError(err.message || 'Failed to generate ideas');
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (index) => {
    navigator.clipboard.writeText(generatedIdeas[index]);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000);
    
    setNotification({
      open: true,
      message: 'Post copied to clipboard!',
      severity: 'success'
    });
  };

  const handleExport = () => {
    if (generatedIdeas.length === 0) return;

    const text = generatedIdeas.join('\n\n');
    const blob = new Blob([text], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `facebook-post-ideas-${keyword.replace(/\s+/g, '-')}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    setNotification({
      open: true,
      message: 'Ideas exported successfully!',
      severity: 'success'
    });
  };

  return (
    // Replace the entire Grid container section with:
    <Box sx={{ mt: 4, mb: 4 }}>
      {/* Keep your existing header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <PageHeader 
          category="Social Content"
          title="Facebook Post Ideas Generator" 
          description="Generate engaging Facebook post ideas from any keyword or topic"
          icon={<FileText size={24} color="#E04C16" />} 
        />
        <Tooltip title="Export all ideas">
          <span>
            <IconButton
              onClick={handleExport}
              disabled={generatedIdeas.length === 0}
              sx={{
                bgcolor: '#E04C16',
                color: 'white',
                '&:hover': {
                  bgcolor: '#c43d0f'
                },
                '&.Mui-disabled': {
                  bgcolor: '#ffd7c9',
                  color: '#666'
                }
              }}
            >
              <Download size={20} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    
      {/* Controls Section */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Enter keyword or topic"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="e.g., healthy recipes, travel tips, digital marketing"
              required
              disabled={loading}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#E04C16'
                  }
                }
              }}
            />
            <FormHelperText>
              Enter a specific topic to generate engaging Facebook posts
            </FormHelperText>
          </Grid>
    
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Post Type</InputLabel>
              <Select
                value={postType}
                label="Post Type"
                onChange={(e) => setPostType(e.target.value)}
                disabled={loading}
              >
                {postTypes.map((type) => (
                  <MenuItem 
                    key={type.value} 
                    value={type.value}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      py: 1
                    }}
                  >
                    <Typography variant="subtitle2">
                      {type.label}
                    </Typography>
                    {type.prompt && (
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ mt: 0.5 }}
                      >
                        {type.prompt}
                      </Typography>
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a post type to generate more targeted content
              </FormHelperText>
            </FormControl>
          </Grid>
    
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Post Style</InputLabel>
              <Select
                value={postStyle}
                label="Post Style"
                onChange={(e) => setPostStyle(e.target.value)}
                disabled={loading}
              >
                {postStyles.map((style) => (
                  <MenuItem 
                    key={style.value} 
                    value={style.value}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      py: 1
                    }}
                  >
                    <Typography variant="subtitle2">
                      {style.label}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      {style.description}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Choose how your posts should be formatted and styled
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
    
        {error && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {error}
          </Alert>
        )}
    
        <Button
          fullWidth
          variant="contained"
          onClick={generateIdeas}
          disabled={loading || !keyword.trim()}
          startIcon={loading ? <CircularProgress size={20} /> : <RefreshCw size={20} />}
          sx={{
            mt: 3,
            bgcolor: '#E04C16',
            '&:hover': {
              bgcolor: '#c43d0f'
            }
          }}
        >
          {loading ? 'Generating Ideas...' : 'Generate Ideas'}
        </Button>
      </Paper>
    {/* Results Grid */}
    {generatedIdeas.length === 0 ? (
      <Box sx={{ 
        height: '200px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <Typography color="text.secondary">
          Enter a keyword and click generate to see post ideas
        </Typography>
      </Box>
    ) : (
      <Grid container spacing={3}>
        {generatedIdeas.map((idea, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <FacebookPostCard
              idea={idea}
              index={index}
              copiedIndex={copiedIndex}
              onCopy={handleCopy}
            />
          </Grid>
        ))}
      </Grid>
    )}
     
    
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
   
  );
};

export default FacebookPostIdeas;