import React, { useState } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PenSquare, Eye, Save, X } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import PageHeader from './PageHeader';

const CustomPromptEditor = () => {
  const navigate = useNavigate();
  const { authenticatedRequest } = useAuth();
  
  // Basic state
  const [promptName, setPromptName] = useState('');
  const [promptContent, setPromptContent] = useState('');
  const [error, setError] = useState(null);
  
  // Preview Dialog state
  const [previewOpen, setPreviewOpen] = useState(false);
  
  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleSave = async () => {
    if (!promptName.trim() || !promptContent.trim()) {
      setError('Please fill in both the name and content for your prompt');
      return;
    }
  
    try {
      await authenticatedRequest(() => 
        apiClient.createPrompt({
          name: promptName,
          prompt_text: promptContent,
          type: 'custom',
          settings: {
            type: 'custom',
            version: '1.0',
            description: 'Custom user prompt',
            variables: []
          }
        })
      );
  
      setNotification({
        open: true,
        message: 'Prompt saved successfully!',
        severity: 'success'
      });
  
      // Navigate after notification
      setTimeout(() => navigate('/prompts/saved'), 1500);
  
    } catch (error) {
      setError(error.message || 'Failed to save prompt');
    }
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, bgcolor: '#f8fafc' }}>
      {/* Header */}
      <PageHeader 
        category="Prompt Lab"
        title="Custom Prompt Editor"
        description="Create and edit your custom prompts"
        icon={<PenSquare size={24} color="#E04C16" />}
      />

      {/* Main Editor */}
      <Paper sx={{ 
        mt: 4,
        borderRadius: 3,
        overflow: 'hidden',
        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        bgcolor: '#fff',
        border: '1px solid',
        borderColor: 'rgba(0,0,0,0.06)'
      }}>
        {error && (
          <Alert 
            severity="error" 
            sx={{ borderRadius: 0 }}
          >
            {error}
          </Alert>
        )}

        {/* Name Input */}
        <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'rgba(0,0,0,0.06)' }}>
          <TextField
            fullWidth
            label="Prompt Name"
            value={promptName}
            onChange={(e) => setPromptName(e.target.value)}
            required
            variant="filled"
            placeholder="Give your prompt a descriptive name"
            sx={{
              '& .MuiFilledInput-root': {
                backgroundColor: 'rgba(0,0,0,0.02)'
              }
            }}
          />
        </Box>

        {/* Prompt Content Editor */}
        <TextField
          fullWidth
          multiline
          rows={20}
          value={promptContent}
          onChange={(e) => setPromptContent(e.target.value)}
          required
          placeholder="Write or paste your prompt here..."
          variant="filled"
          sx={{
            '& .MuiFilledInput-root': {
              padding: 3,
              backgroundColor: 'transparent',
            },
            '& .MuiFilledInput-input': {
              fontFamily: 'monospace',
              fontSize: '0.9rem',
              lineHeight: 1.6
            }
          }}
          InputProps={{
            disableUnderline: true
          }}
        />

        {/* Actions */}
        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid', 
          borderColor: 'rgba(0,0,0,0.06)',
          bgcolor: 'rgba(0,0,0,0.02)',
          display: 'flex',
          gap: 2
        }}>
          <Button
            variant="contained"
            onClick={handleSave}
            startIcon={<Save size={18} />}
            sx={{
              bgcolor: '#E04C16',
              '&:hover': {
                bgcolor: '#c43d0f'
              }
            }}
          >
            Save Prompt
          </Button>
          <Button
            variant="outlined"
            onClick={() => setPreviewOpen(true)}
            startIcon={<Eye size={18} />}
          >
            Preview
          </Button>
        </Box>
      </Paper>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}>
          <Typography variant="h6">
            {promptName || 'Untitled Prompt'}
          </Typography>
          <IconButton 
            onClick={() => setPreviewOpen(false)}
            size="small"
          >
            <X size={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
            p: 2
          }}>
            {promptContent}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomPromptEditor;