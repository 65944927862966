import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  LayoutTemplate,
  Save,
  X,
  HelpCircle,
  MessageSquare,
  Copy,
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import PageHeader from './PageHeader';

const TemplateEditor = () => {
  const navigate = useNavigate();
  const { authenticatedRequest } = useAuth();
  
  // State management
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newPromptContent, setNewPromptContent] = useState('');
  const [newPromptName, setNewPromptName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Dialog states
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      // When a template is selected, copy its content to the editor
      setNewPromptContent(selectedTemplate.prompt_text);
      setNewPromptName(`${selectedTemplate.name} (Custom)`);
    } else {
      setNewPromptContent('');
      setNewPromptName('');
    }
  }, [selectedTemplate]);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await authenticatedRequest(() => 
        apiClient.getSavedPrompts()
      );
      
      // Only load system templates
      setTemplates(response.system_templates || []);
    } catch (err) {
      setError('Failed to load templates');
      console.error('Error fetching templates:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveNewPrompt = async () => {
    if (!newPromptName.trim() || !newPromptContent.trim()) {
      setError('Please provide both a name and content for your prompt');
      return;
    }

    try {
      await authenticatedRequest(() => 
        apiClient.createPrompt({
          name: newPromptName,
          prompt_text: newPromptContent,
          type: 'custom',
          settings: {
            type: 'custom',
            version: '1.0',
            description: 'Based on system template',
            variables: []
          }
        })
      );

      setNotification({
        open: true,
        message: 'New prompt saved successfully!',
        severity: 'success'
      });

      // Reset form
      setSelectedTemplate(null);
      setNewPromptContent('');
      setNewPromptName('');

    } catch (error) {
      setError(error.message || 'Failed to save new prompt');
    }
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, bgcolor: '#f8fafc' }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <PageHeader 
          category="Prompt Lab"
          title="Prompt Templates"
          description="Browse our collection of prompt templates and use them as a starting point for your content."
          icon={<LayoutTemplate size={24} color="#E04C16" />}
        />
        <Button
          onClick={() => setHelpDialogOpen(true)}
          startIcon={<HelpCircle size={18} />}
          sx={{
            bgcolor: '#E04C16',
            color: 'white',
            borderRadius: '20px',
            px: 3,
            '&:hover': {
              bgcolor: '#c43d0f'
            }
          }}
        >
          How to Use
        </Button>
      </Box>

      <Grid container spacing={3}>
        {/* Left Column - Template List */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            p: 3,
            borderRadius: 3,
            boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
            bgcolor: '#fff',
            border: '1px solid',
            borderColor: 'rgba(0,0,0,0.06)'
          }}>
            <Typography variant="h6" gutterBottom>
              Available Templates
            </Typography>
            
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <List>
                {templates.map((template) => (
                  <React.Fragment key={template.id}>
                    <ListItem
                      button
                      selected={selectedTemplate?.id === template.id}
                      onClick={() => setSelectedTemplate(template)}
                      sx={{
                        borderRadius: 2,
                        mb: 1,
                        transition: 'all 0.2s',
                        '&.Mui-selected': {
                          bgcolor: 'rgba(224, 76, 22, 0.1)',
                          '&:hover': {
                            bgcolor: 'rgba(224, 76, 22, 0.15)'
                          }
                        },
                        '&:hover': {
                          bgcolor: 'rgba(0, 0, 0, 0.02)'
                        }
                      }}
                    >
                      <ListItemIcon>
                        <MessageSquare size={20} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={template.name}
                        secondary={template.description || 'System template'}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => setSelectedTemplate(template)}
                          title="Use as template"
                        >
                          <Copy size={18} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                  </React.Fragment>
                ))}
              </List>
            )}
          </Paper>
        </Grid>

        {/* Right Column - Editor */}
        <Grid item xs={12} md={8}>
          {selectedTemplate ? (
            <Box sx={{ 
              bgcolor: '#fff',
              borderRadius: 3,
              overflow: 'hidden',
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
              border: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              {/* Name Input */}
              <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'rgba(0,0,0,0.06)' }}>
                <TextField
                  fullWidth
                  label="New Prompt Name"
                  value={newPromptName}
                  onChange={(e) => setNewPromptName(e.target.value)}
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'rgba(0,0,0,0.02)'
                    }
                  }}
                />
              </Box>

              {/* Content Editor */}
              <TextField
                fullWidth
                multiline
                rows={20}
                value={newPromptContent}
                onChange={(e) => setNewPromptContent(e.target.value)}
                variant="filled"
                placeholder="Modify the template content as needed..."
                sx={{
                  '& .MuiFilledInput-root': {
                    padding: 3,
                    backgroundColor: 'transparent',
                  },
                  '& .MuiFilledInput-input': {
                    fontFamily: 'monospace',
                    fontSize: '0.9rem',
                    lineHeight: 1.6
                  }
                }}
                InputProps={{
                  disableUnderline: true
                }}
              />

              {/* Actions */}
              <Box sx={{ 
                p: 3, 
                borderTop: '1px solid', 
                borderColor: 'rgba(0,0,0,0.06)',
                bgcolor: 'rgba(0,0,0,0.02)',
                display: 'flex',
                gap: 2
              }}>
                <Button
                  variant="contained"
                  onClick={handleSaveNewPrompt}
                  startIcon={<Save size={18} />}
                  sx={{
                    bgcolor: '#E04C16',
                    '&:hover': {
                      bgcolor: '#c43d0f'
                    }
                  }}
                >
                  Save as New Prompt
                </Button>
              </Box>
            </Box>
          ) : (
            <Paper sx={{ 
              p: 6,
              borderRadius: 3,
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
              bgcolor: '#fff',
              border: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '500px'
            }}>
              <MessageSquare size={48} strokeWidth={1} color="#94a3b8" />
              <Typography sx={{ mt: 2, color: '#64748b' }}>
                Select a template to get started
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Help Dialog */}
      <Dialog 
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        maxWidth="sm"
        PaperProps={{
          sx: {
            position: 'fixed',
            right: 32,
            top: '50%',
            transform: 'translateY(-50%)',
            m: 0,
            width: 400,
            maxWidth: '90vw',
            maxHeight: '80vh',
            borderRadius: 2
          }
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
            How to Use Templates
          </DialogTitle>
          <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
            <X size={18} />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Using Templates
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            Browse our collection of pre-made templates and use them as a starting point for your content. Select any template to view and customize it for your needs.
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Customizing Templates
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            • Choose a template from the list
            • Modify the content to match your needs
            • Give your customized version a unique name
            • Save it as your own prompt
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Best Practices
          </Typography>
          <Typography variant="body2">
            • Use descriptive names for your saved prompts
            • Keep modifications clear and purposeful
            • Test your customized prompts
            • Save your work when satisfied
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHelpDialogOpen(false)}>
            Got It
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TemplateEditor;