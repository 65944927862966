export const formatProductReview = (content, title = '', options = {}) => {
  const { 
    includeDisclaimer = false,
    disclaimerText = '',
    disclaimerPosition = 'top',
    includeCheckButton = false,
    buttonColor = '#FF9900',
    useAffiShortcodes = false,
    affiShortcodeTemplate = '[affiai asin="{asin}" template="box"]',
    affiShortcodePlacement = 'after-title',
    tracking_id = null,
    includeBuyingGuide = false,
    buyingGuidePosition = 'top',
    includeProsConsSection = true,
    amazonDomain,
    borderedSections = [],
    includeAffiCarousel = false, 
    productAsins = ''
  } = options;

  const adjustColor = (color, amount) => {
    const clamp = (num) => Math.min(255, Math.max(0, num));
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const adjustedR = clamp(r + amount);
    const adjustedG = clamp(g + amount);
    const adjustedB = clamp(b + amount);
    const toHex = (n) => n.toString(16).padStart(2, '0');
    return `#${toHex(adjustedR)}${toHex(adjustedG)}${toHex(adjustedB)}`;
  };

  const generateButtonHtml = (asin) => {
    const baseUrl = `https://amazon.${amazonDomain}/dp/${asin}`;
    const url = tracking_id ? `${baseUrl}?tag=${tracking_id}` : baseUrl;
    const hoverColor = adjustColor(buttonColor, -15);
    
    return `
      <!-- wp:buttons {"layout":{"type":"flex","justifyContent":"center"},"style":{"spacing":{"margin":{"bottom":"60px"}}}} -->
      <div class="wp-block-buttons" style="margin-bottom:60px">
        <!-- wp:button {"style":{"color":{"background":"${buttonColor}"},"spacing":{"padding":{"top":"12px","bottom":"12px","left":"24px","right":"24px"}}}} -->
        <div class="wp-block-button">
          <a href="${url}" 
             class="wp-block-button__link has-background" 
             style="background-color:${buttonColor};border-radius:4px;font-weight:500;transition:background-color 0.2s ease;text-decoration:none !important;" 
             target="_blank" 
             rel="nofollow noopener"
             onmouseover="this.style.backgroundColor='${hoverColor}'"
             onmouseout="this.style.backgroundColor='${buttonColor}'">
            Check on Amazon
          </a>
        </div>
        <!-- /wp:button -->
      </div>
      <!-- /wp:buttons -->
    `;
  };

  const generateDisclaimerHtml = () => {
    return `
      <!-- wp:group {"style":{"spacing":{"padding":{"top":"15px","bottom":"15px","left":"15px","right":"15px"},"margin":{"top":"20px","bottom":"20px"}},"border":{"width":"1px","radius":"4px"}},"backgroundColor":"grey-100"} -->
      <div class="wp-block-group has-grey-100-background-color" style="border:1px solid #dee2e6;border-radius:4px;padding:15px;margin-top:20px;margin-bottom:20px;background-color:#f8f9fa;font-size:0.9em;color:#6c757d;">
        ${disclaimerText}
      </div>
      <!-- /wp:group -->
    `;
  };

  const generateBuyingGuideHtml = (content) => {
    return `
      <!-- wp:group {"style":{"spacing":{"padding":{"top":"24px","bottom":"24px","left":"24px","right":"24px"},"margin":{"top":"24px","bottom":"24px"}},"border":{"width":"1px","radius":"4px"}},"backgroundColor":"grey-50"} -->
      <div class="wp-block-group" style="border:1px solid #dee2e6;border-radius:4px;padding:24px;margin:24px 0;background-color:#f8f9fa;">
        <!-- wp:heading -->
        <h2 style="margin-top:0;margin-bottom:16px;font-size:1.5em;font-weight:600;">Buying Guide</h2>
        <!-- /wp:heading -->
        ${formatParagraphs(content)}
      </div>
      <!-- /wp:group -->
    `;
  };

  const generateProsConsHtml = (pros, cons) => {
    const tableStyles = 'width:100%;border-collapse:collapse;margin:20px 0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Arial,sans-serif;';
    const thStyles = 'background-color:#f8f9fa;padding:12px;text-align:left;border:1px solid #dee2e6;font-weight:600;';
    const tdStyles = 'padding:12px;border:1px solid #dee2e6;vertical-align:top;';
    const ulStyles = 'margin:0;padding-left:20px;';
    const liStyles = 'margin:8px 0;';

    return `
      <!-- wp:table {"className":"pros-cons-table"} -->
      <figure class="wp-block-table">
        <table style="${tableStyles}">
          <thead>
            <tr>
              <th style="${thStyles}">Pros</th>
              <th style="${thStyles}">Cons</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="${tdStyles}">
                <ul style="${ulStyles}">
                  ${pros.map(pro => `<li style="${liStyles}">${pro}</li>`).join('')}
                </ul>
              </td>
              <td style="${tdStyles}">
                <ul style="${ulStyles}">
                  ${cons.map(con => `<li style="${liStyles}">${con}</li>`).join('')}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <!-- /wp:table -->
    `;
  };

  const formatParagraphs = (text) => {
    let formatted = text
      .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
      .replace(/\*(.+?)\*/g, '<em>$1</em>')
      .replace(/_(.+?)_/g, '<em>$1</em>')
      .replace(/`([^`]+)`/g, '<code>$1</code>')
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank" rel="noopener">$1</a>');

    return formatted
      .split('\n\n')
      .map(paragraph => {
        const trimmed = paragraph.trim();
        if (!trimmed || trimmed.startsWith('<!--')) {
          return trimmed;
        }
        if (trimmed.match(/^[-*]\s/)) { // Detect bullet lists
          const lines = trimmed.split('\n').map(line => {
            const match = line.match(/^[-*]\s+(.*)$/);
            return match ? `<li>${match[1]}</li>` : line;
          }).join('');
          return `<!-- wp:list --><ul>${lines}</ul><!-- /wp:list -->`;
        }
        return `
          <!-- wp:paragraph -->
          <p style="margin-bottom:1em;line-height:1.7;">${trimmed}</p>
          <!-- /wp:paragraph -->
        `;
      })
      .join('\n');
  };

  let formattedContent = content;

  // Normalize newlines before headings to ensure they match regex
  formattedContent = formattedContent.replace(/(^|\n)(#{1,4}\s+)/g, '\n$2');

  // Process product sections with ASIN FIRST
  formattedContent = formattedContent.replace(
    /## \[ASIN:([A-Z0-9]+)\] (\d+)\. (.+?)(?=\n)([\s\S]*?)(?=(?:## \[ASIN:|###|$))/g,
    (match, asin, number, title, sectionContent) => {
      const linkedTitle = `
        <!-- wp:heading -->
        <h2>
          <a href="https://amazon.${amazonDomain}/dp/${asin}" 
             style="display:inline-block;max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-weight:500;text-decoration:none;" 
             target="_blank" 
             rel="nofollow noopener">
            ${number}. ${title.trim()}
          </a>
        </h2>
        <!-- /wp:heading -->
      `;

      let processedSection = '';
      
      if (useAffiShortcodes) {
        processedSection += affiShortcodeTemplate.replace('{asin}', asin) + '\n';
      }

      const prosMatch = sectionContent.match(/Pros:([\s\S]*?)(?=Cons:)/);
      const consMatch = sectionContent.match(/Cons:([\s\S]*?)(?=\n\n|$)/);

      if (prosMatch && consMatch && includeProsConsSection) {
        const pros = prosMatch[1].trim().split('\n').map(p => p.trim()).filter(p => p);
        const cons = consMatch[1].trim().split('\n').map(c => c.trim()).filter(c => c);
        
        const beforeProscons = sectionContent.split('Pros:')[0];
        const afterProscons = sectionContent.split('Cons:')[1].split('\n\n')[1] || '';
        
        processedSection += formatParagraphs(beforeProscons);
        processedSection += generateProsConsHtml(pros, cons);
        processedSection += formatParagraphs(afterProscons);
        
        if (includeCheckButton) {
          processedSection += generateButtonHtml(asin);
        }
      } else {
        processedSection += formatParagraphs(sectionContent);
        if (includeCheckButton) {
          processedSection += generateButtonHtml(asin);
        }
      }

      return `${linkedTitle}\n${processedSection}\n`;
    }
  );

  // Process all headings (including # for h1)
  formattedContent = formattedContent
    .replace(/^#\s+(.*)$/gm, (match, title) => `
      <!-- wp:heading {"level":1} -->
      <h1>${title.trim()}</h1>
      <!-- /wp:heading -->
    `)
    .replace(/^##\s+(?!\[ASIN:)(.*)$/gm, (match, title) => `
      <!-- wp:heading -->
      <h2>${title.trim()}</h2>
      <!-- /wp:heading -->
    `)
    .replace(/^###\s+(.*)$/gm, (match, title) => `
      <!-- wp:heading {"level":3} -->
      <h3>${title.trim()}</h3>
      <!-- /wp:heading -->
    `)
    .replace(/^####\s+(.*)$/gm, (match, title) => `
      <!-- wp:heading {"level":4} -->
      <h4>${title.trim()}</h4>
      <!-- /wp:heading -->
    `);

    // if (includeAffiCarousel && productAsins) {
    //   const introEndIndex = formattedContent.indexOf('<!-- wp:heading -->');
    //   if (introEndIndex !== -1) {
    //     const carouselShortcode = `\n[affiai asin="${productAsins}" template="carousel" ]\n`;
    //     formattedContent = (
    //       formattedContent.substring(0, introEndIndex) +
    //       carouselShortcode +
    //       formattedContent.substring(introEndIndex)
    //     );
    //   } else {
    //     // If no heading found, append after first two paragraphs (assumed intro)
    //     const paragraphs = formattedContent.split('\n\n');
    //     if (paragraphs.length >= 2) {
    //       formattedContent = (
    //         paragraphs.slice(0, 2).join('\n\n') + '\n\n' +
    //         `[affiai asin="${productAsins}" template="carousel" rank="on"]\n\n` +
    //         paragraphs.slice(2).join('\n\n')
    //       );
    //     }
    //   }
    // }

    if (includeAffiCarousel && productAsins) {
      const introEndIndex = formattedContent.indexOf('<!-- wp:heading -->');
      if (introEndIndex !== -1) {
        const carouselSection = `
    <!-- wp:heading -->
    <h2>Our Top Picks</h2>
    <!-- /wp:heading -->
    
    [affiai asin="${productAsins}" template="carousel" rank="on"]
    
    `;
        formattedContent = (
          formattedContent.substring(0, introEndIndex) +
          carouselSection +
          formattedContent.substring(introEndIndex)
        );
      } else {
        // If no heading found, append after first two paragraphs (assumed intro)
        const paragraphs = formattedContent.split('\n\n');
        if (paragraphs.length >= 2) {
          formattedContent = (
            paragraphs.slice(0, 2).join('\n\n') + '\n\n' +
            `<!-- wp:heading -->
    <h2>Our Top Picks</h2>
    <!-- /wp:heading -->
    
    [affiai asin="${productAsins}" template="carousel" rank="on"]
    
    ` +
            paragraphs.slice(2).join('\n\n')
          );
        }
      }
    }

  // Extract and process buying guide
  const buyingGuideRegex = /(?:## Buying Guide\n?|\# Buying Guide\n?)([\s\S]*?)(?=(?:\n#|\n##|$))/;
  let buyingGuideContent = '';
  const buyingGuideMatch = formattedContent.match(buyingGuideRegex);
  
  if (buyingGuideMatch && includeBuyingGuide) {
    buyingGuideContent = generateBuyingGuideHtml(buyingGuideMatch[1].trim());
    formattedContent = formattedContent.replace(buyingGuideRegex, '');
  }

  // Add disclaimer
  if (includeDisclaimer && disclaimerText) {
    const disclaimer = generateDisclaimerHtml();
    if (disclaimerPosition === 'top') {
      formattedContent = `${disclaimer}\n${formattedContent}`;
    } else if (disclaimerPosition === 'bottom') {
      formattedContent = `${formattedContent}\n${disclaimer}`;
    } else if (disclaimerPosition === 'after-intro') {
      formattedContent = formattedContent.replace(
        /(<!-- wp:heading -->)/i,
        `${disclaimer}\n$1`
      );
    }
  }

  // Add buying guide
  if (includeBuyingGuide && buyingGuideContent) {
    if (buyingGuidePosition === 'top') {
      formattedContent = `${buyingGuideContent}\n${formattedContent}`;
    } else {
      formattedContent = `${formattedContent}\n${buyingGuideContent}`;
    }
  }

  // Format remaining paragraphs
  formattedContent = formatParagraphs(formattedContent);

  return formattedContent.trim();
};