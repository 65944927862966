import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';

const ColorPickerDialog = ({ open, onClose, color, onColorChange, onConfirm }) => {
  const [currentColor, setCurrentColor] = useState(color);
  const [hexInput, setHexInput] = useState(color);

  const handleColorChange = (newColor) => {
    setCurrentColor(newColor);
    setHexInput(newColor);
  };

  const handleHexInput = (event) => {
    const input = event.target.value;
    setHexInput(input);
    
    // Validate hex color format
    if (/^#[0-9A-Fa-f]{6}$/.test(input)) {
      setCurrentColor(input);
    }
  };

  const handleConfirm = () => {
    onConfirm(currentColor);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Choose Button Color</DialogTitle>
      <DialogContent>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2,
          alignItems: 'center',
          py: 2
        }}>
          <HexColorPicker 
            color={currentColor} 
            onChange={handleColorChange}
          />
          
          <TextField
            fullWidth
            label="Hex Color"
            value={hexInput}
            onChange={handleHexInput}
            placeholder="#000000"
            error={!/^#[0-9A-Fa-f]{6}$/.test(hexInput)}
            helperText={!/^#[0-9A-Fa-f]{6}$/.test(hexInput) ? "Enter a valid hex color (e.g., #FF9900)" : null}
            sx={{ mt: 2 }}
          />
          
          <Box sx={{
            width: '100%',
            height: '40px',
            bgcolor: currentColor,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            mt: 1
          }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleConfirm}
          variant="contained"
          disabled={!/^#[0-9A-Fa-f]{6}$/.test(hexInput)}
        >
          Confirm Color
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPickerDialog;