import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography, Tooltip, IconButton } from '@mui/material';
import { HelpCircle } from 'lucide-react';
import { useAIModels } from '../hooks/useAIModels';
import { useAuth } from '../context/AuthContext';

const formatCost = (model) => {
  if (!model.prompt_tokens_per_usd) return 'Free';
  
  // Calculate cost for 1000 words (approximately 1500 output tokens and 100 input tokens)
  const inputCostPer1000Words = (100 / model.prompt_tokens_per_usd).toFixed(4);
  const outputCostPer1000Words = (1500 / model.completion_tokens_per_usd).toFixed(4);
  const totalCost = (parseFloat(inputCostPer1000Words) + parseFloat(outputCostPer1000Words)).toFixed(4);
  
  return `~${totalCost} per 1000 words`;
};

const ModelSelector = ({
  icon,
  label = 'AI MODEL',
  tooltip,
  value,
  onChange,
  required = false,
  size = 'small',
  variant = 'default', // 'default' or 'sidebar'
  disabled = false,
  showDescription = true,
  className
}) => {
  const { authenticatedRequest } = useAuth();
  const { models: availableModels, loading, error } = useAIModels(authenticatedRequest);

  if (loading || availableModels.length === 0 || error) return null;

  const renderModelOption = (model) => (
    <MenuItem 
      key={model.value} 
      value={model.value}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start',
        py: 1
      }}
    >
      <Typography>{model.label}</Typography>
      {showDescription && (
        <Box sx={{ mt: 0.5 }}>
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ display: 'block', textTransform: 'capitalize' }}
          >
            Provider: {model.provider}
          </Typography>
         
        </Box>
      )}
    </MenuItem>
  );

  if (variant === 'sidebar') {
    return (
      <Box sx={{ 
        px: 3,
        py: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        mb: 2
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1, 
          mb: 1 
        }}>
          {icon && React.cloneElement(icon, { 
            size: 18, 
            color: "#666666" 
          })}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography
              variant="overline"
              sx={{
                color: '#666666',
                fontSize: '0.75rem',
                fontWeight: 500,
                letterSpacing: '0.02em'
              }}
            >
              {label}
            </Typography>
            {tooltip && (
              <Tooltip title={tooltip} placement="right" arrow>
                <IconButton 
                  size="small" 
                  sx={{ 
                    p: 0.25,
                    '&:hover': { bgcolor: 'transparent' }
                  }}
                >
                  <HelpCircle size={14} color="#666666" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <FormControl 
          fullWidth
          size={size}
          required={required}
          disabled={disabled}
        >
          <Select
            value={value}
            onChange={onChange}
            sx={{
              backgroundColor: 'background.paper',
              '& .MuiSelect-select': { py: 1 }
            }}
          >
            {availableModels.map(renderModelOption)}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box className={className} sx={{ width: '100%' }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'start', 
        gap: 1, 
        width: '100%' 
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <FormControl 
            fullWidth
            required={required}
            disabled={disabled}
          >
            <InputLabel>AI Model</InputLabel>
            <Select
              value={value}
              label="AI Model"
              onChange={onChange}
              sx={{
                backgroundColor: 'background.paper',
                '& .MuiSelect-select': { py: 1.5 }
              }}
            >
              {availableModels.map(renderModelOption)}
            </Select>
          </FormControl>
        </Box>
        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <IconButton size="small" sx={{ mt: 1 }}>
              <HelpCircle size={18} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default ModelSelector;