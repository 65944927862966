import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Typography,
  Container,
  alpha,
} from '@mui/material';
import {
  PenSquare,
  Blocks,
  ImageIcon,
  ChevronRight,
  LayoutTemplate,
  Sparkles,
} from 'lucide-react';
import PageHeader from './PageHeader';

const PromptBuilderLanding = () => {
  const navigate = useNavigate();

  const cards = [
    {
      title: "Custom Editor",
      description: "Start with a blank canvas and write from scratch or paste in a prompt you want to edit and use",
      icon: <PenSquare size={40} strokeWidth={1} />,
      action: () => navigate('/prompts/editor'),
      gradient: 'from-orange-500 to-red-500',
      color: '#ff5733'
    },
    {
      title: "Block Builder",
      description: "Build prompts visually using our intuitive drag-and-drop interface. Mix and match pre-made blocks for tone, style, and structure while maintaining consistent quality.",
      icon: <Blocks size={40} strokeWidth={1} />,
      action: () => navigate('/prompts/builder'),
      gradient: 'from-blue-500 to-purple-500',
      color: '#4158D0'
    },
    {
      title: "Template Library",
      description: "Jump-start your prompt creation by choosing a prompt template, then customize to match your specific needs.",
      icon: <LayoutTemplate size={40} strokeWidth={1} />,
      action: () => navigate('/prompts/templates'),
      gradient: 'from-green-500 to-teal-500',
      color: '#43A047'
    },
    {
      title: "Image Prompts",
      description: "",
      icon: <ImageIcon size={40} strokeWidth={1} />,
      comingSoon: true,
      gradient: 'from-pink-500 to-rose-500',
      color: '#E91E63'
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ background: 'linear-gradient(180deg, #F9FAFB 0%, #F3F4F6 100%)', minHeight: '100vh', py: 4 }}>
      <Box sx={{ mt: 4, mb: 6 }}>
        <PageHeader
          category="Prompt Lab"
          title="Create New Prompt"
          icon={
            <Box sx={{
              width: 36,
              height: 36,
              borderRadius: '50%',
              background: 'linear-gradient(135deg, #E04C16 0%, #8B5CF6 50%, #3B82F6 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              animation: 'pulse-sparkle-bg 3s infinite ease-in-out',
            }}>
              <Sparkles size={24} color="#FFFFFF" strokeWidth={1} className="animate-pulse-sparkle" />
            </Box>
          }
          description="Choose your preferred method to create a new prompt"
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Poppins, Inter, sans-serif',
            },
            '& .MuiTypography-h1': { fontWeight: 700, color: '#1F2937' },
            '& .MuiTypography-body1': { fontWeight: 500, color: '#6B7280' },
          }}
        />

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' },
          gap: 3,
          mt: 6
        }}>
          {cards.map((card, index) => (
            <Card
              key={index}
              onClick={!card.comingSoon ? card.action : undefined}
              sx={{
                position: 'relative',
                aspectRatio: { xs: '3/4', sm: '9/16' },
                cursor: card.comingSoon ? 'default' : 'pointer',
                opacity: card.comingSoon ? 0.7 : 1,
                background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7))',
                backdropFilter: 'blur(8px)',
                borderRadius: '16px 16px 8px 8px',
                border: `1px solid ${alpha(card.color, 0.5)}`, // Thinner border
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)', // Subtle gray shadow like sidebar
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: card.comingSoon ? 'none' : 'perspective(1000px) rotateX(2deg) rotateY(2deg) scale(1.05)',
                  boxShadow: card.comingSoon ? '0 2px 6px rgba(0, 0, 0, 0.05)' : `0 4px 12px ${alpha(card.color, 0.2)}`, // Lighter glow
                  borderColor: card.color,
                  '& .icon-box': {
                    transform: 'scale(1.1) rotate(5deg)',
                    boxShadow: `0 0 10px ${alpha(card.color, 0.3)}`, // Subtle icon glow
                  },
                  '& .hover-arrow': {
                    transform: 'translateX(8px)',
                  },
                  '& .ripple': {
                    transform: 'scale(2)',
                    opacity: 0,
                  }
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '40%',
                  background: '#FFFFFF',
                  borderBottom: `1px solid ${card.color}`, // Thinner neon line
                  clipPath: 'polygon(0 0, 100% 0, 100% 70%, 0 100%)',
                }
              }}
            >
              {/* Ripple Effect */}
              {!card.comingSoon && (
                <Box
                  className="ripple"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '100px',
                    height: '100px',
                    background: alpha(card.color, 0.2),
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%) scale(0)',
                    transition: 'transform 0.6s ease-out, opacity 0.6s ease-out',
                    pointerEvents: 'none',
                  }}
                />
              )}

              {/* Icon and Title Section */}
              <Box sx={{ 
                position: 'relative',
                p: 3,
                color: card.color,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                mb: 2,
                zIndex: 1,
              }}>
                <Box 
                  className="icon-box"
                  sx={{ 
                    backgroundColor: alpha(card.color, 0.1),
                    borderRadius: '50%',
                    p: 2,
                    color: card.color,
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  {React.cloneElement(card.icon, { color: card.color })}
                </Box>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    fontWeight: 600, // Lighter than 700
                    fontFamily: 'Poppins, Inter, sans-serif',
                    letterSpacing: '0.01em',
                  }}
                >
                  {card.title}
                </Typography>
              </Box>

              {/* Description Section */}
              <Box sx={{ 
                p: 3,
                pt: 0,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                zIndex: 1,
              }}>
                <Typography sx={{ 
                  color: '#4B5563',
                  fontWeight: 400,
                  lineHeight: 1.6,
                  fontSize: { xs: '1.0rem', sm: '0.95rem', md: '1.1rem' },
                  fontFamily: 'Poppins, Inter, sans-serif',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 4, sm: 6 },
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis'
                }}>
                  {card.description}
                </Typography>
                
                {!card.comingSoon && (
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end',
                    mt: 2,
                    color: card.color
                  }}>
                    <ChevronRight 
                      size={24} 
                      strokeWidth={1}
                      className="hover-arrow" 
                      style={{ transition: 'transform 0.2s ease' }}
                    />
                  </Box>
                )}
                {card.comingSoon && (
                  <Box sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    backgroundColor: alpha(card.color, 0.8),
                    color: 'white',
                    fontSize: '0.8rem',
                    fontWeight: 600,
                    fontFamily: 'Poppins, Inter, sans-serif',
                    padding: '4px 10px',
                    borderRadius: '12px',
                    boxShadow: `0 0 10px ${alpha(card.color, 0.3)}`, // Lighter shadow
                    animation: 'pulse 2s infinite ease-in-out',
                  }}>
                    Coming Soon
                  </Box>
                )}
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default PromptBuilderLanding;