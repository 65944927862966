import React from 'react';
import { FileText, FileCode, Download } from 'lucide-react';
import { IconButton, Tooltip, Menu, MenuItem, Typography } from '@mui/material';

const DownloadHandler = ({ content, processedContent, title, images, onDownload }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadFile = (format) => {
    console.log('DownloadHandler received:', { content, processedContent, title, images });
  
    if (!content && !processedContent) {
      console.error('No content available for download');
      return;
    }
  
    let fileContent;
    let fileName = `${title.replace(/[^a-z0-9]/gi, '-').toLowerCase()}`;
    let mimeType;
    
    if (format === 'html') {
      // Add image information to the HTML content
      let processedHtml = processedContent;
      
      // If we have separate image data, ensure it's included in the HTML
      if (images && images.length > 0) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(processedHtml, 'text/html');
        
        // Add featured image if it exists
        const featuredImage = images.find(img => img.type === 'featured');
        if (featuredImage) {
          const featuredHtml = `
            <figure class="featured-image">
              <img src="${featuredImage.url}" alt="${featuredImage.alt || title}" />
              <figcaption>${featuredImage.title || title}</figcaption>
            </figure>`;
          doc.body.insertBefore(
            parser.parseFromString(featuredHtml, 'text/html').body.firstChild,
            doc.body.firstChild
          );
        }
        
        processedHtml = doc.documentElement.outerHTML;
      }

      fileContent = `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>${title}</title>
  <style>
    body { 
      font-family: system-ui, sans-serif; 
      line-height: 1.6; 
      max-width: 800px; 
      margin: 0 auto; 
      padding: 20px;
      color: #333;
    }
    img { 
      max-width: 100%; 
      height: auto; 
      margin: 20px 0;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
    h1 { 
      font-size: 2.5em;
      margin-bottom: 1em;
      color: #222;
    }
    h2, h3 { 
      margin-top: 2em;
      color: #333;
    }
    figure {
      margin: 2em 0;
      text-align: center;
    }
    figcaption {
      color: #666;
      font-size: 0.9em;
      margin-top: 8px;
    }
    .featured-image {
      margin-bottom: 2em;
    }
    .section-image {
      margin: 1em 0 2em 0;
    }
    .image-url {
      display: block;
      color: #666;
      font-size: 0.8em;
      margin-top: 4px;
      font-family: monospace;
    }
    @media print {
      body { max-width: none; }
      img { 
        max-width: 500px;
        margin: 20px auto;
        display: block;
      }
      .image-url {
        page-break-before: avoid;
        page-break-inside: avoid;
      }
    }
  </style>
</head>
<body>
  <h1>${title}</h1>
  ${processedHtml}
</body>
</html>`;
      fileName += '.html';
      mimeType = 'text/html';
    } else {
      // Text format
      const textContent = processedContent || content;
      if (!textContent) {
        console.error('No content available for text conversion');
        return;
      }
    
      // Create a temporary DOM element to parse the HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(textContent, 'text/html');
      
      // Extract and format content with image URLs
      let formattedContent = '';
      
      // Add title
      formattedContent += title + '\n' + '='.repeat(title.length) + '\n\n';
      
      // Add featured image if available
      if (images && images.length > 0) {
        const featuredImage = images.find(img => img.type === 'featured');
        if (featuredImage) {
          formattedContent += '[Featured Image]\n';
          formattedContent += `URL: ${featuredImage.url}\n`;
          formattedContent += `Title: ${featuredImage.title || title}\n\n`;
        }
      }
      
      // Process each element
      const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trim();
          if (text) {
            formattedContent += text + '\n';
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          switch (node.tagName.toLowerCase()) {
            case 'h1':
              formattedContent += '\n' + node.textContent + '\n' + '='.repeat(node.textContent.length) + '\n';
              break;
            case 'h2':
              formattedContent += '\n' + node.textContent + '\n' + '-'.repeat(node.textContent.length) + '\n';
              break;
            case 'h3':
              formattedContent += '\n' + node.textContent + '\n' + '~'.repeat(node.textContent.length) + '\n';
              break;
            case 'figure':
              const img = node.querySelector('img');
              const caption = node.querySelector('figcaption');
              if (img) {
                formattedContent += '\n[Image]\n';
                formattedContent += `URL: ${img.src}\n`;
                if (caption) {
                  formattedContent += `Caption: ${caption.textContent}\n`;
                }
                formattedContent += '\n';
              }
              break;
            case 'img':
              formattedContent += '\n[Image]\n';
              formattedContent += `URL: ${node.src}\n\n`;
              break;
            case 'p':
              Array.from(node.childNodes).forEach(child => {
                if (child.nodeType === Node.TEXT_NODE) {
                  const text = child.textContent.trim();
                  if (text) {
                    formattedContent += text + ' ';
                  }
                }
              });
              formattedContent += '\n\n';
              break;
            case 'ul':
            case 'ol':
              formattedContent += '\n';
              node.querySelectorAll('li').forEach(li => {
                formattedContent += '• ' + li.textContent.trim() + '\n';
              });
              formattedContent += '\n';
              break;
            default:
              Array.from(node.childNodes).forEach(processNode);
          }
        }
      };
    
      // Process all nodes in the document body
      Array.from(doc.body.childNodes).forEach(processNode);
    
      // Clean up the formatted content
      fileContent = formattedContent
        .replace(/\n{3,}/g, '\n\n')
        .replace(/ +/g, ' ')
        .trim();
      
      fileName += '.txt';
      mimeType = 'text/plain';
    }
    
    const blob = new Blob([fileContent], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    handleClose();
    
    if (onDownload) {
      onDownload(format);
    }
  };

  return (
    <>
      <Tooltip title="Download">
        <IconButton onClick={handleClick} size="small">
          <Download className="h-5 w-5" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => downloadFile('text')} sx={{ gap: 1 }}>
          <FileText size={18} />
          <Typography>Download as Text</Typography>
        </MenuItem>
        <MenuItem onClick={() => downloadFile('html')} sx={{ gap: 1 }}>
          <FileCode size={18} />
          <Typography>Download as HTML</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DownloadHandler;