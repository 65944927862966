import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { config } from '../config';

const Callback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [checkingSubscription, setCheckingSubscription] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    
    if (error) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/');
      return;
    }

    const checkSubscription = async () => {
      if (isLoading || !isAuthenticated) return;

      try {
        setCheckingSubscription(true);
        const token = await getAccessTokenSilently();
        
        const response = await axios.get(
          `${config.api.baseUrl}/api/stripe/subscription`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subscription = response.data?.subscription;
        const hasActiveSubscription = subscription?.status === 'active' && 
                                    !subscription?.cancel_at_period_end;

        if (hasActiveSubscription) {
          
          navigate('/', { replace: true });
        } else {
         
          navigate('/subscription', { 
            replace: true,
            state: { newSignup: true }
          });
        }
      } catch (err) {
        console.error('Error checking subscription:', err);
        navigate('/subscription', { 
          replace: true,
          state: { error: true }
        });
      } finally {
        setCheckingSubscription(false);
      }
    };

    checkSubscription();
  }, [location, isAuthenticated, isLoading, getAccessTokenSilently, navigate]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      gap: 2
    }}>
      <CircularProgress />
      {checkingSubscription && (
        <Typography variant="body2" color="text.secondary">
          Verifying subscription...
        </Typography>
      )}
    </Box>
  );
};

export default Callback;