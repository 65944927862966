import React, { useState, useEffect } from 'react';
import { config } from '../../../config';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Alert,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  ListSubheader,
  Dialog, DialogTitle, DialogContent, DialogActions, Divider,
} from '@mui/material';
import { FileText, Eye, HelpCircle, X } from 'lucide-react';
import PageHeader from '../../PageHeader';
import { useAuth } from '../../../context/AuthContext';
import apiClient from '../../../utils/apiUtils';
import ModelSelector from '../../ModelSelector';
import { useAIModels } from '../../../hooks/useAIModels';
import aiClient from '../../../api/clients/AiClient';
import imageClient from '../../../api/clients/ImageClient';
import WordPressClient from '../../../utils/WordPressClient';
import { formatProductReview } from '../../../utils/formatProductReviews';
import ColorPickerDialog from '../../../utils/ColorPickerDialog';
import ImageControls from '../../ImageControls';


export default function ProductReviewsPage({onGeneratingChange}) {
  // Auth and API hooks
  const { authenticatedRequest, error: authError } = useAuth();
  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);

  // Dialog and navigation state
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [navDialogOpen, setNavDialogOpen] = useState(false);
  const [navigationTarget, setNavigationTarget] = useState(null);

  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [generationStatus, setGenerationStatus] = useState('');
  const [generationSteps, setGenerationSteps] = useState([]);

  // WordPress site settings
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  // Content settings
  const [title, setTitle] = useState('');
  const [model, setModel] = useState('');
  const [prompts, setPrompts] = useState({ systemTemplates: [], userPrompts: [] });
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState(null);

  // Product search and selection
  const [searchMode, setSearchMode] = useState('asin');
  const [searchQuery, setSearchQuery] = useState('');
  const [productCount, setProductCount] = useState(5);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('us-west-1');
  const [productAsins, setProductAsins] = useState('');
  const [validatedProducts, setValidatedProducts] = useState([]);

  // Product display settings
  const [includeCarousel, setIncludeCarousel] = useState(true);
  const [includeProductBoxes, setIncludeProductBoxes] = useState(true);
  const [includeProsConsSection, setIncludeProsConsSection] = useState(true);
  const [includeBuyingGuide, setIncludeBuyingGuide] = useState(false);
  const [buyingGuidePosition, setBuyingGuidePosition] = useState('bottom');
  const [linkHeadings, setLinkHeadings] = useState(true);

  // Disclaimer settings
  const [includeDisclaimer, setIncludeDisclaimer] = useState(true);
  const [disclaimerText, setDisclaimerText] = useState("As an Amazon Associate I earn from qualifying purchases.");
  const [disclaimerPosition, setDisclaimerPosition] = useState('top');

  // Button settings
  const [includeCheckButton, setIncludeCheckButton] = useState(true);
  const [buttonColor, setButtonColor] = useState('#FF9900');
  const [showColorPicker, setShowColorPicker] = useState(false);

  // Image settings
  const [productImageType, setProductImageType] = useState('none');
  const [availableImageModels, setAvailableImageModels] = useState([]);
  const [includeFeaturedImage, setIncludeFeaturedImage] = useState(false);
  const [featuredImageModel, setFeaturedImageModel] = useState('fal-ai/flux/schnell');
  const [featuredImageStyle, setFeaturedImageStyle] = useState('realistic_image');
  const [featuredDimension, setFeaturedDimension] = useState('square');
  const [featuredImagePrompt, setFeaturedImagePrompt] = useState('');
  const [useAIEnhancement, setUseAIEnhancement] = useState(false);

  // AFFI widget settings
  const [useAffiShortcodes, setUseAffiShortcodes] = useState(true);
  const [customAffiShortcode, setCustomAffiShortcode] = useState('');
  const [affiShortcodePlacement, setAffiShortcodePlacement] = useState('after-title');
  const [includeAffiCarousel, setIncludeAffiCarousel] = useState(false);
  
 
  const AMAZON_MARKETPLACES = [
    { 
      value: 'com', 
      label: 'Amazon.com (US)', 
      domain: 'https://amazon.com',
      region: 'us-west-1'
    },
    { 
      value: 'co.uk', 
      label: 'Amazon.co.uk (UK)', 
      domain: 'https://amazon.co.uk',
      region: 'eu-west-1'
    },
    { 
      value: 'ca', 
      label: 'Amazon.ca (Canada)', 
      domain: 'https://amazon.ca',
      region: 'ca-central-1'
    },
    { 
      value: 'de', 
      label: 'Amazon.de (Germany)', 
      domain: 'https://amazon.de',
      region: 'eu-central-1'
    }
  ];


  const [amazonMarketplace, setAmazonMarketplace] = useState(AMAZON_MARKETPLACES[0]);
  const [amazonDomain, setAmazonDomain] = useState('com');
  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Computed values
  const hasProducts = searchMode === 'search' 
    ? selectedProducts.length > 0 
    : productAsins.trim().length > 0;

  // Constants
  const DEFAULT_AFFI_SHORTCODE = '[affiai asin="{asin}" template="box"]';

 
useEffect(() => {
  const handleBeforeUnload = (e) => {
    if (isGenerating) {
      e.preventDefault();
      setNavDialogOpen(true);
      e.returnValue = '';
      return '';
    }
  };

  const handleUnload = (e) => {
    if (isGenerating) {
      e.preventDefault();
      setNavDialogOpen(true);
      return false;
    }
  };

  const handlePopState = (event) => {
    if (isGenerating) {
      event.preventDefault();
      window.history.pushState(null, '', window.location.pathname);
      setNavDialogOpen(true);
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);
  window.addEventListener('unload', handleUnload);
  window.addEventListener('popstate', handlePopState);
  
  if (isGenerating) {
    window.history.pushState(null, '', window.location.pathname);
  }

  onGeneratingChange?.(isGenerating);
  
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('unload', handleUnload);
    window.removeEventListener('popstate', handlePopState);
    onGeneratingChange?.(false);
  };
}, [isGenerating, onGeneratingChange]);

useEffect(() => {
  const fetchInitialData = async () => {
    try {
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        
        let sitesResponse;
        let imageModelsResponse;
        let promptsResponse;

        try {
          [sitesResponse, imageModelsResponse, promptsResponse] = await Promise.all([
            apiClient.getWordPressSites(),
            apiClient.getAvailableImageModels(),
            apiClient.getSavedPrompts()
          ]);

          setSites(sitesResponse);
          if (sitesResponse.length > 0) {
            setSelectedSite(sitesResponse[0].id);
          }

          setAvailableImageModels(imageModelsResponse);

          if (promptsResponse) {
            // First, gather all product review templates from both system and user prompts
            const systemProductReviews = (promptsResponse.system_templates || []).filter(template => 
              template.type === 'product_review' || 
              template.settings?.type === 'product_review' ||
              template.title?.toLowerCase().includes('product review')
            );

            const userProductReviews = (promptsResponse.user_prompts || []).filter(template =>
              template.type === 'product_review' || 
              template.settings?.type === 'product_review' ||
              template.title?.toLowerCase().includes('product review')
            );

            // Combine both arrays
            const allProductReviews = [...systemProductReviews, ...userProductReviews];

            setPrompts({
              systemTemplates: promptsResponse.system_templates || [],
              userPrompts: promptsResponse.user_prompts || [],
              productReviewTemplates: allProductReviews // Add new property for filtered templates
            });
            
            // Set the first product review template as selected if any exist
            if (allProductReviews.length > 0) {
              setSelectedPrompt(allProductReviews[0].id);
            }
          }

        } catch (error) {
          if (!sitesResponse) console.error('Failed to fetch WordPress sites');
          if (!imageModelsResponse) console.error('Failed to fetch image models');
          if (!promptsResponse) console.error('Failed to fetch prompts');
          throw error;
        }
      });
    } catch (err) {
      setError('Failed to load initial data');
      console.error('Error fetching initial data:', err);
    }
  };

  fetchInitialData();
}, []);

  useEffect(() => {
    console.log('Model initialization check:', {
      availableModels: availableModels.length,
      currentModel: model
    });

    if (availableModels.length > 0 && !model) {
      setModel(availableModels[0].value);
    }
  }, [availableModels, model]);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories();
    }
  }, [selectedSite]);

// Add this temporarily to debug
useEffect(() => {
  console.log('Prompts data:', {
    allPrompts: prompts,
    systemTemplates: prompts.systemTemplates,
    productReviewTemplates: prompts.systemTemplates?.filter(t => t.type === 'product_review')
  });
}, [prompts]);
 
useEffect(() => {
  if (availableModels.length > 0) {
    const initializeAI = async () => {
      try {
        const keys = await authenticatedRequest(() => apiClient.getApiKeys());
        
        // Initialize AI client
        aiClient.setApiKeys(keys);
        aiClient.updateModelConfigs(availableModels); 

        // Initialize image client
        imageClient.setAvailableModels(availableModels);
        imageClient.setAiClient(aiClient);
        imageClient.setApiKeys(keys);
        
        // Set initial model
        if (!model && availableModels.length > 0) {
          const defaultModel = availableModels[0].value;
          setModel(defaultModel);
        }
      } catch (error) {
        console.error('Error initializing AI services:', error);
        setError('Failed to initialize AI services');
      }
    };

    initializeAI();
  }
}, [availableModels, authenticatedRequest, model]);

const LeaveConfirmationDialog = ({ open, onClose, onConfirm }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Leave Page?</DialogTitle>
    <DialogContent>
      <Typography>
        Review is being generated. If you leave now, all progress will be lost.
      </Typography>
      <Typography color="error" sx={{ mt: 2 }}>
        Are you sure you want to leave this page?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Stay on Page
      </Button>
      <Button onClick={onConfirm} color="error" variant="contained">
        Leave Page
      </Button>
    </DialogActions>
  </Dialog>
);

const NavigationDialog = () => (
  <Dialog open={navDialogOpen} onClose={() => setNavDialogOpen(false)}>
    <DialogTitle>Leave Page?</DialogTitle>
    <DialogContent>
      <Typography>
        Review is being generated. If you leave now, all progress will be lost.
      </Typography>
      <Typography color="error" sx={{ mt: 2 }}>
        Are you sure you want to leave this page?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setNavDialogOpen(false)}>
        Stay on Page
      </Button>
      <Button
        onClick={() => {
          setIsGenerating(false);
          setNavDialogOpen(false);
          window.history.back();
        }}
        color="error"
        variant="contained"
      >
        Leave Page
      </Button>
    </DialogActions>
  </Dialog>
);


  const handlePreviewPrompt = (promptId) => {
    const prompt = [
      ...prompts.systemTemplates,
      ...prompts.userPrompts
    ].find(p => p.id === promptId);
    
    if (prompt) {
      setPreviewPrompt(prompt);
      setPreviewOpen(true);
    }
  };
  

  const fetchCategories = async () => {
    try {
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        const categoriesData = await apiClient.getCategories(selectedSite);
        setCategories(categoriesData);
      });
    } catch (err) {
      setError('Failed to load categories');
      console.error('Error fetching categories:', err);
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);
    const marketplace = AMAZON_MARKETPLACES.find(m => m.value === amazonDomain);

   try {
       await authenticatedRequest(async (token) => {
         apiClient.setAuthToken(token);
         const response = await apiClient.searchProducts({
           query: searchQuery,
           maxResults: productCount,
           region: marketplace.region
         });
        
        setSearchResults(response.products);
        if (response.products.length > 0) {
          setSelectedProducts(response.products);
          setProductAsins(response.products.map(p => p.asin).join('\n'));
        }
      });
    } catch (err) {
      setError(err.message || 'Failed to search products');
    } finally {
      setIsLoading(false);
    }
  };

  const generateProductContent = async (productData, prompt, title) => {
    if (!prompt) throw new Error('Selected prompt template not found');
    if (!model) throw new Error('No AI model selected');
  
    const buyingGuidePrompt = includeBuyingGuide ? `
  Please include a buying guide section with the heading "## Buying Guide". Write it as a friendly, conversational 3-paragraph guide that feels like getting advice from a knowledgeable salesperson. Focus on helping the reader understand what makes a good ${title.toLowerCase()}, what to look out for, and how to make the best choice for their needs. Avoid using subheadings or bullet points - keep it flowing naturally as a conversation. Place it at the ${buyingGuidePosition} of the article.
  ` : '';
  
    const structuredPrompt = `${prompt.prompt_text}
  
  Article Title: ${title}
  
  ${buyingGuidePrompt}
  
  Products to Review:
  ${productData.map((product, index) => `
  ${index + 1}. ${product.title}
  ASIN: ${product.asin}
  Features: ${product.features?.join(', ') || 'Not available'}
  Rating: ${product.rating || 'Not available'}
  Description: ${product.description || 'Not available'}
  Price: ${product.price || 'Not available'}
  `).join('\n')}
  
  Additional Requirements:
  - Present products in a clear, organized manner
  - Include specific features and benefits for each product
  - Compare products where relevant
  - Use proper heading hierarchy (h2 for main sections)
  - Make content engaging and informative`;

  console.log('Generated Prompt:', structuredPrompt);
  
    return await aiClient.generateArticleContent({
      title,
      modelId: model,
      customPrompt: structuredPrompt
    });
  };

  const validateAndGatherProductData = async (asins) => {
    try {
      const response = await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        return await apiClient.validateAndGatherProducts({ asins });
      });
      return response.products;
    } catch (error) {
      throw new Error(error.message || 'Failed to gather product data');
    }
  };

  const updateStepStatus = (stepId, status, error = null) => {
    setGenerationSteps(prev => prev.map(step => 
      step.id === stepId ? { ...step, status, error } : step
    ));
  };



const handleGenerateReview = async (e) => {
  e.preventDefault();
  setError(null);
  setIsLoading(true);
  setIsGenerating(true); 
  setGenerationStatus('Starting review generation...');

  try {
    const asins = searchMode === 'search' 
      ? selectedProducts.map(p => p.asin)
      : productAsins.split('\n').map(asin => asin.trim()).filter(asin => asin);

    if (asins.length === 0) {
      throw new Error('Please enter at least one product ASIN');
    }

    if (asins.length > 10) {
      throw new Error('Maximum 10 products allowed');
    }

    // Get product data
    setGenerationStatus('Gathering product data...');
    const productData = await validateAndGatherProductData(asins);

    // Find selected prompt template
    const prompt = [...prompts.systemTemplates, ...prompts.userPrompts]
      .find(p => p.id === selectedPrompt);
    
    if (!prompt) {
      throw new Error('Please select a writing style template');
    }

    // Generate content
    setGenerationStatus('Generating article content...');
    const content = await generateProductContent(productData, prompt, title);
    let processedContent = formatProductReview(content, title, {
      includeDisclaimer,
      disclaimerText,
      disclaimerPosition,
      includeCheckButton,
      useAffiShortcodes,
      affiShortcodeTemplate: customAffiShortcode || DEFAULT_AFFI_SHORTCODE,
      affiShortcodePlacement,
      tracking_id: productData[0]?.tracking_id,
      buttonColor: buttonColor,  
      includeProsConsSection,
      includeBuyingGuide,
      buyingGuidePosition,
      amazonDomain,
      includeAffiCarousel, 
      productAsins: asins.join(',')
    });
    console.log(includeFeaturedImage);

    // Handle featured image if enabled
    let featuredMediaId = null;
    if (includeFeaturedImage) {
      try {
        setGenerationStatus('Generating featured image...');
        const imageData = await imageClient.generateImage({
          prompt: featuredImagePrompt || title,
          model: featuredImageModel,
          dimension: featuredDimension,
          style: featuredImageStyle,
          enhancePrompt: useAIEnhancement,
          context: {
            articleTitle: title
          }
        });

        if (imageData?.image_url) {
          setGenerationStatus('Uploading featured image...');
          const response = await fetch(imageData.image_url);
          const blob = await response.blob();
          const formData = new FormData();
          formData.append('file', blob, `featured-${Date.now()}.png`);
          formData.append('siteId', selectedSite);

          const uploadResult = await authenticatedRequest(() => 
            apiClient.uploadMedia(formData)
          );
          featuredMediaId = uploadResult?.media_id;
        }
      } catch (error) {
        console.error('Featured image error:', error);
        setGenerationStatus('Featured image failed, continuing...');
      }
    }

    // Create WordPress post
    setGenerationStatus('Creating WordPress post...');
    const site = sites.find(s => s.id === selectedSite);
    if (!site) throw new Error('Selected site not found');

    const wpClient = new WordPressClient(site);
    const credentials = await authenticatedRequest(() => 
      apiClient.getWordPressCredentials(selectedSite)
    );
    wpClient.setCredentials(credentials.username, credentials.password);

    const post = await wpClient.createPost({
      title,
      content: processedContent,
      categoryId: selectedCategory,
      featuredMediaId,
      status: 'draft'
    });

    setNotification({
      open: true,
      message: 'Product review generated successfully!',
      severity: 'success'
    });

  } catch (error) {
    console.error('Error generating review:', error);
    setError(error.message || 'Failed to generate product review');
  } finally {
    setIsLoading(false);
    setIsGenerating(false);
    setGenerationStatus('');
  }
};
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };
  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    mb: 4 
  }}>
    <PageHeader 
      category="Content Creation"
      title="Create An Amazon Product Roundup Review" 
      description="Generate comprehensive product reviews by selecting products, customizing display settings, and adding optional features like featured images and product widgets. Choose your preferred writing style and let AI create engaging content."
      icon={<FileText size={24} color="#E04C16" />} 
    />
    <Button
      onClick={() => setHelpDialogOpen(true)}
      startIcon={<HelpCircle size={18} />}
      sx={{
        bgcolor: '#E04C16',
        color: 'white',
        borderRadius: '20px',
        px: 3,
        '&:hover': {
          bgcolor: '#c43d0f'
        }
      }}
    >
      How to Use
    </Button>
  </Box>
  {/* Help Dialog */}
  <Dialog 
    open={helpDialogOpen}
    onClose={() => setHelpDialogOpen(false)}
    PaperProps={{
      sx: {
        position: 'fixed',
        right: 32,
        top: '50%',
        transform: 'translateY(-50%)',
        m: 0,
        width: 400,
        maxWidth: '90vw',
        maxHeight: '80vh',
        borderRadius: 2
      }
    }}
  >
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      p: 2,
      borderBottom: '1px solid',
      borderColor: 'divider'
    }}>
      <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
        How to Use Product Review Generator
      </DialogTitle>
      <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
        <X size={18} />
      </IconButton>
    </Box>
    <DialogContent>
  {/* Getting Started */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Getting Started with Amazon Product Review Writer
  </Typography>
  <Typography variant="body2" sx={{ mb: 4, lineHeight: 1.6 }}>
    Welcome to the Amazon Product Review Writer! This tool creates detailed roundup reviews for Amazon products—perfect for affiliate blogs or content sites. To use this page, ensure your Amazon API details are saved in the app’s settings (under Settings - Amazon API). Enter a review title, select products by ASIN or search, customize settings, and generate a polished article saved as a WordPress draft—all in a few steps!
  </Typography>

  {/* Setting Up Your Review */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Setting Up Your Review
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Configure the essentials to start your review:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Enter a review title:</strong> Type a descriptive title in the "Review Title" field (e.g., "Top 5 Wireless Earbuds of 2023"). This guides the AI’s content generation.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Select a WordPress site and category:</strong> Pick your site from the "WordPress Site" dropdown and a category (e.g., "Tech Reviews") from the "Category" dropdown. Your review will save here as a draft.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Pick an AI model:</strong> Choose a model from the "AI MODEL" selector to generate text. Models vary in quality—test different ones to match your review style (e.g., detailed or concise).
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Choose a writing style template:</strong> From the "Writing Style Template" dropdown, select the default "Product Review" template or a custom template you’ve created with "Product Review" in its title (e.g., "My Product Review Format"). These must follow the same structure as the default Product Review template. Click the eye icon to preview it. The sidebar’s auxiliary AI model powers this process.
    </li>
  </Typography>

  {/* Selecting Products */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Selecting Products
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Add Amazon products to review using one of these methods:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Manual ASIN mode:</strong> In the "Product Selection" card, ensure the switch is set to "Manual ASIN Mode." Enter up to 10 Amazon ASINs (e.g., "B08J5K7L9M") in the text box, one per line, to specify exact products.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Search mode:</strong> Toggle the switch to "Search Mode." Enter a query (e.g., "best gaming headsets under $100") in the "Search Query" field, set the "Number of Products" (1-10), and click "Search." Pick products from the results table—titles and ASINs will auto-populate.
    </li>
  </Typography>

  {/* Customizing Your Review */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Customizing Your Review
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Personalize your review with these options:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Add a featured image:</strong> In the "Featured Image" card, toggle "Include Featured Image" on. Add a custom prompt (e.g., "Wireless earbuds on a sleek desk") or leave it blank for the AI to use the title. Expand the card to choose an image model—cheaper models are faster but lower quality, while premium ones offer better visuals. Check "Enhance Image Prompts with AI" to refine prompts using the sidebar’s auxiliary AI model.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Set up Amazon disclaimers:</strong> In the "Amazon Disclaimer Settings" card, check "Include Amazon Affiliate Disclaimer" and tweak the text (e.g., "As an Amazon Associate...") and position (top, bottom, or after intro).
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Configure links and buttons:</strong> In the "Link Settings" card:
      <Typography component="ul" variant="body2" sx={{ pl: 4, mt: 1, lineHeight: 1.6 }}>
        <li style={{ marginBottom: '8px' }}>
          Check "Include 'Check on Amazon' Button" and click "Choose Button Color" to customize (e.g., orange #FF9900).
        </li>
        <li style={{ marginBottom: '8px' }}>
          Toggle "Include Affi.ai Product Widgets" for affiliate shortcodes, optionally adding a custom template (e.g., "[affiai asin=\"asin\" template=\"box\"]") and placement (after title, description, or before pros/cons).
        </li>
        <li style={{ marginBottom: '8px' }}>
          Check "Include Affi.ai Carousel After Introduction" for a product carousel.
        </li>
      </Typography>
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Include a buying guide:</strong> In the "Buying Guide" card, check "Include Buying Guide Section" and set its position (before or after reviews) for a friendly guide on choosing products.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Add pros and cons:</strong> In the "Pros & Cons" card, check "Include Pros and Cons Sections" to highlight each product’s strengths and weaknesses.
    </li>
  </Typography>

  {/* Generating Your Review */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Generating Your Review
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Create and finalize your review:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Generate the review:</strong> Click "Generate Product Review" at the bottom of the form. Monitor the "Generation Status" panel on the right for updates like "Gathering product data" or "Creating WordPress post."
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Check the output:</strong> When finished, your review saves as a draft in your chosen WordPress site and category. Look for the notification ("Product review generated successfully!") to confirm.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Edit and publish:</strong> Visit your WordPress dashboard to review the draft, adjust content or images, and publish when ready.
    </li>
  </Typography>

  {/* Best Practices */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Best Practices for Great Reviews
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Boost your review’s effectiveness with these tips:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Use specific titles:</strong> Write SEO-friendly titles like "Best Budget Smartwatches of 2023" to draw readers and improve rankings.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Choose a strong auxiliary AI model:</strong> The sidebar’s auxiliary AI model powers writing style and image prompt enhancement—select a capable one for top-notch results.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Optimize image quality:</strong> For featured images, pick higher-quality models in the "Featured Image" settings for professional visuals; use cheaper ones for quick drafts.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Install the Affi.ai plugin:</strong> For up-to-date pricing and Amazon product images, we recommend installing the Affi.ai plugin on your WordPress site where reviews are published.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Balance product count:</strong> Include 3-10 products—enough variety without overloading readers or the AI.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Verify settings:</strong> Before generating, double-check your title, products, and customizations to ensure a cohesive review.
    </li>
  </Typography>
</DialogContent>
  </Dialog>
  
      <Grid container spacing={3}>
        {/* Left Column - Form Controls */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}
            
            <form onSubmit={handleGenerateReview}>
              <Grid container spacing={3}>
                {/* Title Input */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Review Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={isGenerating}
                    helperText="Enter a descriptive title for your product review"
                  />
                </Grid>
  
                {/* WordPress Site & Category Selection */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel>WordPress Site</InputLabel>
                    <Select
                      value={selectedSite}
                      onChange={(e) => setSelectedSite(e.target.value)}
                      disabled={isGenerating}

                    >
                      {sites.map(site => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
  
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      disabled={isGenerating}
                    >
                      {categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
  
                {/* AI Model Selection */}
                <Grid item xs={12}>
                  <ModelSelector
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    required
                    tooltip="Choose the AI model that will generate your content"
                    label="AI MODEL"
                    disabled={isGenerating || loading || modelsLoading}
                    showDescription={true}
                  />
                  {modelsError && (
                    <FormHelperText error>
                      {modelsError}
                    </FormHelperText>
                  )}
                </Grid>
  
                {/* Writing Style Template */}
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Writing Style Template</InputLabel>
                      <Select
                        value={selectedPrompt}
                        label="Writing Style Template"
                        disabled={isGenerating || loading || modelsLoading}
                        onChange={(event) => setSelectedPrompt(event.target.value)}
                      >
                        {[...prompts.systemTemplates, ...prompts.userPrompts]
                          .filter(template => 
                            template.type === 'product_review' || 
                            template.settings?.type === 'product_review' ||
                            template.name?.toLowerCase().includes('product review') ||
                            template.name === '**Product Reviews Extended**'
                          )
                          .map((prompt) => (
                            <MenuItem key={prompt.id} value={prompt.id}>
                              <Box>
                                <Typography variant="subtitle2">{prompt.name}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                  Detailed Amazon Product Roundup Review Prompt
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {selectedPrompt && (
                      <IconButton 
                        onClick={() => handlePreviewPrompt(selectedPrompt)}
                        sx={{ ml: 1 }}
                      >
                        <Eye />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Amazon Marketplace</InputLabel>
                    <Select
                      value={amazonDomain}
                      onChange={(e) => setAmazonDomain(e.target.value)}
                      disabled={isGenerating}
                    >
                      {AMAZON_MARKETPLACES.map((marketplace) => (
                        <MenuItem key={marketplace.value} value={marketplace.value}>
                          {marketplace.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Select the Amazon marketplace you want to target
                    </FormHelperText>
                  </FormControl>
                </Grid> */}
                {/* Amazon Disclaimer Settings */}
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Amazon Disclaimer Settings
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeDisclaimer}
                            onChange={(e) => setIncludeDisclaimer(e.target.checked)}
                            disabled={isGenerating}
                          />
                        }
                        label="Include Amazon Affiliate Disclaimer"
                      />
                      {includeDisclaimer && (
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            multiline
                            rows={2}
                            label="Disclaimer Text"
                            value={disclaimerText}
                            onChange={(e) => setDisclaimerText(e.target.value)}
                            disabled={isGenerating}
                            sx={{ mb: 2 }}
                          />
                          <FormControl fullWidth>
                            <InputLabel>Disclaimer Position</InputLabel>
                            <Select
                              value={disclaimerPosition}
                              onChange={(e) => setDisclaimerPosition(e.target.value)}
                              disabled={isGenerating}
                            >
                              <MenuItem value="top">Top of Article</MenuItem>
                              <MenuItem value="bottom">Bottom of Article</MenuItem>
                              <MenuItem value="after-intro">After Introduction</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
  
             {/* Featured Image Settings */}
<Grid item xs={12}>
  <Card variant="outlined">
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Typography variant="h6">
          Featured Image
        </Typography>
        <Tooltip title="Generate an AI-powered featured image for your review. You can customize the image style and provide specific prompts for better results.">
          <IconButton size="small">
            <HelpCircle size={18} />
          </IconButton>
        </Tooltip>
        <Box sx={{ flexGrow: 1 }} />
        <FormControlLabel
          control={
            <Switch
              checked={includeFeaturedImage}
              onChange={(e) => setIncludeFeaturedImage(e.target.checked)}
              disabled={isGenerating || isLoading}
            />
          }
          label="Include Featured Image"
        />
      </Box>

      {includeFeaturedImage && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Featured Image Prompt (optional)"
              placeholder="Leave blank to let AI generate based on article title"
              value={featuredImagePrompt}
              onChange={(e) => setFeaturedImagePrompt(e.target.value)}
              disabled={isGenerating || isLoading}
            />
          </Grid>
          
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={useAIEnhancement}
                  onChange={(e) => setUseAIEnhancement(e.target.checked)}
                  disabled={isGenerating || isLoading}
                />
              }
              label="Enhance Image Prompts with AI"
            />
            <FormHelperText>
              AI will automatically enhance image prompts for better results
            </FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <ImageControls
              model={featuredImageModel}
              setModel={setFeaturedImageModel}
              style={featuredImageStyle}
              setStyle={setFeaturedImageStyle}
              dimension={featuredDimension}
              setDimension={setFeaturedDimension}
              loading={isLoading}
              disabled={isGenerating}
            />
          </Grid>
        </Grid>
      )}
    </CardContent>
  </Card>
</Grid>
  
                {/* Product Selection */}
                <Grid item xs={12}>
                <Card variant="outlined">
  <CardContent>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h6">
          Product Selection
        </Typography>
        <Tooltip title="Enter Amazon product ASINs manually or search for products to review. You can add up to 10 products.">
          <IconButton size="small">
            <HelpCircle size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={searchMode === 'search'}
            onChange={() => setSearchMode(searchMode === 'search' ? 'asin' : 'search')}
            disabled={isGenerating}
          />
        }
        label={searchMode === 'search' ? 'Search Mode' : 'Manual ASIN Mode'}
      />
    </Box>
                      {searchMode === 'search' ? (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <TextField
                                fullWidth
                                label="Search Query"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                disabled={isGenerating}
                                placeholder="e.g., best gaming chairs under $200"
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                type="number"
                                label="Number of Products"
                                value={productCount}
                                onChange={(e) => setProductCount(Math.min(10, Math.max(1, parseInt(e.target.value) || 1)))}
                                inputProps={{ min: 1, max: 10 }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                variant="contained"
                                onClick={handleSearch}
                                disabled={isGenerating || isLoading || !searchQuery}
                                sx={{ height: '100%' }}
                                fullWidth
                              >
                                {isLoading ? <CircularProgress size={24} /> : 'Search'}
                              </Button>
                            </Grid>
                          </Grid>
  
                          {searchResults.length > 0 && (
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Product Title</TableCell>
                                    <TableCell>ASIN</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {searchResults.map((product) => (
                                    <TableRow key={product.asin}>
                                      <TableCell>
                                        {product.image_url && (
                                          <img 
                                            src={product.image_url} 
                                            alt={product.title}
                                            style={{ 
                                              width: '50px',
                                              height: '50px',
                                              objectFit: 'contain'
                                            }}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <a 
                                          href={`https://amazon.com/dp/${product.asin}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ 
                                            color: '#0066c0',
                                            textDecoration: 'none',
                                            '&:hover': {
                                              textDecoration: 'underline'
                                            }
                                          }}
                                        >
                                          {product.title}
                                        </a>
                                      </TableCell>
                                      <TableCell>{product.asin}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Enter Amazon ASINs (one per line, max 10)"
                          value={productAsins}
                          onChange={(e) => setProductAsins(e.target.value)}
                          helperText="Enter each Amazon ASIN on a new line"
                          required
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
  
                {/* Link Settings */}
<Grid item xs={12}>
  <Card variant="outlined">
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
        <Typography variant="h6">
          Link Settings
        </Typography>
        <Tooltip title="Configure how product links and buttons will appear in your review, including Amazon buttons and AFFI widgets.">
          <IconButton size="small">
            <HelpCircle size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      
      <Grid container spacing={3}>
        {/* Amazon Button Settings */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom color="text.secondary">
            Amazon Button Settings
          </Typography>
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeCheckButton}
                  onChange={(e) => setIncludeCheckButton(e.target.checked)}
                  disabled={isGenerating}
                />
              }
              label="Include 'Check on Amazon' Button"
            />
          </Box>
          {includeCheckButton && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 4 }}>
              <Button
                onClick={() => setShowColorPicker(true)}
                disabled={isGenerating}
                variant="contained"
                style={{ backgroundColor: buttonColor }}
                size="small"
              >
                Choose Button Color
              </Button>
              <Typography variant="body2" color="text.secondary">
                Current color: {buttonColor}
              </Typography>
            </Box>
          )}
        </Grid>

        {/* AFFI Widget Settings */}
       <Grid item xs={12}>
         <Divider sx={{ my: 2 }} />
         <Typography variant="subtitle2" gutterBottom color="text.secondary">
           AFFI Widget Settings
         </Typography>
         <FormControlLabel
           control={
             <Switch
               checked={useAffiShortcodes}
               onChange={(e) => setUseAffiShortcodes(e.target.checked)}
               disabled={isLoading}
             />
           }
           label="Include Affi.ai Product Widgets"
         />
         <FormControlLabel
           control={
             <Checkbox
               checked={includeAffiCarousel}
               onChange={(e) => setIncludeAffiCarousel(e.target.checked)}
               disabled={isLoading}
             />
           }
           label="Include Affi.ai Carousel After Introduction"
         />
         
         {useAffiShortcodes && (
           <Box sx={{ ml: 4, mt: 2 }}>
             <Grid container spacing={2}>
               <Grid item xs={12}>
                 <TextField
                   fullWidth
                   multiline
                   rows={2}
                   label="Custom Shortcode Template (Optional)"
                   placeholder={DEFAULT_AFFI_SHORTCODE}
                   helperText="Use {asin} as placeholder for product ASIN. Leave blank to use default."
                   value={customAffiShortcode}
                   onChange={(e) => setCustomAffiShortcode(e.target.value)}
                   disabled={isGenerating}
                 />
               </Grid>
               
               <Grid item xs={12}>
                 <FormControl fullWidth>
                   <InputLabel>AFFI Widget Placement</InputLabel>
                   <Select
                     value={affiShortcodePlacement}
                     onChange={(e) => setAffiShortcodePlacement(e.target.value)}
                     label="AFFI Widget Placement"
                     disabled={isGenerating}
                   >
                     <MenuItem value="after-title">After Product Title</MenuItem>
                     <MenuItem value="after-description">After Product Description</MenuItem>
                     <MenuItem value="before-pros-cons">Before Pros & Cons</MenuItem>
                   </Select>
                 </FormControl>
               </Grid>
             </Grid>
           </Box>
         )}
       </Grid>
      </Grid>
    </CardContent>
  </Card>
</Grid>
<Grid item xs={12}>
  <Card variant="outlined">
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Typography variant="h6">
          Buying Guide
        </Typography>
        <Tooltip title="Add a comprehensive buying guide to help readers make informed purchasing decisions">
          <IconButton size="small">
            <HelpCircle size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      
      <FormControlLabel
        control={
          <Checkbox
            checked={includeBuyingGuide}
            onChange={(e) => setIncludeBuyingGuide(e.target.checked)}
            disabled={isGenerating}
          />
        }
        label="Include Buying Guide Section"
      />
      
      {includeBuyingGuide && (
        <Box sx={{ mt: 2, ml: 4 }}>
          <FormControl fullWidth>
            <InputLabel>Guide Position</InputLabel>
            <Select
              value={buyingGuidePosition}
              onChange={(e) => setBuyingGuidePosition(e.target.value)}
              label="Guide Position"
              disabled={isGenerating}
            >
              <MenuItem value="top">Before Product Reviews</MenuItem>
              <MenuItem value="bottom">After Product Reviews</MenuItem>
            </Select>
            <FormHelperText>
              Choose where to place the buying guide in relation to the product reviews
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    </CardContent>
  </Card>
</Grid>
               

              {/* Pros and Cons Section */}
<Grid item xs={12}>
  <Card variant="outlined">
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Typography variant="h6">
          Pros & Cons
        </Typography>
        <Tooltip title="Add a pros and cons summary for each product to help readers quickly understand the key benefits and limitations.">
          <IconButton size="small">
            <HelpCircle size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={includeProsConsSection}
            onChange={(e) => setIncludeProsConsSection(e.target.checked)}
            disabled={isGenerating}
          />
        }
        label="Include Pros and Cons Sections"
      />
    </CardContent>
  </Card>
</Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={
                    isGenerating || 
                    isLoading || 
                    !selectedSite || 
                    !selectedCategory || 
                    !selectedPrompt || 
                    !title.trim() ||
                    !hasProducts
                  }
                  fullWidth
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Generate Product Review"
                  )}
                </Button>
              </Grid>

      
            </Grid>
          </form>
        </Paper>
      </Grid>

      {/* Right Column - Generation Status */}
      <Grid item xs={12} md={5}>
        <Paper sx={{ p: 3, height: '100%', position: 'sticky', top: 24 }}>
          <Typography variant="h6" gutterBottom>
            Generation Status
          </Typography>
          
          {isLoading ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <CircularProgress />
              <Typography sx={{ mt: 2 }} color="text.secondary">
                {generationStatus || 'Generating product review...'}
              </Typography>
            </Box>
          ) : searchResults.length > 0 ? (
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Selected Products
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {searchResults.map((product) => (
                      <TableRow key={product.asin}>
                        <TableCell>
                          <Typography variant="body2" noWrap>
                            {product.title}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption" color="text.secondary">
                            {product.asin}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', py: 8 }}>
              <Typography color="text.secondary">
                Fill out the form and click "Generate Product Review" to begin.
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>

    {/* Preview Dialog */}
    <Dialog
      open={previewOpen}
      onClose={() => setPreviewOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {previewPrompt?.name}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
          {previewPrompt?.prompt_text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPreviewOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>

    {/* Color Picker Dialog */}
    <ColorPickerDialog
      open={showColorPicker}
      onClose={() => setShowColorPicker(false)}
      color={buttonColor}
      onConfirm={(newColor) => {
        setButtonColor(newColor);
        setShowColorPicker(false);
      }}
    />

    {/* Notification Snackbar */}
    <Snackbar
      open={notification.open}
      autoHideDuration={6000}
      onClose={handleCloseNotification}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={handleCloseNotification}
        severity={notification.severity}
        sx={{ width: '100%' }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
    <LeaveConfirmationDialog
  open={leaveDialogOpen}
  onClose={() => setLeaveDialogOpen(false)}
  onConfirm={() => {
    setIsGenerating(false);
    setLeaveDialogOpen(false);
    if (navigationTarget) {
      window.location.href = navigationTarget;
    }
  }}
/>
<NavigationDialog />
  </Box>

);
}