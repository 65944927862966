export async function compressImage(blob, options = {}) {
    const defaultOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      preserveExif: true,
      initialQuality: 0.8,
      alwaysKeepResolution: true,
      outputFormat: 'png'
    };
  
    const finalOptions = { ...defaultOptions, ...options };
  
    try {
      // Create a canvas element for image processing
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
  
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
      });
  
      // Calculate dimensions to maintain aspect ratio and meet maxSizeMB
      const originalSize = blob.size / (1024 * 1024); // Convert to MB
      const scaleFactor = Math.min(1, finalOptions.maxSizeMB / originalSize);
      const targetWidth = img.width * scaleFactor;
      const targetHeight = img.height * scaleFactor;
  
      // Set canvas dimensions
      canvas.width = targetWidth;
      canvas.height = targetHeight;
  
      // Draw image with new dimensions
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
      // Set correct MIME type based on output format
      const mimeType = `image/${finalOptions.outputFormat}`;
  
      // Handle different quality settings for different formats
      let quality = finalOptions.initialQuality;
      if (finalOptions.outputFormat === 'png') {
        quality = undefined; // PNG doesn't use quality parameter
      }
  
      // Convert to blob with compression
      return new Promise((resolve) => {
        canvas.toBlob(
          resolve,
          mimeType,
          quality
        );
      });
    } catch (error) {
      console.error('Image compression failed:', error);
      throw error;
    }
  }
  
  export async function compressImageLossless(blob, options = {}) {
    const { outputFormat = 'png' } = options;
    
    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
      });
      
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      
      const mimeType = `image/${outputFormat}`;
      let quality;
      
      // Set appropriate quality settings for each format
      switch (outputFormat) {
        case 'png':
          quality = undefined; // PNG doesn't use quality
          break;
        case 'jpeg':
          quality = 1.0; // Maximum quality for JPEG
          break;
        case 'webp':
          quality = 1.0; // Maximum quality for WebP
          break;
        default:
          quality = undefined;
      }
      
      return new Promise((resolve) => {
        canvas.toBlob(resolve, mimeType, quality);
      });
    } catch (error) {
      console.error('Lossless compression failed:', error);
      return blob;
    }
  }