import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../config';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@mui/material';
import { Trash, Plus } from 'lucide-react';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AmazonSettings from './AmazonSettings'; // Ensure this path is correct
import SecureCredentialsField from './SecureCredentialsField';

// Custom TabPanel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function UserDashboard() {
  const { getAccessTokenSilently, user, isLoading: authLoading } = useAuth0();
  const [activeTab, setActiveTab] = useState(2);
  const [apiKeys, setApiKeys] = useState({
    openai: '',
    anthropic: '',
    fal: '',
    ideogram: '',
    openrouter: '',
    scrapeowl: ''
  });
  const [isLoadingApiKeys, setIsLoadingApiKeys] = useState(true);
  const [isLoadingWordPress, setIsLoadingWordPress] = useState(true);
  const [isLoadingAmazon, setIsLoadingAmazon] = useState(true);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [amazonSettings, setAmazonSettings] = useState({
    api_key: '',
    secret_key: '',
    tracking_id: '',
    marketplace: 'amazon.com'
  });
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [siteForm, setSiteForm] = useState({
    name: '',
    url: '',
    username: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Help content for API Keys
  const apiHelpContent = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: '#1976d2' }}>
          📋 API Key Setup Instructions
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
              OpenAI API Key
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
              1. Visit <a href="https://platform.openai.com" target="_blank" rel="noopener noreferrer">platform.openai.com</a><br />
              2. Sign up or log in, then go to "API Keys" in your dashboard<br />
              3. Click "Create new secret key" and copy the generated key<br />
              4. Paste it in the field above and save
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
              OpenRouter API Key
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
              1. Go to <a href="https://openrouter.ai" target="_blank" rel="noopener noreferrer">openrouter.ai</a><br />
              2. Sign up or log in to your account<br />
              3. Navigate to "Keys" in the left sidebar<br />
              4. Click "Generate Key", name it, and copy the key<br />
              5. Paste it in the field above and save
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
              Fal.ai API Key
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
              1. Go to <a href="https://fal.ai" target="_blank" rel="noopener noreferrer">fal.ai</a> and create an account<br />
              2. Navigate to your account settings or dashboard<br />
              3. Generate an API key and copy it<br />
              4. Paste it in the field above and save
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  // Help content for WordPress
  const wordpressHelpContent = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: '#1976d2' }}>
          📋 WordPress Connection Instructions
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            To connect your WordPress site, you'll need an Application Password:
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            1. Log into your WordPress admin panel<br />
            2. Go to Users > Profile<br />
            3. Scroll down to "Application Passwords"<br />
            4. Enter a name (e.g., "AI Writer") and click "Add New"<br />
            5. Copy the generated password (format: xxxx-xxxx-xxxx-xxxx)<br />
            6. Use your WordPress username and this password in the form<br />
            7. Ensure your site URL uses https:// and matches your WordPress installation
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1rem', color: 'warning.main', lineHeight: 1.6 }}>
            Note: Your WordPress user must have Administrator privileges.
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  // Help content for Amazon API
  const amazonHelpContent = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: '#1976d2' }}>
          📋 Amazon API Setup Instructions
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            You must be an existing Amazon Associate to use the Product Advertising API:
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            1. Ensure you're enrolled in the <a href="https://affiliate-program.amazon.com/" target="_blank" rel="noopener noreferrer">Amazon Associates Program</a><br />
            2. Go to the Product Advertising API page at <a href="https://affiliate-program.amazon.com/assoc_credentials/home" target="_blank" rel="noopener noreferrer">assoc_credentials/home</a><br />
            3. Click "Manage Your Credentials"<br />
            4. Select "Create a new key" to generate your Access Key and Secret Key<br />
            5. Copy your Access Key ID, Secret Access Key, and Associate Tag (Tracking ID)<br />
            6. Enter these details above and save
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  useEffect(() => {
    if (!authLoading) {
      fetchWordPressSites();
      fetchApiKeys();
      fetchAmazonSettings();
    }
  }, [authLoading]);

  const fetchWordPressSites = async () => {
    try {
      setIsLoadingWordPress(true);
      setLoadingError(null);
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${config.api.baseUrl}/api/user/wordpress-sites`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setSites(response.data);
    } catch (error) {
      console.error('WordPress sites fetch error:', error);
      setLoadingError(error.response?.data?.detail || 'Error loading WordPress sites');
      setNotification({
        open: true,
        message: 'Error loading WordPress sites: ' + (error.response?.data?.detail || error.message),
        severity: 'error'
      });
    } finally {
      setIsLoadingWordPress(false);
    }
  };

  const fetchApiKeys = async () => {
    try {
      setIsLoadingApiKeys(true);
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${config.api.baseUrl}/api/user/api-keys`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setApiKeys(response.data);
    } catch (error) {
      console.error('API keys fetch error:', error);
      setNotification({
        open: true,
        message: 'Error loading API keys: ' + (error.response?.data?.detail || error.message),
        severity: 'error'
      });
    } finally {
      setIsLoadingApiKeys(false);
    }
  };

  const fetchAmazonSettings = async () => {
    try {
      setIsLoadingAmazon(true);
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${config.api.baseUrl}/api/user/amazon-credentials`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setAmazonSettings({
        api_key: response.data.api_key === true ? '••••••••' : response.data.api_key || '',
        secret_key: response.data.secret_key === true ? '••••••••' : response.data.secret_key || '',
        tracking_id: response.data.tracking_id || ''
      });
    } catch (error) {
      console.error('Amazon settings fetch error:', error);
      setNotification({
        open: true,
        message: 'Error loading Amazon settings: ' + (error.response?.data?.detail || error.message),
        severity: 'error'
      });
    } finally {
      setIsLoadingAmazon(false);
    }
  };

  const handleApiKeysSave = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${config.api.baseUrl}/api/user/api-keys`, apiKeys, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setNotification({
        open: true,
        message: 'API keys saved successfully',
        severity: 'success'
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.response?.data?.detail || 'Failed to save API keys',
        severity: 'error'
      });
    }
  };

  const handleSaveSite = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const errors = {};
    if (!siteForm.name.trim()) errors.name = "Site name is required";
    if (!siteForm.url.trim()) {
      errors.url = "Site URL is required";
    } else {
      let cleanUrl = siteForm.url.trim().toLowerCase();
      cleanUrl = cleanUrl.replace(/\/+$/, '');
      if (!cleanUrl.startsWith('http')) cleanUrl = 'https://' + cleanUrl;
      setSiteForm({ ...siteForm, url: cleanUrl });
    }
    if (!siteForm.username.trim()) errors.username = "Username is required";
    if (!siteForm.password.trim()) errors.password = "Password is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      setNotification({
        open: true,
        message: 'Verifying WordPress connection...',
        severity: 'info'
      });
      const token = await getAccessTokenSilently();
      const url = `${config.api.baseUrl}/api/user/wordpress-sites`;
      await axios.post(url, siteForm, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      await fetchWordPressSites();
      handleCloseSiteDialog();
      setNotification({
        open: true,
        message: 'WordPress site connected successfully',
        severity: 'success'
      });
    } catch (error) {
      let errorMessage = 'Failed to connect to WordPress site';
      if (error.response?.data?.detail) errorMessage = error.response.data.detail;
      else if (error.response?.status === 404) errorMessage = "WordPress REST API not found.";
      else if (error.response?.status === 401) errorMessage = "Invalid credentials.";
      else if (error.response?.status === 403) errorMessage = "Access denied.";
      else if (error.message.includes('Network Error')) errorMessage = "Could not connect.";
      
      setNotification({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDeleteDialog = (site) => {
    setSiteToDelete(site);
    setDeleteDialogOpen(true);
  };

  const handleDeleteSite = async () => {
    try {
      setDeleteLoading(true);
      const token = await getAccessTokenSilently();
      await axios.delete(`${config.api.baseUrl}/api/user/wordpress-sites/${siteToDelete.id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setDeleteDialogOpen(false);
      await fetchWordPressSites();
      setNotification({
        open: true,
        message: 'Site deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error deleting WordPress site',
        severity: 'error'
      });
    } finally {
      setDeleteLoading(false);
      setSiteToDelete(null);
    }
  };

  const handleOpenSiteDialog = () => {
    setSiteForm({ name: '', url: '', username: '', password: '' });
    setSelectedSite(null);
    setFormErrors({});
    setIsSubmitting(false);
    setOpenDialog(true);
  };

  const handleCloseSiteDialog = () => {
    setOpenDialog(false);
    setSelectedSite(null);
    setFormErrors({});
    setIsSubmitting(false);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleSaveAmazonSettings = async (settings) => {
    try {
      if (Object.keys(settings).length === 0) return;
      const token = await getAccessTokenSilently();
      await axios.post(`${config.api.baseUrl}/api/user/amazon-credentials`, settings, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      await fetchAmazonSettings();
      setNotification({
        open: true,
        message: 'Amazon settings saved successfully',
        severity: 'success'
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.response?.data?.detail || 'Failed to save Amazon settings',
        severity: 'error'
      });
    }
  };

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: 1200, mx: 'auto' }}>
      <Paper elevation={3} sx={{ 
        borderRadius: 2,
        overflow: 'hidden',
        background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
      }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            backgroundColor: '#f8f9fa',
            '& .MuiTab-root': { textTransform: 'none', fontWeight: 500, fontSize: '1rem' },
            '& .Mui-selected': { color: '#1976d2', backgroundColor: '#ffffff' }
          }}
          TabIndicatorProps={{ style: { backgroundColor: '#1976d2', height: 3 } }}
        >
          <Tab label="User Details" />
          <Tab label="API Keys" />
          <Tab label="WordPress Sites" />
          <Tab label="Amazon API" />
        </Tabs>

        {/* User Details Tab */}
        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
                <CardContent>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Name
                  </Typography>
                  <Typography variant="h6">{user?.name}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
                <CardContent>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Email
                  </Typography>
                  <Typography variant="h6">{user?.email}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>

        {/* API Keys Tab */}
        <TabPanel value={activeTab} index={1}>
          {isLoadingApiKeys ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ maxWidth: 600, mx: 'auto' }}>
              <form onSubmit={handleApiKeysSave}>
                <Grid container spacing={3}>
                  {[
                    { label: "OpenAI API Key", key: "openai" },
                    { label: "Anthropic API Key", key: "anthropic" },
                    { label: "OpenRouter API Key", key: "openrouter" },
                    { label: "Fal.ai API Key", key: "fal" },
                    { label: "ScrapeOwl API Key", key: "scrapeowl", helper: "Required for URL Rewriter" }
                  ].map((field) => (
                    <Grid item xs={12} key={field.key}>
                      <TextField
                        fullWidth
                        label={field.label}
                        type="password"
                        value={apiKeys[field.key]}
                        onChange={(e) => setApiKeys({ ...apiKeys, [field.key]: e.target.value })}
                        variant="outlined"                     
                        helperText={field.helper}
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2, backgroundColor: '#fff' } }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      size="large"
                      sx={{ borderRadius: 2, px: 4, py: 1.5, backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
                    >
                      Save API Keys
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Box sx={{ mt: 4 }}>{apiHelpContent}</Box>
            </Box>
          )}
        </TabPanel>

        {/* WordPress Sites Tab */}
        <TabPanel value={activeTab} index={2}>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Connected WordPress Sites
              </Typography>
              <Button
                variant="contained"
                startIcon={<Plus />}
                onClick={handleOpenSiteDialog}
                sx={{ borderRadius: 2, px: 3, backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
              >
                Add Site
              </Button>
            </Box>

            {isLoadingWordPress ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : loadingError ? (
              <Alert severity="error" sx={{ mb: 2, borderRadius: 2 }}>{loadingError}</Alert>
            ) : sites.length === 0 ? (
              <Alert severity="info" sx={{ mb: 2, borderRadius: 2 }}>
                No WordPress sites added yet. Click "Add Site" to get started.
              </Alert>
            ) : (
              <Grid container spacing={2}>
                {sites.map((site) => (
                  <Grid item xs={12} md={6} key={site.id}>
                    <Card sx={{ borderRadius: 2, transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }}>
                      <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img 
                          src={`${site.url}/favicon.ico`}
                          alt=""
                          onError={(e) => { e.target.src = `https://www.google.com/s2/favicons?domain=${site.url}`; }}
                          style={{ width: 24, height: 24, objectFit: 'contain' }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="h6" sx={{ fontWeight: 500 }}>{site.name}</Typography>
                          <Typography color="text.secondary" variant="body2">{site.url}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleOpenDeleteDialog(site)}>
                              <Trash size={20} color="#ef4444" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
            <Box sx={{ mt: 4 }}>{wordpressHelpContent}</Box>
          </Box>
        </TabPanel>

        {/* Amazon API Tab */}
        <TabPanel value={activeTab} index={3}>
          {isLoadingAmazon ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ maxWidth: 600, mx: 'auto' }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 3 }}>
                Amazon API Settings
              </Typography>
              <AmazonSettings
                onSave={handleSaveAmazonSettings}
                initialData={amazonSettings}
                isLoading={false}
              />
              <Box sx={{ mt: 4 }}>{amazonHelpContent}</Box>
            </Box>
          )}
        </TabPanel>
      </Paper>

      {/* WordPress Site Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseSiteDialog} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{ sx: { borderRadius: 2 } }}
      >
        <form onSubmit={handleSaveSite}>
          <DialogTitle sx={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #eee' }}>
            Add WordPress Site
          </DialogTitle>
          <DialogContent sx={{ pt: 2 }}>
            <Grid container spacing={2}>
              {[
                { label: "Site Name", key: "name" },
                { label: "Site URL", key: "url", helper: "Example: your-site.com" },
                { label: "Username", key: "username" },
                { label: "Password", key: "password", type: "password" }
              ].map((field) => (
                <Grid item xs={12} key={field.key}>
                  <TextField
                    fullWidth
                    label={field.label}
                    type={field.type || "text"}
                    value={siteForm[field.key]}
                    onChange={(e) => setSiteForm({ ...siteForm, [field.key]: e.target.value })}
                    required
                    helperText={formErrors[field.key] || field.helper}
                    variant="outlined"
                    error={!!formErrors[field.key]}
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2, backgroundColor: '#fff' } }}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button onClick={handleCloseSiteDialog} sx={{ borderRadius: 2 }}>
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained"
              disabled={isSubmitting}
              sx={{ borderRadius: 2, px: 3, backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Add Site'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Trash color="#ef4444" size={20} />
            <Typography variant="h6" component="span">Delete WordPress Site</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
            This action cannot be undone. The site will be disconnected from Scripti.sh.
          </Alert>
          <Typography>
            Are you sure you want to delete the connection to <strong>{siteToDelete?.name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={deleteLoading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteSite}
            disabled={deleteLoading}
            startIcon={deleteLoading ? <CircularProgress size={20} /> : <Trash size={20} />}
          >
            {deleteLoading ? 'Deleting...' : 'Delete Site'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%', borderRadius: 2, boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UserDashboard;