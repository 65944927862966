import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0Wrapper = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  const [hasRedirected, setHasRedirected] = useState(false); // Add a flag

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');

    if (!isLoading && isAuthenticated && !hasRedirected) {
      if (error) {
        navigate('/unauthorized', { replace: true });
        setHasRedirected(true);
      } else if (location.pathname === '/callback') { // Only redirect from /callback
        navigate('/', { replace: true });
        setHasRedirected(true);
      }
    }
  }, [isAuthenticated, isLoading, navigate, location.pathname, hasRedirected]);

  return children;
};

export default Auth0Wrapper;