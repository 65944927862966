import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Alert,
    Box,
    Button,
    Card,
    FormControlLabel,
    Checkbox,
    Link,
    CardContent,
    Typography,
    CircularProgress,
    Snackbar,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@mui/material';
import { 
    Sparkles,
    Bot,
    Image,
    FileText,
    Zap
} from 'lucide-react';
import axios from 'axios';
import { config } from '../config';




const SubscriptionPlans = () => {
    const { getAccessTokenSilently } = useAuth0();
    const location = useLocation();
    const navigate = useNavigate();
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showCanceled, setShowCanceled] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTermsWarning, setShowTermsWarning] = useState(false);

    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    useEffect(() => {
        // Check URL parameters
        const searchParams = new URLSearchParams(location.search);
        const success = searchParams.get('success');
        const canceled = searchParams.get('canceled');

        if (success) {
            setSuccessMessage('Subscription successful! Welcome aboard.');
            setShowSuccess(true);
            // Remove query parameters from URL
            navigate('/account/subscription', { replace: true });
        }
        if (canceled) {
            setShowCanceled(true);
            navigate('/account/subscription', { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        const initializeData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                await fetchData(accessToken);
            } catch (err) {
                console.error('Authentication error:', err);
                setError('Authentication failed. Please try again.');
                setLoading(false);
            }
        };

        initializeData();
    }, [getAccessTokenSilently]);


// Add this after other useEffects
useEffect(() => {
  const { state } = location;
  if (state?.newSignup) {
    setNotification({
      open: true,
      message: 'Welcome! Please choose a subscription plan to continue.',
      severity: 'info'
    });
  } else if (state?.needsSubscription) {
    setNotification({
      open: true,
      message: 'An active subscription is required to access this feature.',
      severity: 'warning'
    });
  } else if (state?.error) {
    setNotification({
      open: true,
      message: 'There was an error verifying your subscription. Please try again.',
      severity: 'error'
    });
  }
}, [location]);

// Add this alert at the top of your render, before the subscription content
{location.state?.newSignup && (
  <Alert severity="info" sx={{ mb: 3 }}>
    Welcome to Scripti.sh! Choose a subscription plan to get started with all our features.
  </Alert>
)}

{location.state?.needsSubscription && (
  <Alert severity="warning" sx={{ mb: 3 }}>
    A subscription is required to access this feature. Please choose a plan to continue.
  </Alert>
)}

    const fetchData = async (accessToken) => {
        try {
            const [pricesResponse, subscriptionResponse] = await Promise.all([
                axios.get(`${config.api.baseUrl}/api/stripe/prices`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }),
                axios.get(`${config.api.baseUrl}/api/stripe/subscription`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    },
                    params: {
                        expand: ['items.data.price.product', 'default_payment_method', 'discount']
                    }
                })
            ]);

            setPrices(pricesResponse.data.prices);
            setSubscription(subscriptionResponse.data.subscription);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to load subscription data');
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = async (priceId) => {
        if (!termsAccepted) {
            setShowTermsWarning(true);
            return;
        }
        setShowTermsWarning(false);
        setError(null);
        

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await axios.post(
                `${config.api.baseUrl}/api/stripe/create-checkout-session`,
                { 
                    price_id: priceId,
                    return_url: '/prompts/new'
                 },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            if (response.data?.url) {
                window.location.href = response.data.url;
            } else {
                setError('Invalid response from server');
                console.error('Invalid checkout response:', response.data);
            }
        } catch (err) {
            const errorMessage = err.response?.data?.detail || 'Failed to initiate checkout. Please try again.';
            setError(errorMessage);
            console.error('Checkout error:', err);
        }
    };

    const handleReactivate = async (subscriptionId) => {
        try {
            setError(null);
            const accessToken = await getAccessTokenSilently();
            
            if (!window.confirm('Would you like to restart your membership?')) {
                return;
            }
    
            const response = await axios.post(
                `${config.api.baseUrl}/api/stripe/reactivate-subscription`,
                { subscription_id: subscriptionId },  
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.data?.subscription) {
                // Refresh subscription data
                await fetchData(accessToken);
                
                setSuccessMessage('Your subscription has been successfully reactivated!');
                setShowSuccess(true);
            }
    
        } catch (err) {
            console.error('Reactivation error:', err);
            const errorMessage = err.response?.data?.detail || 'Failed to reactivate subscription';
            setError(errorMessage);
        }
    };

    const handleCancel = async () => {
        try {
            setError(null);
            const accessToken = await getAccessTokenSilently();
            
            // Add confirmation dialog
            if (!window.confirm('Are you sure you want to cancel your subscription?')) {
                return;
            }

            await axios.post(
                `${config.api.baseUrl}/api/stripe/cancel-subscription`,
                { subscription_id: subscription.id },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Refresh subscription data
            await fetchData(accessToken);
            
            setSuccessMessage('Subscription cancelled successfully.');
            setShowSuccess(true);

        } catch (err) {
            console.error('Cancel error:', err);
            const errorMessage = err.response?.data?.detail || 'Failed to cancel subscription';
            setError(errorMessage);
        }
    };

    const handleCloseSnackbar = () => {
        setShowSuccess(false);
        setShowCanceled(false);
    };

    const getSubscriptionStatus = (status, cancelAtPeriodEnd) => {
        if (status === 'active' && cancelAtPeriodEnd) {
            return 'Canceling at end of period';
        }
        return status;
    };

    const features = [
        {
            icon: <Bot size={24} color="#E04C16" />,
            text: "Access to OpenAI, Anthropic And Openrouter AI Models"
        },
        {
            icon: <Image size={24} color="#E04C16" />,
            text: "Unlimited AI Image Generation*"
        },
        {
            icon: <FileText size={24} color="#E04C16" />,
            text: "Unlimited Content Generation*"
        },
        {
            icon: <Sparkles size={24} color="#E04C16" />,
            text: "Access to PromptLab Prompt Generator"
        },
        {
            icon: <Zap size={24} color="#E04C16" />,
            text: "Unlimited Free Updates And Access To New Features"
        },
        {
            icon: <Zap size={24} color="#E04C16" />,
            text: "Access To Private Facebook Support Group"
        }
    ];

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress sx={{ color: '#E04C16' }} />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 4, mb: 4 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

<Snackbar
    open={notification.open}
    autoHideDuration={6000}
    onClose={() => setNotification(prev => ({ ...prev, open: false }))}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
    <Alert 
        onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
        severity={notification.severity}
        sx={{ width: '100%' }}
    >
        {notification.message}
    </Alert>
</Snackbar>

                {subscription ? (
                    <Card 
                    sx={{ 
                        maxWidth: 400,
                        mx: 'auto',
                        background: 'linear-gradient(145deg, #ffffff 0%, #f9f9f9 100%)',
                        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                        borderRadius: 2
                    }}
                >
                    <CardContent sx={{ p: 4 }}>
                        
                       <Typography variant="h5" gutterBottom sx={{ color: '#E04C16', fontWeight: 600 }}>
                           {subscription?.items?.data?.[0]?.price?.product?.name || 'Current Subscription'} {subscription.cancel_at_period_end ? '(Ending)' : ''}
                       </Typography>
                        
                      
                       <Typography variant="h4" sx={{ mt: 2, fontWeight: 700 }}>
                           {subscription.discount ? (
                               <>
                                   <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                       <Typography 
                                           component="span" 
                                           sx={{ 
                                               textDecoration: 'line-through', 
                                               color: 'text.secondary',
                                               fontSize: '1.5rem' 
                                           }}
                                       >
                                           ${(subscription.plan.amount / 100).toFixed(2)}
                                       </Typography>
                                       ${((subscription.plan.amount * (1 - subscription.discount.coupon.percent_off/100)) / 100).toFixed(2)}
                                       <Typography component="span" variant="h6" color="text.secondary">
                                           /month
                                       </Typography>
                                   </Box>
                                   <Typography 
                                       variant="h6" 
                                       color="success.main" 
                                       sx={{ mt: 1 }}
                                   >
                                       {subscription.discount.coupon.percent_off}% discount applied
                                   </Typography>
                               </>
                           ) : (
                               <>
                                   ${subscription.plan.amount / 100}
                                   <Typography component="span" variant="h6" color="text.secondary">
                                       /month
                                   </Typography>
                               </>
                           )}
                       </Typography>
            
                        <Divider sx={{ my: 2 }} />
                        
                        {subscription.cancel_at_period_end ? (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                Your premium features will end on {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                            </Alert>
                        ) : (
                            <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                                Next billing date: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                            </Typography>
                        )}
            
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 2, fontWeight: 600 }}>
                            {subscription.cancel_at_period_end ? 'Features you\'ll lose access to:' : 'Your active features:'}
                        </Typography>
            
                        <List>
                            {features.map((feature, index) => (
                                <ListItem key={index} sx={{ px: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                        {feature.icon}
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={feature.text}
                                        primaryTypographyProps={{
                                            sx: { 
                                                fontWeight: 500,
                                                color: subscription.cancel_at_period_end ? 'text.secondary' : 'text.primary'
                                            }
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
            
                        {subscription.cancel_at_period_end ? (
                            <Box sx={{ mt: 3 }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#E04C16',
                                        '&:hover': {
                                            backgroundColor: '#c43e12'
                                        },
                                        mb: 2,
                                        py: 1.5
                                    }}
                                    onClick={() => handleReactivate(subscription.id)}
                                >
                                    Restart Membership Now
                                </Button>
                                <Typography variant="body2" color="text.secondary" align="center">
                                    Restart now to keep your premium features without interruption
                                </Typography>
                            </Box>
                        ) : (
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    color: '#E04C16',
                                    borderColor: '#E04C16',
                                    '&:hover': {
                                        borderColor: '#c43e12',
                                        color: '#c43e12'
                                    },
                                    mt: 2
                                }}
                                onClick={handleCancel}
                            >
                                Cancel Subscription
                            </Button>
                        )}
                        <Typography variant="caption" color="text.secondary" align="center" sx={{ mt: 3, display: 'block' }}>
  *Additional charges apply through usage of your API keys for applicable AI model providers
</Typography>
                    </CardContent>
                </Card>
                ) : (
                    <Box>
                       
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {prices.map((price) => (
                                <Card
                                key={price.id}
                                sx={{
                                    width: 380,
                                    minHeight: 570, 
                                    background: 'linear-gradient(145deg, #ffffff 0%, #f9f9f9 100%)',
                                    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                                    borderRadius: 2,
                                    transition: 'transform 0.2s',
                                    display: 'flex',  
                                    flexDirection: 'column',  
                                    '&:hover': {
                                        transform: 'translateY(-5px)'
                                    }
                                }}
                            >
                                    <CardContent sx={{ p: 4 }}>
                                        <Typography variant="h5" gutterBottom sx={{ color: '#E04C16', fontWeight: 600 }}>
                                            {price.product.name}
                                        </Typography>
                                        <Typography variant="h3" sx={{ mt: 2, mb: 3, fontWeight: 700 }}>
                                            ${price.unit_amount / 100}
                                            <Typography component="span" variant="h6" color="text.secondary">
                                                /month
                                            </Typography>
                                        </Typography>
                                        <Typography color="text.secondary" sx={{ mb: 4 }}>
                                            {price.product.description}
                                        </Typography>
                                        <Divider sx={{ my: 3 }} />
                                        <List>
                                            {features.map((feature, index) => (
                                                <ListItem key={index} sx={{ px: 0 }}>
                                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                                        {feature.icon}
                                                    </ListItemIcon>
                                                    <ListItemText 
                                                        primary={feature.text}
                                                        primaryTypographyProps={{
                                                            sx: { fontWeight: 500 }
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Box sx={{ mt: 1 }}>
    <FormControlLabel
        control={
            <Checkbox
                checked={termsAccepted}
                onChange={(e) => {
                    setTermsAccepted(e.target.checked);
                    if (e.target.checked) {
                        setShowTermsWarning(false);
                    }
                }}
                sx={{
                    color: '#E04C16',
                    '&.Mui-checked': {
                        color: '#E04C16',
                    },
                }}
            />
        }
        label={
            <Typography variant="body2">
                I accept the{' '}
                <Link
                    href="/terms.html"
                    target="_blank"
                    sx={{
                        color: '#E04C16',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    terms of usage
                </Link>
            </Typography>
        }
    />
    {showTermsWarning && (
        <Typography
            color="error"
            variant="caption"
            sx={{ display: 'block', mt: 1 }}
        >
            Please accept the terms of usage to continue
        </Typography>
    )}
    
</Box>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            sx={{
                                                mt: 4,
                                                backgroundColor: '#E04C16',
                                                '&:hover': {
                                                    backgroundColor: '#c43e12'
                                                },
                                                py: 1.5
                                            }}
                                            onClick={() => handleSubscribe(price.id)}
                                        >
                                            Get Started
                                        </Button>
                                        <Typography variant="caption" color="text.secondary" align="center" sx={{ mt: 3, display: 'block' }}>
  *Additional charges apply through usage of your API keys for applicable AI model providers
</Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default SubscriptionPlans;