import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import { config } from '../config';
import { useAuth0 } from '@auth0/auth0-react';

const SubscriptionProtectedRoute = () => {
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${config.api.baseUrl}/api/stripe/subscription`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
               'Content-Type': 'application/json'
            },
            withCredentials: true
          }
        );
    
        const subscription = response.data?.subscription;
        const isActive = subscription?.status === 'active';
        
        setHasSubscription(isActive);
      } catch (err) {
        console.error('Subscription check error:', err);
        setError('Failed to verify subscription status');
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      checkSubscription();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  // Redirect to subscription page if no active subscription
  if (!hasSubscription) {
    return <Navigate 
      to="/subscription" 
      state={{ from: location, needsSubscription: true }}
      replace 
    />;
  }

  // If has subscription, render the protected route
  return <Outlet />;
};

export default SubscriptionProtectedRoute;