import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Tooltip,
  Grid,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  LinearProgress,
  Link,
} from '@mui/material';
import { 
  Upload, 
  Download, 
  ImageIcon, 
  Sparkles, 
  HelpCircle,
  CheckCircle,
  X,
  Maximize
} from 'lucide-react';
import PageHeader from './PageHeader';
import ImageControls from './ImageControls';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import aiClient from '../api/clients/AiClient';
import imageClient from '../api/clients/ImageClient';
import { useAIModels } from '../hooks/useAIModels';
import WordPressClient from '../utils/WordPressClient';
import CompressionDialog from './CompressionDialog';
import { compressImage, compressImageLossless } from '../utils/imageCompression';
import JSZip from 'jszip';


const ImageGenerator = () => {

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // Basic state
  const [prompts, setPrompts] = useState('');
  const [model, setModel] = useState('fal-ai/flux/schnell');
  const [dimension, setDimension] = useState('square_hd');
  const [useCustomPrompt, setUseCustomPrompt] = useState(false);
  const [safetyCheckerEnabled, setSafetyCheckerEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [generationProgress, setGenerationProgress] = useState({ current: 0, total: 0 });
  const [generatedImage, setGeneratedImage] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [wordPressSites, setWordPressSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({ current: 0, total: 0 });

  const [compressionDialogOpen, setCompressionDialogOpen] = useState(false);
  const [compressionAction, setCompressionAction] = useState('download');
  const [pendingImages, setPendingImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);



  const [notification, setNotification] = useState({ 
    open: false,
    severity: 'success',
    message: '' 
  });

  // Auth and API hooks
  const { authenticatedRequest, error: authError } = useAuth();
  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);

  const handleCompressedDownload = async (images, isLossless, options = {}) => {
    try {
      if (images.length === 1) {
        const response = await fetch(images[0].url);
        const blob = await response.blob();
        const compressedBlob = isLossless ? 
          await compressImageLossless(blob) :
          await compressImage(blob, options);
  
        const url = window.URL.createObjectURL(compressedBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `compressed-image-${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        const zip = new JSZip();
        for (const image of images) {
          const response = await fetch(image.url);
          const blob = await response.blob();
          const compressedBlob = isLossless ? 
            await compressImageLossless(blob) :
            await compressImage(blob, options);
          
          const filename = `compressed-${Date.now()}-${image.prompt.slice(0, 30)}.png`;
          zip.file(filename, compressedBlob);
        }
  
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const url = window.URL.createObjectURL(zipBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `compressed-images-${Date.now()}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Compression/download error:', error);
      throw error;
    }
  };
  
  const handleCompressedUpload = async (images, wpClient, isLossless, options = {}) => {
    const results = [];
    for (const image of images) {
      const response = await fetch(image.url);
      const blob = await response.blob();
      const compressedBlob = isLossless ? 
        await compressImageLossless(blob) :
        await compressImage(blob, options);
  
      const filename = `compressed-${Date.now()}-${image.prompt.slice(0, 30)}.png`;
      const result = await wpClient.uploadMedia(compressedBlob, filename);
      results.push(result);
    }
    return results;
  };
  
  useEffect(() => {
      fetchWordPressSites();
    }, []);
  
  useEffect(() => {
    const initializeServices = async () => {
      try {
        const keys = await authenticatedRequest(() => apiClient.getApiKeys());
        aiClient.setApiKeys(keys);
        aiClient.updateModelConfigs(availableModels);
        imageClient.setAvailableModels(availableModels);
        imageClient.setAiClient(aiClient);
        imageClient.setApiKeys(keys);
      } catch (error) {
        console.error('Error initializing services:', error);
        setError('Failed to initialize services: ' + error.message);
      }
    };

    if (availableModels.length > 0) {
      initializeServices();
    }
  }, [availableModels, authenticatedRequest]);

  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };

  const handleGenerate = async () => {
    const promptList = prompts.split('\n').filter(p => p.trim());
    if (promptList.length === 0) {
      setError('Please enter at least one image description');
      return;
    }
    if (promptList.length > 50) {
      setError('Maximum 50 images allowed per batch');
      return;
    }

    
  const sanitizedPrompts = promptList.map(prompt => {
    
    const sanitized = prompt
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
      .replace(/javascript:/gi, '')
      .replace(/on\w+=/gi, '')
      .trim();
      
    
    return sanitized.slice(0, 1000);
  });

 
  const validPrompts = sanitizedPrompts.filter(p => p.length > 0);
  if (validPrompts.length === 0) {
    setError('Invalid prompts detected. Please enter valid image descriptions.');
    return;
  }

  

    setLoading(true);
    setError(null);
    setGeneratedImages([]);
    setSelectedImages([]);
    setGenerationProgress({ current: 0, total: promptList.length });

    try {
      const images = [];
      for (let i = 0; i < promptList.length; i++) {
        const prompt = promptList[i];
        try {
          const imageData = await imageClient.generateImage({
            prompt,
            model,
            dimension,
            enhancePrompt: !useCustomPrompt,
            enableSafetyFilter: safetyCheckerEnabled,
            context: {
              articleTitle: prompt
            }
          });

          if (imageData?.image_url) {
            images.push({
              id: Date.now() + i,
              url: imageData.image_url,
              prompt: prompt,
              status: 'success'
            });
          }
        } catch (err) {
          console.error(`Error generating image for prompt: ${prompt}`, err);
          images.push({
            id: Date.now() + i,
            prompt: prompt,
            status: 'error',
            error: err.message
          });
        }
        setGenerationProgress(prev => ({ ...prev, current: i + 1 }));
        setGeneratedImages([...images]);
      }
    } catch (err) {
      console.error('Generation error:', err);
      setError(err.message || 'Failed to generate images');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectImage = (imageId) => {
    setSelectedImages(prev => {
      if (prev.includes(imageId)) {
        return prev.filter(id => id !== imageId);
      }
      return [...prev, imageId];
    });
  };

  const handleSelectAll = () => {
    if (selectedImages.length === generatedImages.length) {
      setSelectedImages([]);
    } else {
      setSelectedImages(generatedImages.filter(img => img.status === 'success').map(img => img.id));
    }
  };



  const fetchWordPressSites = async () => {
    try {
      const sitesData = await authenticatedRequest(() => 
        apiClient.getWordPressSites()
      );
      setWordPressSites(sitesData);
      if (sitesData.length > 0) {
        setSelectedSite(sitesData[0].id);
      }
    } catch (error) {
      console.error('Error fetching WordPress sites:', error);
      setError('Failed to fetch WordPress sites');
    }
  };

 

  const handleConfirmUpload = async () => {
    if (!selectedSite) {
      setUploadError('Please select a WordPress site');
      return;
    }
  
    setUploadLoading(true);
    setUploadError(null);
  
    try {
      const site = wordPressSites.find(s => s.id === selectedSite);
      if (!site) {
        throw new Error('Selected site not found');
      }
  
      const wpClient = new WordPressClient(site);
      const credentials = await authenticatedRequest(() => 
        apiClient.getWordPressCredentials(selectedSite)
      );
      wpClient.setCredentials(credentials.username, credentials.password);
  
      const imagesToUpload = generatedImages.length === 1 ? 
        [generatedImages[0]] : 
        generatedImages.filter(img => selectedImages.includes(img.id) && img.status === 'success');
  
      if (imagesToUpload.length === 0) {
        throw new Error('No valid images selected for upload');
      }
  
      // Initialize progress
      setUploadProgress({ current: 0, total: imagesToUpload.length });
  
      const uploadResults = [];
      const failures = [];
  
      for (let i = 0; i < imagesToUpload.length; i++) {
        const image = imagesToUpload[i];
        try {
          const response = await fetch(image.url);
          if (!response.ok) {
            failures.push(`Failed to download image: ${image.prompt}`);
            continue;
          }
  
          const blob = await response.blob();
          const filename = `generated-image-${Date.now()}-${image.prompt.slice(0, 30).replace(/[^a-z0-9]/gi, '-')}.png`;
  
          const result = await wpClient.uploadMedia(blob, filename);
          if (result?.id) {
            uploadResults.push(result);
          }
        } catch (err) {
          console.error(`Failed to upload image: ${image.prompt}`, err);
          failures.push(image.prompt);
        }
        
        // Update progress
        setUploadProgress(prev => ({ ...prev, current: i + 1 }));
      }
  
      setUploadDialogOpen(false);
  
      if (failures.length === 0) {
        setNotification({
          open: true,
          severity: 'success',
          message: `Successfully uploaded ${uploadResults.length} image${uploadResults.length > 1 ? 's' : ''} to WordPress`
        });
      } else if (uploadResults.length > 0) {
        setNotification({
          open: true,
          severity: 'warning',
          message: `Uploaded ${uploadResults.length} image${uploadResults.length > 1 ? 's' : ''}, but ${failures.length} failed`
        });
      } else {
        throw new Error('All uploads failed');
      }
    } catch (err) {
      console.error('Upload error:', err);
      setUploadError(err.message || 'Failed to upload images to WordPress');
    } finally {
      setUploadLoading(false);
      setUploadProgress({ current: 0, total: 0 }); // Reset progress
    }
  };

  const handleDownloadWithCompression = async (compressionSettings) => {
    try {
      setLoading(true);
      const imagesToDownload = generatedImages.length === 1 ? 
        [generatedImages[0]] : 
        generatedImages.filter(img => selectedImages.includes(img.id) && img.status === 'success');
  
      if (imagesToDownload.length === 0) {
        throw new Error('No valid images selected for download');
      }
  
      if (imagesToDownload.length === 1) {
        const image = imagesToDownload[0];
        const response = await fetch(image.url);
        const blob = await response.blob();
        
        const compressedBlob = compressionSettings.type === 'none' ? 
          blob : 
          compressionSettings.type === 'lossless' ? 
            await compressImageLossless(blob, {
              outputFormat: compressionSettings.options?.outputFormat || 'png'
            }) :
            await compressImage(blob, {
              maxSizeMB: compressionSettings.options.maxSizeMB,
              initialQuality: compressionSettings.options.initialQuality,
              outputFormat: compressionSettings.options.outputFormat || 'png'
            });
  
        const url = window.URL.createObjectURL(compressedBlob);
        const link = document.createElement('a');
        link.href = url;
        const ext = compressionSettings.options?.outputFormat || 'png';
        link.download = `compressed-image-${Date.now()}.${ext}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return;
      }
  
      const zip = new JSZip();
      
      for (const image of imagesToDownload) {
        const response = await fetch(image.url);
        const blob = await response.blob();
        
        const compressedBlob = compressionSettings.type === 'none' ? 
          blob : 
          compressionSettings.type === 'lossless' ? 
            await compressImageLossless(blob, {
              outputFormat: compressionSettings.options?.outputFormat || 'png'
            }) :
            await compressImage(blob, {
              maxSizeMB: compressionSettings.options.maxSizeMB,
              initialQuality: compressionSettings.options.initialQuality,
              outputFormat: compressionSettings.options.outputFormat || 'png'
            });
  
        const ext = compressionSettings.options?.outputFormat || 'png';
        const filename = `compressed-${Date.now()}-${image.prompt.slice(0, 30)}.${ext}`;
        zip.file(filename, compressedBlob);
      }
  
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const zipUrl = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.href = zipUrl;
      link.download = `compressed-images-${Date.now()}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(zipUrl);
  
      setNotification({
        open: true,
        severity: 'success',
        message: `Successfully compressed and downloaded ${imagesToDownload.length} images`
      });
  
    } catch (err) {
      console.error('Download error:', err);
      setNotification({
        open: true,
        severity: 'error',
        message: err.message || 'Failed to download images'
      });
    } finally {
      setLoading(false);
      setCompressionDialogOpen(false);
    }
  };
  
  const handleUploadWithCompression = async (compressionSettings) => {
    try {
      setUploadLoading(true);
      const imagesToUpload = pendingImages;
  
      if (!imagesToUpload?.length) {
        throw new Error('No images selected for upload');
      }
  
      const site = wordPressSites.find(s => s.id === selectedSite);
      if (!site) throw new Error('Selected site not found');
  
      const wpClient = new WordPressClient(site);
      const credentials = await authenticatedRequest(() => 
        apiClient.getWordPressCredentials(selectedSite)
      );
      wpClient.setCredentials(credentials.username, credentials.password);
  
      setUploadProgress({ current: 0, total: imagesToUpload.length });
  
      const uploadResults = [];
      for (let i = 0; i < imagesToUpload.length; i++) {
        const image = imagesToUpload[i];
        try {
          const response = await fetch(image.url);
          const blob = await response.blob();
          
          // Apply compression with the correct options
          const compressedBlob = compressionSettings.type === 'none' ? 
            blob : 
            compressionSettings.type === 'lossless' ? 
              await compressImageLossless(blob, {
                outputFormat: compressionSettings.options?.outputFormat || 'png'
              }) :
              await compressImage(blob, {
                maxSizeMB: compressionSettings.options.maxSizeMB,
                initialQuality: compressionSettings.options.initialQuality,
                outputFormat: compressionSettings.options.outputFormat || 'png'
              });
  
          const ext = compressionSettings.options?.outputFormat || 'png';
          const filename = `compressed-image-${Date.now()}-${image.prompt.slice(0, 30).replace(/[^a-z0-9]/gi, '-')}.${ext}`;
          
          const result = await wpClient.uploadMedia(compressedBlob, filename);
          if (result?.id) {
            uploadResults.push(result);
          }
          setUploadProgress(prev => ({ ...prev, current: i + 1 }));
        } catch (err) {
          console.error(`Failed to upload image: ${image.prompt}`, err);
        }
      }
  
      if (uploadResults.length > 0) {
        setNotification({
          open: true,
          severity: 'success',
          message: `Successfully uploaded ${uploadResults.length} compressed images`
        });
      } else {
        throw new Error('Failed to upload any images');
      }
  
    } catch (err) {
      console.error('Upload error:', err);
      setNotification({
        open: true,
        severity: 'error',
        message: err.message || 'Failed to upload images'
      });
    } finally {
      setUploadLoading(false);
      setUploadProgress({ current: 0, total: 0 });
      setCompressionDialogOpen(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await authenticatedRequest(() =>
        apiClient.generateImage({
          prompt,
          model,
          dimension,
          useCustomPrompt,
          enable_safety_checker: safetyCheckerEnabled
        })
      );

      if (response.image_url) {
        setGeneratedImage(response.image_url);
      } else {
        setError('No image was generated');
      }
    } catch (err) {
      setError(err.response?.data?.detail || err.message || 'Failed to generate image');
    } finally {
      setLoading(false);
    }
  };


  
  return (
    <Box className="max-w-6xl mx-auto p-6 space-y-8">
      {/* Header Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <PageHeader
          title="AI Image Generator"
          description={[
            "Generate high-quality AI images from text descriptions that are perfect for creating featured images, illustrations, or social media post content.",
            "To start, enter a single or multiple prompts (one per line max 50), and choose your preferred model and dimensions."
          ].join(' ')}
          icon={<Sparkles size={24} color="#E04C16" />}
        />
        <Button
          onClick={() => setHelpDialogOpen(true)}
          startIcon={<HelpCircle size={18} />}
          sx={{
            bgcolor: '#E04C16',
            color: 'white',
            borderRadius: '20px',
            px: 3,
            '&:hover': {
              bgcolor: '#c43d0f'
            }
          }}
        >
          How to Use
        </Button>
      </Box>

      {/* Main Content */}
      <div className="space-y-6">
        {/* Prompt Card */}
        <Paper 
          elevation={0} 
          className="border border-gray-200 rounded-xl overflow-hidden bg-white"
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                borderWidth: 2
              }
            }
          }}
        >
          <Box className="p-6">
            <TextField
              fullWidth
              label={useCustomPrompt ? "Direct Image Prompt(s)" : "Image Description(s)"}
              multiline
              rows={4}
              value={prompts}
              onChange={(e) => setPrompts(e.target.value)}
              required
              disabled={loading}
              placeholder={useCustomPrompt ? 
                "Enter exact prompt(s) for the AI model... (one per line for multiple images)" : 
                "Describe the image(s) you want to create... (one per line for multiple images)"
              }
              helperText={useCustomPrompt ? 
                "Your prompts will be used exactly as written" : 
                `Prompts will be enhanced using ${availableModels.find(m => m.value === getAuxiliaryModel())?.label || 'AI'}`
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.02)'
                }
              }}
            />

            <Box className="flex flex-wrap gap-6 mt-4">
              <Box className="flex items-center gap-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useCustomPrompt}
                      onChange={(e) => setUseCustomPrompt(e.target.checked)}
                      disabled={loading}
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        }
                      }}
                    />
                  }
                  label="Use direct prompt"
                />
                <Tooltip title={
                  useCustomPrompt
                    ? "When checked your prompt will be used exactly as written"
                    : "The AI model will enhance your description into an optimal prompt for better results"
                }>
                  <HelpCircle size={16} className="text-gray-400 cursor-help" />
                </Tooltip>
              </Box>

              <Box className="flex items-center gap-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={safetyCheckerEnabled}
                      onChange={(e) => setSafetyCheckerEnabled(e.target.checked)}
                      disabled={loading}
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.main',
                        }
                      }}
                    />
                  }
                  label="Content safety filter"
                />
                <Tooltip title={
                  safetyCheckerEnabled 
                    ? "When checked, inappropriate content will be blocked" 
                    : "Basic content filtering will still be applied"
                }>
                  <HelpCircle size={16} className="text-gray-400 cursor-help" />
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Paper>

        {/* Settings Card */}
        <Paper 
          elevation={0} 
          className="border border-gray-200 rounded-xl overflow-hidden bg-white"
        >
          <Box className="p-6">
           
            
            <ImageControls
              model={model}
              setModel={setModel}
              dimension={dimension}
              setDimension={setDimension}
              loading={loading}
            />

            {/* Error Display */}
            {(error || authError || modelsError) && (
              <Alert 
                severity="error" 
                className="mt-4"
                sx={{
                  borderRadius: 2,
                  backgroundColor: 'rgba(211, 47, 47, 0.05)'
                }}
              >
                {error || authError || modelsError}
              </Alert>
            )}

            {/* Generate Button */}
            <Box className="mt-6 flex justify-center">
              <Button
                variant="contained"
                disabled={loading || !prompts.trim()}
                onClick={handleGenerate}
                className="w-full sm:w-auto sm:min-w-[200px]"
                startIcon={loading ? <CircularProgress size={20} /> : <ImageIcon className="h-5 w-5" />}
                sx={{
                  py: 1.5,
                  px: 4,
                  borderRadius: 2,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }}
              >
                {loading ? 
                  `Generating${generationProgress.total > 1 ? 
                    ` (${generationProgress.current}/${generationProgress.total})` : 
                    '...'
                  }` : 
                  'Generate Image(s)'
                }
              </Button>
            </Box>
          </Box>
        </Paper>

        {/* Images Display */}
        {generatedImages.length > 0 && (
          <Paper className="p-6">
       {generatedImages.length === 1 ? (
  // Single Image Large Preview
  <div className="space-y-4">
    <Typography variant="subtitle1" className="mb-4 font-medium">
      Generated Image
    </Typography>
    {generatedImages[0].status === 'success' ? (
      <>
        <div className="relative w-full">
          <img
            src={generatedImages[0].url}
            alt={generatedImages[0].prompt}
            className="w-full h-auto rounded-lg shadow-lg"
            onClick={() => handleSelectImage(generatedImages[0].id)}
          />
        </div>
                    <div className="flex gap-4 justify-center">
                      {wordPressSites.length > 0 && (
                        <Button
                          variant="contained"
                          startIcon={<Upload />}
                          onClick={() => {
                            setPendingImages([generatedImages[0]]);
                            setUploadProgress({ current: 0, total: 1 });
                            setCompressionAction('upload');
                            setCompressionDialogOpen(true);
                          }}
                        >
                          Upload to WordPress
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        startIcon={<Download />}
                        onClick={() => {
                          setPendingImages([generatedImages[0]]);
                          setCompressionAction('download');
                          setCompressionDialogOpen(true);
                        }}
                      >
                        Download Image
                      </Button>
                    </div>
      </>
    ) : (
      <Box className="p-4 bg-gray-100 rounded-lg">
        <Typography color="error">
          Generation failed: {generatedImages[0].error}
        </Typography>
      </Box>
    )}
  </div>
) : (
              // Multi-Image Grid View
              <>
                <Box className="mb-4 flex justify-between items-center">
                  <Typography variant="subtitle1" className="font-medium">
                    Generated Images ({generatedImages.length})
                  </Typography>
                  <Box className="flex gap-2">
  <Button
    variant="outlined"
    onClick={handleSelectAll}
    disabled={loading}
  >
    {selectedImages.length === generatedImages.length ? 'Deselect All' : 'Select All'}
  </Button>
  <Button
    variant="contained"
    startIcon={<Download />}
    disabled={selectedImages.length === 0 || loading}
    onClick={() => {
      const selectedImagesToDownload = generatedImages.filter(
        img => selectedImages.includes(img.id) && img.status === 'success'
      );
      setPendingImages(selectedImagesToDownload);
      setCompressionAction('download');
      setCompressionDialogOpen(true);
    }}
  >
    Download Selected
  </Button>
  {wordPressSites.length > 0 && (
    <Button
      variant="contained"
      startIcon={<Upload />}
      disabled={selectedImages.length === 0 || loading}
      onClick={() => {
        const selectedImagesToUpload = generatedImages.filter(
          img => selectedImages.includes(img.id) && img.status === 'success'
        );
        setPendingImages(selectedImagesToUpload);
        setUploadProgress({ current: 0, total: selectedImagesToUpload.length }); 
        setCompressionAction('upload');
        setCompressionDialogOpen(true);
      }}
    >
      Upload to WordPress
    </Button>
  )}
</Box>
                </Box>

                <Grid container spacing={2}>
                  {generatedImages.map((image) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
                      <Card 
                        className={`relative ${
                          selectedImages.includes(image.id) ? 'ring-2 ring-blue-500' : ''
                        }`}
                      >
                        {image.status === 'success' ? (
                          <CardMedia
                            component="img"
                            height="200"
                            image={image.url}
                            alt={image.prompt}
                            className="cursor-pointer"
                            onClick={() => handleSelectImage(image.id)}
                          />
                        ) : (
                          <Box className="h-48 flex items-center justify-center bg-gray-100">
                            <Typography color="error">
                              Generation failed
                            </Typography>
                          </Box>
                        )}
                        <CardContent className="p-2">
                          <Typography variant="caption" className="line-clamp-2">
                            {image.prompt}
                          </Typography>
                        </CardContent>
                        <Box
                          className="absolute top-2 right-2 flex gap-1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {image.status === 'success' && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => handleSelectImage(image.id)}
                                className="bg-white hover:bg-gray-100"
                              >
                                {selectedImages.includes(image.id) ? (
                                  <CheckCircle className="h-5 w-5 text-blue-500" />
                                ) : (
                                  <ImageIcon className="h-5 w-5" />
                                )}
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => setPreviewImage(image)}
                                className="bg-white hover:bg-gray-100"
                              >
                                <Maximize className="h-5 w-5" />
                              </IconButton>
                            </>
                          )}
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Paper>
        )}

        {!generatedImages.length && !loading && (
          <Paper className="p-6">
            <Typography variant="subtitle1" className="mb-4 font-medium">
              Preview
            </Typography>
            <div className="border-2 border-dashed border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-900 dark:border-gray-800">
              <div className="h-[512px] flex items-center justify-center text-gray-500">
                <Typography>
                  Generated image(s) will appear here
                </Typography>
              </div>
            </div>
          </Paper>
          
        )}
      </div>
      <Dialog 
        open={uploadDialogOpen} 
        onClose={() => !uploadLoading && setUploadDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload to WordPress</DialogTitle>
        <DialogContent>
  <Box sx={{ pt: 2 }}>
    {uploadError && (
      <Alert severity="error" sx={{ mb: 2 }}>
        {uploadError}
      </Alert>
    )}
    
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Select WordPress Site</InputLabel>
      <Select
        value={selectedSite}
        label="Select WordPress Site"
        onChange={(e) => setSelectedSite(e.target.value)}
        disabled={uploadLoading}
      >
        {wordPressSites.map((site) => (
          <MenuItem key={site.id} value={site.id}>
            {site.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    {wordPressSites.length === 0 && (
      <Alert severity="info" sx={{ mt: 2 }}>
        No WordPress sites connected. Please add a site in your dashboard settings.
      </Alert>
    )}

    {uploadLoading && uploadProgress.total > 0 && (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Upload Progress: {Math.round((uploadProgress.current / uploadProgress.total) * 100)}%
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={(uploadProgress.current / uploadProgress.total) * 100}
            sx={{
              height: 8,
              borderRadius: 4,
              bgcolor: 'grey.200',
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
                bgcolor: 'primary.main'
              }
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption" color="text.secondary">
            Uploading images to WordPress...
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {uploadProgress.current}/{uploadProgress.total} files
          </Typography>
        </Box>
      </Box>
    )}
  </Box>
</DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setUploadDialogOpen(false)} 
            disabled={uploadLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmUpload}
            disabled={uploadLoading || !selectedSite}
            startIcon={uploadLoading && <CircularProgress size={20} />}
          >
            {uploadLoading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
  open={notification.open}
  autoHideDuration={6000}
  onClose={() => setNotification(prev => ({ ...prev, open: false }))}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
  <Alert 
    onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
    severity={notification.severity}
    sx={{ width: '100%' }}
  >
    {notification.message}
  </Alert>
</Snackbar>
<Dialog 
  open={helpDialogOpen}
  onClose={() => setHelpDialogOpen(false)}
  PaperProps={{
    sx: {
      position: 'fixed',
      right: 32,
      top: '50%',
      transform: 'translateY(-50%)',
      m: 0,
      width: 400,
      maxWidth: '90vw',
      maxHeight: '80vh',
      borderRadius: 2
    }
  }}
>
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    p: 2,
    borderBottom: '1px solid',
    borderColor: 'divider'
  }}>
    <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
      How to Use Image Generator
    </DialogTitle>
    <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
      <X size={18} />
    </IconButton>
  </Box>
  <DialogContent>
    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
      Getting Started
    </Typography>
    <Typography variant="body2" sx={{ mb: 4 }}>
      Enter your image descriptions in the text box, with one description per line for multiple images. You can generate up to 50 images at once, making it perfect for batch creation.
    </Typography>

    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
      Writing Good Prompts
    </Typography>
    <Typography variant="body2" sx={{ mb: 4 }}>
      Describe your desired image with specific details about style, mood, and composition. When "Use direct prompt" is unchecked, our AI will enhance your descriptions automatically for better results.
    </Typography>

    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
      Image Models
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Each model has different strengths: FLUX for speed, Recraft for artistic quality, and Ideogram for text integration. Check out the <Link href="https://fal.ai/models?categories=text-to-image" target="_blank" rel="noopener noreferrer">full model documentation</Link> to learn more about each option.
    </Typography>
    <Typography variant="body2" sx={{ mb: 4 }}>
      Consider cost per image when choosing your model. The price is displayed next to each option in the dropdown menu.
    </Typography>

    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
      Image Dimensions
    </Typography>
    <Typography variant="body2" sx={{ mb: 4 }}>
      Square formats work well for general use and social media. Choose landscape or portrait orientations based on where you'll use the image, like website headers or Pinterest posts.
    </Typography>

    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
      Downloading & Uploading
    </Typography>
    <Typography variant="body2" sx={{ mb: 4 }}>
      After generation, you can download images directly to your computer or upload them to WordPress. For WordPress uploads, just click the upload button and select your site - the images will appear in your media library instantly.
    </Typography>

    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
      Content Safety
    </Typography>
    <Typography variant="body2">
      The content safety filter helps ensure generated images are appropriate. You can adjust this setting using the checkbox, but keep in mind that the API still maintains basic content standards.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setHelpDialogOpen(false)}>
      Got it
    </Button>
  </DialogActions>
</Dialog>

<CompressionDialog
  open={compressionDialogOpen}
  onClose={() => setCompressionDialogOpen(false)}
  onConfirm={compressionAction === 'download' ? 
    handleDownloadWithCompression : 
    handleUploadWithCompression
  }
  action={compressionAction}
  wordPressSites={wordPressSites}
  selectedSite={selectedSite}
  setSelectedSite={setSelectedSite}
  uploadLoading={uploadLoading}
  uploadProgress={uploadProgress}  
/>
<Dialog
  open={Boolean(previewImage)}
  onClose={() => setPreviewImage(null)}
  maxWidth="lg"
  fullWidth
>
  <DialogContent sx={{ p: 0, position: 'relative' }}>
    {previewImage && (
      <>
        <IconButton
          onClick={() => setPreviewImage(null)}
          sx={{ position: 'absolute', top: 8, right: 8, bgcolor: 'white' }}
        >
          <X />
        </IconButton>
        <img
          src={previewImage.url}
          alt={previewImage.prompt}
          style={{ width: '100%', height: 'auto' }}
        />
      </>
    )}
  </DialogContent>
</Dialog>
    </Box>
  );
};

export default ImageGenerator;