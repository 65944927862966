import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode || 'light';
  });

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#E04C16',
            light: '#ff6d3f',
            dark: '#a82800',
            contrastText: mode === 'dark' ? '#ffffff' : '#ffffff',
          },
          secondary: {
            main: '#2563eb',
            light: '#3b82f6',
            dark: '#1d4ed8',
            contrastText: mode === 'dark' ? '#ffffff' : '#ffffff',
          },
          background: {
            default: mode === 'light' ? '#f8fafc' : '#000000',
            paper: mode === 'light' ? '#ffffff' : '#121212',
          },
          text: {
            primary: mode === 'light' ? '#0f172a' : '#ffffff',
            secondary: mode === 'light' ? '#475569' : '#cccccc',
          },
        },
        typography: {
          fontFamily: [
            'Inter',
            'Outfit',
            'Manrope',
            '-apple-system',
            'BlinkMacSystemFont',
            'system-ui',
            'sans-serif'
          ].join(','),
        },
        components: {
          MuiTypography: {
            styleOverrides: {
              h6: {
                fontSize: '1.0rem', 
                color: '#E04C16', 
                fontWeight: 700 
              }
            }
          },
          MuiListItemText: {
            styleOverrides: {
              primary: {
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '1rem',
                letterSpacing: '-0.04em'
              },
              secondary: {
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '.875rem',
              }
            }
          },
        
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'light' ? '#ffffff' : '#121212',
                borderBottom: '1px solid',
                borderColor: mode === 'light' ? '#e2e8f0' : '#2d2d2d',
                color: mode === 'light' ? '#0f172a' : '#ffffff',
                '& .MuiTypography-root': {
                  color: '#E04C16'
                }
              }
            }
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: mode === 'light' ? '#f8fafc' : '#000000', // Matches container background
                borderRight: '1px solid',
                borderColor: mode === 'light' ? '#e2e8f0' : '#2d2d2d',
                '& .MuiListItemText-primary': {
                  fontWeight: 600,
                  fontSize: '1.1rem'
                },
                '& .MuiCollapse .MuiListItemText-primary': {
                  fontWeight: 400, // Regular weight for submenu items
                  fontSize: '1rem'
                }
              }
            }
          },
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'light' ? '#ffffff' : '#121212',
                borderColor: mode === 'light' ? '#e2e8f0' : '#2d2d2d'
              }
            }
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'light' ? '#ffffff' : '#121212',
                borderColor: mode === 'light' ? '#e2e8f0' : '#2d2d2d'
              }
            }
          }
        }
      }),
    [mode]
  );

  const toggleTheme = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);