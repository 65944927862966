import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Lock, Bot, Image, Key, Zap, Globe, FileText, HelpCircle,
  Files, ShoppingBag, ListOrdered, Sparkles, Infinity, Star, Pencil, Facebook,
  PenTool, Link, Settings, ListFilter, CheckCircle, Share, Users,
} from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';

const headingStyles = {
  fontFamily: "'Manrope', sans-serif",
};

const bodyStyles = {
  fontFamily: "'Poppins', sans-serif",
};

const FeatureGrid = () => {
  const features = [
    {
      icon: FileText,
      title: "Article Generator",
      description: "Create in-depth, SEO-optimized articles with customizable sections, images, and formatting. Perfect for producing well-organized content."
    },
    {
      icon: Image,
      title: "Image Generator",
      description: "Generate high-quality images using Fal.ai models. Create featured images and section images with customizable styles and dimensions."
    },
    {
      icon: Bot,
      title: "URL Rewriter",
      description: "Transform existing articles into unique content while maintaining your preferred writing style and structure."
    },
    {
      icon: Link,
      title: "Amazon Review Generator",
      description: "Create comprehensive product reviews and roundups with automatic product data fetching and image generation."
    },
    {
      icon: Settings,
      title: "Customizable Settings",
      description: "Control every aspect of content generation from AI models to image styles. Optimize costs by choosing the right models for your needs."
    },
    {
      icon: Sparkles,
      title: "AI Enhancement",
      description: "Automatically enhance image prompts and content structure using advanced AI models for better results."
    },
    {
      icon: ListFilter,
      title: "Prompt Lab",
      description: "Create and save custom prompts for consistent content generation. Test and refine your prompts for optimal results."
    },
    {
      icon: Facebook,
      title: "Facebook Tools",
      description: "Generate engaging social media content and visuals optimized for Facebook engagement."
    },
    {
      icon: PenTool,
      title: "Content Control",
      description: "Fine-tune your content with word count targets, section planning, and detailed content structuring tools."
    }
  ];

  return (
    <section id="features-section" className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">
              Everything You Need for <span className="text-orange-600">Content Creation</span>
            </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="p-6 bg-white rounded-xl  hover:border-orange-200 hover:shadow-lg transition-all"
            >
              <div className="flex items-center gap-3 mb-4">
                <feature.icon className="w-6 h-6 text-orange-600" />
                <h3 className="text-xl font-semibold text-gray-900">{feature.title}</h3>
              </div>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const LandingPage = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    loginWithRedirect({
      prompt: 'login',
      appState: { returnTo: '/articles/standard' }
    });
  };

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  const faqs = [
    {
      q: "What do I need to get started?",
      a: "You'll need a Scripti subscription ($25/month) and API keys for the AI services you want to use (OpenAI, Anthropic, or Fal.ai). Some providers like DeepSeek R1 are currently free through OpenRouter. API availability and pricing subject to change."
    },
    {
      q: "What are the typical API costs for content generation?",
      a: "Example costs as of January 2025 for a 1000-word article: GPT-4o mini ($0.001), Claude 3.5 Haiku ($0.0064), Claude 3.5 Sonnet ($0.024), Grok 2 Vision ($0.016). Perplexity Sonar Small ($0.0005) and Large ($0.0027) have an additional $5 per 1000 requests. Costs subject to change."
    },
    {
      q: "How much do AI-generated images cost?",
      a: "Current Fal.ai costs range from $0.02 per image (FLUX Schnell) to $0.10 per image (Recraft V3). Prices subject to change."
    },
    {
      q: "Do you offer refunds?",
      a: "No, we do not offer refunds for partial monthly usage as you gain immediate access to all features. However, you can cancel your subscription at any time with no additional fees, giving you full flexibility. Please contact us via the Scripti Facebook Group if you have an queries about cancelling your membership also see our Terms & Conditions for details (EU/UK: 14-day cooling off refund period applies)"



    },
    {
      q: "How much content can I create per month?",
      a: "For example with a $10 API budget: GPT-4o mini (~10,000 articles), Perplexity Sonar Small (~20,000 articles + request fees), or Claude 3.5 Sonnet (~400 articles). Image costs are additional, $10 credit with Fal.ai can generate up 10,000 images if using fal-ai/sana model. API Prices are subject to change at any time and are outside of the control of Scripti"
    },
    {
      q: "Which AI models are supported?",
      a: "We support various AI models through direct integration and OpenRouter, including Claude 3.7 (Anthropic), GPT-40-Mini (OpenAI), and other models available via Openrouter. For images, we use Fal.ai models, with a choice of 6 different image models that offer different capabilities and cost effectiveness. Model availability subject to change."
    },
    {
      q: "Do you provide the API keys?",
      a: "No, you'll need to bring your own API keys. This gives you direct control over your API usage and costs."
    },
    {
      q: "What is OpenRouter?",
      a: "OpenRouter is a unified API gateway providing access to various AI models through a single API key. Current offerings include models from Anthropic, Meta, Mistral, Perplexity, and others. Model availability and pricing subject to change."
    },
    {
      q: "Can I control my API costs?",
      a: "Yes! You have complete control over which models you use and how much you spend. You can monitor usage through provider dashboards and switch between models to optimize costs."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 antialiased " style={headingStyles}>
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-lg z-50">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-between items-center h-16">
      <div className="flex items-center gap-2">
        <img 
          src="/Scriptilogo.jpg" 
          alt="Scripti Logo" 
          className="h-14 w-auto"
        />
      </div>
      
      {/* Desktop Links */}
      <div className="hidden md:flex items-center space-x-8">
        <a href="#features-section" className="text-black font-bold hover:text-orange-600 transition-colors">Features</a>
        <a href="#pricing" className="text-black font-bold hover:text-orange-600 transition-colors">Pricing</a>
        <a href="#faq" className="text-black font-bold hover:text-orange-600 transition-colors">FAQ</a>
        <button
          onClick={handleLogin}
          className="px-6 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-colors font-semibold"
        >
          Get Started
        </button>
      </div>

      <button 
        className="md:hidden p-2"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        <svg className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>

    {/* Mobile Links */}
    {isMobileMenuOpen && (
      <div className="md:hidden pb-4 px-2">
        <a href="#features" className="block py-2 text-black font-bold hover:text-orange-600 transition-colors">Features</a>
        <a href="#pricing" className="block py-2 text-black font-bold hover:text-orange-600 transition-colors">Pricing</a>
        <a href="#faq" className="block py-2 text-black font-bold hover:text-orange-600 transition-colors">FAQ</a>
        <button
          onClick={handleLogin}
          className="w-full mt-2 px-4 py-2 bg-orange-600 text-white rounded-lg font-semibold"
        >
          Get Started
        </button>
      </div>
    )}
  </div>
</nav>

      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4 bg-gradient-to-br from-orange-50 via-white to-orange-50">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-extrabold mt-12 text-gray-900 mb-6 leading-tight">
            Supercharge Your Content with
            <br />
            <span className="text-orange-600">Some Help From AI</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Create SEO-optimized content and stunning visuals using advanced AI models at unbeatable value
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={handleLogin}
              className="px-8 py-4 bg-orange-600 text-white rounded-lg hover:bg-orange-700 font-semibold flex items-center gap-2 shadow-lg transition-all"
            >
              <Zap className="w-5 h-5" />
              Start Now
            </button>
          </div>
        </div>
      </section>

      {/* Feature Grid */}
      <FeatureGrid />

      {/* Image Generation Section */}
      <section id="image-generation" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="mb-12">
      <h2 className="text-4xl font-bold text-gray-900 mb-6 text-center">
        <span className="text-orange-600">Image Generation</span> with AI
      </h2>
      <p className="text-gray-600 mb-6">
        Create stunning AI-powered images with Scripti. Add visuals to your content easily. Or generate images on their own in bulk. You control the process from start to finish.
      </p>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {/* Section 1: Content Creation Integration */}
      <div className="bg-gray-50 rounded-xl p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Image className="w-6 h-6 text-orange-600" />
          In Your Content
        </h3>
        <ul className="space-y-3 text-gray-600">
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Generate images while creating articles or posts
          </li>
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Perfect for blogs, reviews, or social media
          </li>
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Seamless part of your workflow
          </li>
        </ul>
      </div>

      {/* Section 2: Standalone Image Generation */}
      <div className="bg-gray-50 rounded-xl p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Globe className="w-6 h-6 text-orange-600" />
          Standalone Images
        </h3>
        <ul className="space-y-3 text-gray-600">
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Dedicated page for single or bulk generation
          </li>
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Download images to your device
          </li>
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Bulk upload directly to WordPress
          </li>
        </ul>
      </div>

      {/* Section 3: Customization & Output */}
      <div className="bg-gray-50 rounded-xl p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Settings className="w-6 h-6 text-orange-600" />
          Customize & Save
        </h3>
        <ul className="space-y-3 text-gray-600">
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Choose Fal.ai models and image sizes
          </li>
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Enhance prompts with AI for better results
          </li>
          <li className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5 text-orange-600" />
            Advanced compression when saving
          </li>
        </ul>
      </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <img 
        src="/Images/lion.png" 
        alt="AI Generated Lion" 
        className="w-full h-80 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow"
      />
      <img 
        src="/Images/manonstreet.png" 
        alt="AI Generated Man on Street" 
        className="w-full h-80 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow"
      />
      <img 
        src="/Images/newyork.png" 
        alt="AI Generated New York Scene" 
        className="w-full h-80 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow"
      />
      <img 
        src="/Images/womanchef.png" 
        alt="AI Generated Woman Chef" 
        className="w-full h-80 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow"
      />
      <img 
        src="/Images/womaninforest.png" 
        alt="AI Generated Woman in Forest" 
        className="w-full h-80 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow"
      />
      <img 
        src="/Images/eagle.png" 
        alt="AI Generated Yoga Pose" 
        className="w-full h-80 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow"
      />
    </div>
  </div>
</section>

      {/* Prompt Lab Section */}
      <section id="prompt-lab" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-4xl font-bold mb-6">
              Prompt Lab
              <span className="text-orange-600"> - Your Prompt Engineering Studio</span>
            </h2>
            <p className="text-lg text-gray-600">
              Take full control of your AI-generated content with our powerful prompt engineering tools.
              Create, test, and perfect prompts that deliver exactly the content you envision.
            </p>
          </div>
      
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {/* Create from Scratch */}
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center gap-3 mb-4">
                <PenTool className="w-6 h-6 text-orange-600" />
                <h3 className="text-xl font-semibold">Create from Scratch</h3>
              </div>
              <p className="text-gray-600 mb-4">
                Build custom prompts from the ground up. Define tone, style, structure, and specific requirements 
                for your content. Perfect for creating unique brand voices and specialized content formats.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Custom tone and style definitions
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Content structure control
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Detailed instruction sets
                </li>
              </ul>
            </div>
      
            {/* Visual Prompt Builder */}
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center gap-3 mb-4">
                <Settings className="w-6 h-6 text-orange-600" />
                <h3 className="text-xl font-semibold">Visual Prompt Builder</h3>
              </div>
              <p className="text-gray-600 mb-4">
                Use our intuitive drag-and-drop interface to construct prompts. Choose from pre-built components 
                for writing style, structure, tone, and more. Perfect for visual thinkers and quick iterations.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Drag-and-drop interface
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Pre-built style components
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Real-time preview
                </li>
              </ul>
            </div>
      
            {/* Starter Templates */}
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center gap-3 mb-4">
                <FileText className="w-6 h-6 text-orange-600" />
                <h3 className="text-xl font-semibold">Starter Templates</h3>
              </div>
              <p className="text-gray-600 mb-4">
                Begin with proven prompt templates designed for specific content types. Customize and refine them 
                to match your needs. Perfect for getting started quickly with optimized results.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Industry-specific templates
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Easy customization
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Proven frameworks
                </li>
              </ul>
            </div>
          </div>
      
          <div className="bg-gradient-to-br from-gray-50 to-white rounded-2xl p-8 shadow-sm">
            <h3 className="text-2xl font-bold mb-6">Save & Re-use Your Perfect Prompts</h3>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <p className="text-gray-600 mb-4">
                  Once you've created the perfect prompt, save it to your library for future use. Your prompts can be:
                </p>
                <ul className="space-y-3">
  <li className="flex items-start gap-3">
    <Star className="w-5 h-5 text-orange-600 mt-1" />
    <span className="text-gray-600">Applied across content generation tools in Scripti</span>
  </li>
  <li className="flex items-start gap-3">
    <Pencil className="w-5 h-5 text-orange-600 mt-1" />
    <span className="text-gray-600">Easily modified and duplicated for different content needs</span>
  </li>
  <li className="flex items-start gap-3">
    <Share className="w-5 h-5 text-orange-600 mt-1" />
    <span className="text-gray-600">Reusable templates that ensure consistent output across all your content</span>
  </li>
</ul>
              </div>
              <div className="space-y-4">
                <div className="flex items-center gap-2 text-gray-600">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                  <span>Easily create prompts for any content type</span>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                  <span>Save and organize multiple prompt templates</span>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                  <span>Duplicate and modify existing prompts</span>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                  <span>Build a library of proven, effective prompts</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Facebook Tools Section */}
      <section id="facebook-tools" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                <span className="text-orange-600">Facebook Tools</span>
              </h2>
              <h3 className="text-2xl font-semibold mb-4">Ideas Generator & Image Editor</h3>
              <p className="text-gray-600 mb-6">
                Boost your Facebook presence with our Ideas Generator for post inspiration and Image Editor for creating engaging visuals tailored for social media.
                </p>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center gap-2">
                  <Facebook className="w-5 h-5 text-orange-600" />
                  Generate post ideas
                </li>
                <li className="flex items-center gap-2">
                  <Image className="w-5 h-5 text-orange-600" />
                  Edit images for Facebook
                </li>
                <li className="flex items-center gap-2">
                  <Sparkles className="w-5 h-5 text-orange-600" />
                  Optimize social content
                </li>
              </ul>
            </div>
            <div className="bg-white h-96 rounded-lg flex items-center justify-center overflow-hidden">
              <img 
                src="/FacebookScripti.png" 
                alt="Facebook Tools Interface" 
                className="w-full h-full object-cover rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20 px-4 bg-orange-50">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">Simple Pricing, Unlimited Creation</h2>
          <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
            <div className="mb-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-gray-900">Pro Plan</h3>
                <span className="bg-orange-100 text-orange-800 px-3 py-1 rounded-full text-sm font-medium">
                  Launch Special
                </span>
              </div>
              <div className="flex items-baseline mb-4">
                {/* <span className="text-xl text-gray-400 line-through mr-3">$25.00</span> */}
                <span className="text-5xl font-bold text-gray-900">$27</span>
                <span className="text-gray-600 ml-2">/month</span>
              </div>
              <p className="text-gray-600 mb-6">Full access to every available feature, bring your own API keys</p>
            </div>
            
            <div className="grid md:grid-cols-2 gap-4 mb-8">
              <div className="space-y-3">
                <div className="flex items-center gap-3">
                  <Infinity className="w-5 h-5 text-orange-600" />
                  <span>Create Unlimited content</span>
                </div>
                <div className="flex items-center gap-3">
                  <Image className="w-5 h-5 text-orange-600" />
                  <span>Unlimited AI image generation</span>
                </div>
                <div className="flex items-center gap-3">
                  <Bot className="w-5 h-5 text-orange-600" />
                  <span>Access to latest AI models such as GPT-4o-Mini, Claude Sonnet 3.7 and Deepseek R1</span>
                </div>
                <div className="flex items-center gap-3">
                  <PenTool className="w-5 h-5 text-orange-600" />
                  <span>Create And Use Your Own Prompts For Full Control Over Content Creation</span>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex items-center gap-3">
                  <Globe className="w-5 h-5 text-orange-600" />
                  <span>Full WordPress integration - Auto Upload Content and Images</span>
                </div>
                <div className="flex items-center gap-3">
                  <Key className="w-5 h-5 text-orange-600" />
                  <span>Bring Your Own API keys</span>
                </div>
                <div className="flex items-center gap-3">
                  <Lock className="w-5 h-5 text-orange-600" />
                  <span>Cancel anytime</span>
                </div>
                <div className="flex items-center gap-3">
                  <HelpCircle className="w-5 h-5 text-orange-600" />
                  <span>Access To A Private Facebook Group</span>
                </div>
              </div>
            </div>
            
            <button
              onClick={handleLogin}
              className="w-full px-8 py-4 bg-orange-600 text-white rounded-lg hover:bg-orange-700 font-semibold transition-all shadow-md"
            >
              Get Started Now
            </button>
          </div>
        </div>
      </section>

      {/* Why Choose Scripti Section */}
{/* Why Choose Scripti Section */}
<section className="py-20 bg-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">
      Why Choose <span className="text-orange-600">Scripti</span>
    </h2>
    
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {/* Each box now has centered headings */}
      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Globe className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Universal Compatibility</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Works seamlessly across all major browsers on both Apple and Windows devices, ensuring you can create content anywhere.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <PenTool className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Complete Creative Control</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Build and save custom prompts to maintain full control over your AI-generated content, ensuring consistent quality and brand voice.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Infinity className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Unlimited Creation</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          No restrictions on content generation - create as many articles, images, and prompts as you need without hitting artificial token limits *.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <FileText className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">WordPress Integration</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Seamlessly publish content and images directly to WordPress with automatic formatting and media management.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Bot className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Latest AI Models</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Access to cutting-edge AI models with rapid integration of new releases, keeping you at the forefront of content technology.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Image className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Premium Images</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Create stunning visuals using advanced AI models, with customizable styles and formats perfect for any content need.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Key className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">API Flexibility</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Use your own API keys for complete cost control and transparency, with support for multiple providers and models.
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl hover:shadow-lg transition-all text-center">
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Users className="w-6 h-6 text-orange-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Premium Support</h3>
        </div>
        <p className="text-gray-700 font-medium leading-relaxed">
          Join our exclusive Facebook community for direct support, tips, and collaboration with fellow content creators.
        </p>
      </div>
    </div>
  </div>
</section>

      {/* FAQ Section */}
      <section id="faq" className="py-20 px-4 bg-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-xl overflow-hidden bg-white"
              >
                <button
                  className="w-full px-6 py-4 text-left hover:bg-gray-50 flex justify-between items-center"
                  onClick={() => toggleFaq(index)}
                >
                  <h3 className="text-lg font-semibold text-gray-900">{faq.q}</h3>
                  <svg
                    className={`w-5 h-5 text-gray-500 transform transition-transform ${
                      expandedFaq === index ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div
                  className={`px-6 py-4 bg-gray-50 ${
                    expandedFaq === index ? 'block' : 'hidden'
                  }`}
                >
                  <p className="text-gray-600">{faq.a}</p>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-12 pt-8 border-t border-gray-200 text-center text-gray-500 text-sm space-y-2">
            <p>* API costs additional. Pricing as of February 2025, subject to change.</p>
            <p>* Content and image quality varies by AI model selected.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-orange-600 py-12 px-4 text-white">
  <div className="max-w-7xl mx-auto">
    <div className="flex flex-col md:flex-row items-center justify-between gap-8">
      <div className="flex items-center gap-2">
        <Sparkles size={28} color="#FFFFFF" />
        <span className="text-2xl font-extrabold">Scripti</span>
      </div>
      <div className="flex gap-6">
        <a href="/privacy.html" className="hover:text-orange-200 transition-colors">
          Privacy Policy
        </a>
        <a href="/terms.html" className="hover:text-orange-200 transition-colors">
          Terms & Conditions
        </a>
      </div>
    </div>
    <div className="mt-8 pt-8 border-t border-orange-500 text-center">
      <p className="text-orange-100">© 2025 Scripti. All rights reserved.</p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default LandingPage;