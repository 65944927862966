import React, { useState, useEffect } from 'react';
import ImageControls from './ImageControls';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  ListSubheader, 
  FormHelperText, 
  Switch,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Pending as PendingIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Eye, FileText, HelpCircle, X } from 'lucide-react';
import { useAIModels } from '../hooks/useAIModels';
import ModelSelector from './ModelSelector';
import PageHeader from './PageHeader';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import aiClient from '../api/clients/AiClient';
import imageClient from '../api/clients/ImageClient';
import WordPressClient from '../utils/WordPressClient';
import { formatContent } from '../utils/contentFormatting';



const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

const UrlRewriter = () => {
  // State Management
  const [urls, setUrls] = useState('');
  const [featuredImage, setFeaturedImage] = useState(false);
  const [featuredImageModel, setFeaturedImageModel] = useState('fal-ai/flux/schnell');
  const [subheadingImages, setSubheadingImages] = useState(false);
  const [subheadingImageModel, setSubheadingImageModel] = useState('fal-ai/flux/schnell');
  const [maxSubheadings, setMaxSubheadings] = useState(5);
  const [dimension, setDimension] = useState('square');

  // Auth Context
  const { authenticatedRequest, error: authError, loading: authLoading } = useAuth();
  
  // Sites and Categories State
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  // Form State
  const [model, setModel] = useState('claude');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  
  // Queue State
  const [queuedUrls, setQueuedUrls] = useState([]);
  const [retryCount, setRetryCount] = useState({});

  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);
  const [useAIEnhancement, setUseAIEnhancement] = useState(false);
  
  // Image Settings State
  const [includeImages, setIncludeImages] = useState(false);
  const [imageFrequency, setImageFrequency] = useState('every');
  const [featuredImageStyle, setFeaturedImageStyle] = useState('realistic_image');
  const [subheadingImageStyle, setSubheadingImageStyle] = useState('realistic_image');
  const [featuredDimension, setFeaturedDimension] = useState('square');
  const [subheadingDimension, setSubheadingDimension] = useState('square');
  const [maxSubheadingImages, setMaxSubheadingImages] = useState(5);
  const [excludeIntro, setExcludeIntro] = useState(false);
  const [excludeConclusion, setExcludeConclusion] = useState(false);
  const [excludeFAQ, setExcludeFAQ] = useState(false);
  const [featuredImagePrompt, setFeaturedImagePrompt] = useState('');

  // Prompt State
  const [prompts, setPrompts] = useState({
    systemTemplates: [],
    userPrompts: []
  });
  const [selectedPrompt, setSelectedPrompt] = useState('');


  // Validation State
  const [urlsTouched, setUrlsTouched] = useState(false);
  const hasUrls = urls.trim().length > 0;
  const showUrlError = urlsTouched && !hasUrls;

  // Preview State
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState(null);

  

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);


  useEffect(() => {
    fetchInitialData();
   
  }, []);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories();
    }
  }, [selectedSite]);

  useEffect(() => {
    const initializeServices = async () => {
      try {
        const keys = await authenticatedRequest(() => apiClient.getApiKeys());
        
        if (!keys.openai && !keys.anthropic && !keys.fal && !keys.openrouter) {
          throw new Error("No API keys configured. Please add required API keys in settings.");
        }

        if (!keys.scrapeowl) {
          throw new Error("No ScrapeOwl API key configured. You need Scrapeowl to use the URLRewriter");
        }
        
        aiClient.setApiKeys(keys);
        aiClient.updateModelConfigs(availableModels);
  
        imageClient.setAvailableModels(availableModels);
        imageClient.setAiClient(aiClient);
        imageClient.setApiKeys(keys);
  
        if (availableModels.length > 0 && !model) {
          const defaultModel = availableModels[0].value;
          setModel(defaultModel);
        }
        
      } catch (error) {
        console.error("Failed to initialize services:", error);
        setError(error.message || 'Failed to initialize services');
      }
    };
  
    if (!authLoading && !modelsLoading && availableModels.length > 0) {
      initializeServices();
    }
  }, [authLoading, modelsLoading, availableModels]);

  const getModelId = (selectedModel) => {
    // Map the UI model selection to the actual model ID
    switch (selectedModel) {
      case 'claude':
        return 'claude-3-sonnet-20240222';
      case 'gpt-4':
        return 'gpt-4o-mini';
      default:
        return selectedModel;
    }
  };

  const shouldExcludeHeading = (headingText) => {
    const text = headingText.toLowerCase();
    if (excludeIntro && (text.includes('introduction') || text.includes('overview'))) return true;
    if (excludeConclusion && (text.includes('conclusion') || text.includes('summary'))) return true;
    if (excludeFAQ && (text.includes('faq') || text.includes('frequently asked'))) return true;
    return false;
  };

 
  const fetchInitialData = async () => {
    try {
      const [sitesData, promptsData] = await Promise.all([
        authenticatedRequest(() => apiClient.getWordPressSites()),
        authenticatedRequest(() => apiClient.getSavedPrompts())
      ]);
  
      setSites(sitesData || []);
      if (sitesData?.length > 0) {
        setSelectedSite(sitesData[0].id);
      }
  
      if (promptsData) {
        setPrompts({
          systemTemplates: promptsData.system_templates || [],
          userPrompts: promptsData.user_prompts || []
        });
      }
    } catch (err) {
      console.error('Failed to load initial data:', err);
      setError('Failed to load initial data');
      setSites([]);
      setPrompts({
        systemTemplates: [],
        userPrompts: []
      });
    }
  };

  const fetchCategories = async () => {
    if (!selectedSite) return;
    try {
      const categoriesData = await authenticatedRequest(() => 
        apiClient.getCategories(selectedSite)
      );
      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to load categories');
    }
  };

  

  
  const generateArticle = async (url, retryAttempt = 0) => {
    try {
      console.log('Starting article generation for URL:', url, 'Attempt:', retryAttempt + 1);
  
      
      const scrapedResult = await apiClient.scrapeUrl(url, selectedPrompt);
      
      
      if (!scrapedResult?.data?.content) {
        throw new Error('Scraped result is missing content');
      }

    
      console.log('=== SCRAPED RESULT ANALYSIS ===');
      console.log('1. Full scraped result:', scrapedResult);
      console.log('2. Success status:', scrapedResult?.success);
      console.log('3. Data structure:', {
        hasTitle: !!scrapedResult?.data?.title,
        hasContent: !!scrapedResult?.data?.content,
        hasProcessedPrompt: !!scrapedResult?.data?.processed_prompt,
        hasOriginalContent: !!scrapedResult?.data?.original_content,
      });   
  
      const site = sites.find(s => s.id === selectedSite);
      if (!site) throw new Error('Selected site not found');
  
      // Initialize WordPress client
      const wpClient = new WordPressClient(site);
      const credentials = await authenticatedRequest(() =>
        apiClient.getWordPressCredentials(selectedSite)
      );
      wpClient.setCredentials(credentials.username, credentials.password);
     
      // Replace the current customPrompt extraction with:
      let promptToUse;
      if (scrapedResult?.data?.processed_prompt) {
        console.log('Using processed prompt from backend');
        promptToUse = scrapedResult.data.processed_prompt;
      } else if (scrapedResult?.data?.content) {
        console.log('Using original content as fallback');
        promptToUse = scrapedResult.data.content;
      } else {
        throw new Error('No valid content found in scraped result');
      }

      // Validate the prompt
      if (typeof promptToUse !== 'string' || !promptToUse.trim()) {
        throw new Error('Invalid or empty prompt received');
      }

      console.log('Final prompt to be used:', {
        length: promptToUse.length,
        preview: promptToUse.substring(0, 200) + '...'
      });

     
      updateUrlStatus(url, 'processing', 'Generating content...');
      const content = await aiClient.generateArticleContent({
        title: scrapedResult.data.title || '',
        modelId: getModelId(model),
        customPrompt: promptToUse,
        metadata: {
          sourceUrl: url,
          originalLength: scrapedResult.data.original_content?.length,
          processedLength: promptToUse.length,
          usedProcessedPrompt: !!scrapedResult.data.processed_prompt
        }
      });
  
      console.log('Generated content:', content);
  
      let featuredMediaId = null;
      let processedContent = formatContent(content, scrapedResult.data.title);  
      // Handle featured image
    // Update this section in the generateArticle function where featured image is handled
if (featuredImage) {
  try {
    updateUrlStatus(url, 'processing', 'Generating featured image...');
    console.log('Generating featured image with settings:', {
      prompt: featuredImagePrompt || scrapedResult.data.title,
      model: featuredImageModel,
      dimension: featuredDimension,
      style: featuredImageStyle,
      aiEnhancement: useAIEnhancement
    });

    const imageData = await imageClient.generateImage({
      prompt: featuredImagePrompt || scrapedResult.data.title,
      model: featuredImageModel,
      dimension: featuredDimension,
      style: featuredImageStyle,
      enhancePrompt: useAIEnhancement,
      context: {
        articleTitle: scrapedResult.data.title
      }
    });

    if (imageData?.image_url) {
      updateUrlStatus(url, 'processing', 'Uploading featured image...');
      
      try {
        // Download the image first
        const imageResponse = await fetch(imageData.image_url);
        if (!imageResponse.ok) {
          throw new Error(`Failed to download image: ${imageResponse.statusText}`);
        }
        
        const imageBlob = await imageResponse.blob();
        
        // Create a safe filename
        const safeTitle = scrapedResult.data.title
          .replace(/[^a-z0-9]/gi, '-')
          .toLowerCase()
          .slice(0, 50);
        const filename = `featured-${safeTitle}-${Date.now()}.png`;

        // Create FormData with explicit filename
        const formData = new FormData();
        formData.append('file', imageBlob, filename);
        formData.append('siteId', selectedSite);

        console.log('Uploading image with FormData:', {
          filename,
          siteId: selectedSite,
          blobSize: imageBlob.size,
          blobType: imageBlob.type
        });

        const uploadResult = await authenticatedRequest(() => 
          apiClient.uploadMedia(formData)
        );

        if (!uploadResult?.media_id) {
          throw new Error('Upload successful but no media ID returned');
        }

        featuredMediaId = uploadResult.media_id;
        console.log('Featured image uploaded successfully:', { 
          mediaId: featuredMediaId,
          uploadResult 
        });
      } catch (uploadError) {
        console.error('Featured image upload error:', uploadError);
        throw new Error(`Failed to upload featured image: ${uploadError.message}`);
      }
    }
  } catch (imageError) {
    console.error('Featured image error:', imageError);
    updateUrlStatus(url, 'processing', 'Featured image failed, continuing...');
  }
}
if (subheadingImages) {
  try {
    console.log('Starting subheading image processing');
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = processedContent;
    const headings = tempContainer.querySelectorAll('h2');
    let imagesGenerated = 0;

    console.log('Processing subheading images:', {
      totalHeadings: headings.length,
      maxImages: maxSubheadingImages
    });

    for (const heading of headings) {
      if (imagesGenerated >= maxSubheadingImages) {
        console.log('Reached maximum number of images');
        break;
      }
      
      if (shouldExcludeHeading(heading.textContent)) {
        console.log('Skipping excluded heading:', heading.textContent);
        continue;
      }

      try {
        updateUrlStatus(url, 'processing', `Generating image for section: ${heading.textContent}`);
        
        const imageData = await imageClient.generateImage({
          prompt: heading.textContent,
          model: subheadingImageModel,
          dimension: subheadingDimension,
          style: subheadingImageStyle,
          enhancePrompt: useAIEnhancement,
          context: {
            articleTitle: scrapedResult.data.title,
            sectionTitle: heading.textContent
          }
        });

        if (imageData?.image_url) {
          updateUrlStatus(url, 'processing', `Uploading image for: ${heading.textContent}`);
          
          try {
            // Download the image
            const imageResponse = await fetch(imageData.image_url);
            if (!imageResponse.ok) {
              throw new Error(`Failed to download image: ${imageResponse.statusText}`);
            }
            
            const imageBlob = await imageResponse.blob();
            
            // Create safe filename from heading
            const safeHeading = heading.textContent
              .replace(/[^a-z0-9]/gi, '-')
              .toLowerCase()
              .slice(0, 50);
            const filename = `subheading-${safeHeading}-${Date.now()}.png`;

            const formData = new FormData();
            formData.append('file', imageBlob, filename);
            formData.append('siteId', selectedSite);

            console.log('Uploading subheading image:', {
              filename,
              siteId: selectedSite,
              blobSize: imageBlob.size,
              blobType: imageBlob.type,
              heading: heading.textContent
            });

            // Use apiClient.uploadMedia instead of wpClient.uploadMedia
            const uploadResult = await authenticatedRequest(() => 
              apiClient.uploadMedia(formData)
            );

            if (!uploadResult?.media_id || !uploadResult?.url) {
              throw new Error('Upload successful but missing required data');
            }

            // Create the image HTML
            const imgHtml = `
              <figure class="wp-block-image size-full">
                <img src="${uploadResult.url}" 
                     alt="${heading.textContent}" 
                     class="wp-image-${uploadResult.media_id}"
                     style="max-width: 100%; height: auto; margin: 20px 0;"
                />
                <figcaption>${heading.textContent}</figcaption>
              </figure>
            `;

            // Insert after the heading
            heading.insertAdjacentHTML('afterend', imgHtml);
            imagesGenerated++;

            console.log(`Successfully added image for section: ${heading.textContent}`, {
              imageId: uploadResult.media_id,
              imageUrl: uploadResult.url
            });

          } catch (uploadError) {
            console.error(`Failed to upload image for "${heading.textContent}":`, uploadError);
            updateUrlStatus(
              url,
              'processing',
              `Failed to upload image for "${heading.textContent}": ${uploadError.message}`
            );
          }
        }
      } catch (error) {
        console.error(`Failed to process image for heading "${heading.textContent}":`, error);
        updateUrlStatus(
          url,
          'processing',
          `Failed to generate image for "${heading.textContent}", continuing...`
        );
      }
    }

    // Update the processed content
    processedContent = tempContainer.innerHTML;
    console.log('Completed subheading images:', {
      imagesGenerated,
      contentLength: processedContent.length
    });

  } catch (error) {
    console.error('Subheading images error:', error);
    updateUrlStatus(url, 'processing', 'Subheading images failed, continuing...');
  }
}

  
      // Create WordPress post
      updateUrlStatus(url, 'processing', 'Creating WordPress post...');
      console.log('Creating WordPress post:', {
        title: scrapedResult.data.title,
        contentLength: processedContent.length,
        featuredMediaId,
        category
      });
  
      const post = await wpClient.createPost({
        title: scrapedResult.data.title,
        content: processedContent,
        categoryId: category,
        featuredMediaId,
        status: 'draft'
      });
  
      console.log('Post created successfully:', {
        postId: post.id,
        status: post.status
      });
  
      updateUrlStatus(url, 'completed', `Created successfully (Post ID: ${post.id})`);
      setRetryCount(prev => ({ ...prev, [url]: 0 }));
  
    } catch (err) {
      console.error('Error in generateArticle:', {
        url,
        retryAttempt,
        error: err.message,
        stack: err.stack
      });
  
      if (!err.message.includes('Content access failed')) {
        await handleGenerationError(err, url, retryAttempt);
      } else {
        updateUrlStatus(url, 'error', err.message);
      }
    }
  };


  const handleGenerationError = async (err, url, retryAttempt) => {
    if (retryAttempt < MAX_RETRIES && isRetryableError(err)) {
      updateUrlStatus(url, 'retrying', `Attempt ${retryAttempt + 1}/${MAX_RETRIES}...`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, retryAttempt)));
      return generateArticle(url, retryAttempt + 1);
    }

    setRetryCount(prev => ({ ...prev, [url]: (prev[url] || 0) + 1 }));
    updateUrlStatus(url, 'error', err.message || 'Failed to process URL');
  };

  const isRetryableError = (error) => {
    const message = error.message.toLowerCase();
    return (
      message.includes('rate limit') ||
      message.includes('timeout') ||
      message.includes('too many requests') ||
      message.includes('service unavailable')
    );
  };

  const initializeQueue = (urlList) => {
    const newQueue = urlList.map(url => ({
      url,
      status: 'queued',
      message: 'Waiting to start...'
    }));
    setQueuedUrls(newQueue);
  };

  const updateUrlStatus = (url, status, message) => {
    setQueuedUrls(prev =>
      prev.map(item =>
        item.url === url
          ? { ...item, status, message }
          : item
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRetryCount({});
    setIsGenerating(true);

    const urlList = urls.split('\n')
      .map(url => url.trim())
      .filter(url => url && url.startsWith('http'));
    
    if (urlList.length === 0) {
      setError('Please enter at least one valid URL');
      setLoading(false);
      setIsGenerating(false);
      return;
    }

    if (urlList.length > 25) {
      setError('Maximum 25 URLs allowed');
      setLoading(false);
      setIsGenerating(false);
      return;
    }

    initializeQueue(urlList);

    try {
      for (const url of urlList) {
        await generateArticle(url);
      }
    } catch (error) {
      console.error('Error processing URLs:', error);
    } finally {
      setLoading(false);
      setIsGenerating(false);
    }
  };

  const handlePreviewPrompt = (promptId) => {
    const prompt = [
      ...prompts.systemTemplates,
      ...prompts.userPrompts
    ].find(p => p.id === promptId);
    
    if (prompt) {
      setPreviewPrompt(prompt);
      setPreviewOpen(true);
    }
  };

  const getStatusIcon = (item) => {
    switch (item.status) {
      case 'completed':
        return <CheckCircleIcon color="success" />;
      case 'error':
        return <ErrorIcon color="error" />;
      case 'processing':
      case 'retrying':
        return <CircularProgress size={24} />;
      default:
        return <PendingIcon color="disabled" />;
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
     
    {/* Page Header with Help Button */}
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      mb: 4 
    }}>
      <PageHeader 
        category="Content Creation"
        title="URL Rewriter" 
        icon={<FileText size={24} color="#E04C16" />} 
        description="Transform existing articles into fresh, unique content by leveraging online sources for research, facts and inspiration. Perfect for producing specific research based high-quality, SEO-optimized content. (Note: Requires a ScrapeOwl API key for web content access)"
      />
      <Button
        onClick={() => setHelpDialogOpen(true)}
        startIcon={<HelpCircle size={18} />}
        sx={{
          bgcolor: '#E04C16',
          color: 'white',
          borderRadius: '20px',
          px: 3,
          '&:hover': {
            bgcolor: '#c43d0f'
          }
        }}
      >
        How to Use
      </Button>
    </Box>

    {/* Help Dialog */}
    <Dialog 
      open={helpDialogOpen}
      onClose={() => setHelpDialogOpen(false)}
      PaperProps={{
        sx: {
          position: 'fixed',
          right: 32,
          top: '50%',
          transform: 'translateY(-50%)',
          m: 0,
          width: 400,
          maxWidth: '90vw',
          maxHeight: '80vh',
          borderRadius: 2
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
          How to Use URL Rewriter
        </DialogTitle>
        <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
          <X size={18} />
        </IconButton>
      </Box>
      <DialogContent>
  {/* Welcome */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Welcome to URL Rewriter
  </Typography>
  <Typography variant="body2" sx={{ mb: 4, lineHeight: 1.6 }}>
    Welcome to the URL Rewriter! This tool transforms existing web articles into fresh, unique content tailored to your preferred style—perfect for refreshing old posts or creating new spins on blog topics. To use this page, you’ll need an active ScrapeOwl subscription and a valid API key saved in your app settings (under Settings - API Keys). Before starting, ensure you have the rights or permissions to reference and rewrite content from your chosen sources.
  </Typography>

  {/* Getting Started */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Getting Started
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Set up your rewriting project with these steps:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Select a WordPress site and category:</strong> Choose your target site from the "WordPress Site" dropdown and a category (e.g., "Lifestyle") from the "Category" dropdown. Your rewritten articles will save here as drafts.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Pick a writing style template:</strong> From the "Writing Style Template" dropdown, select a template that matches your desired tone (e.g., "Casual Blog Post" or "Formal Guide"). Click the eye icon to preview it. This uses the sidebar’s auxiliary AI model for consistency.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Enter source URLs:</strong> In the URL input box, type each article URL on a new line (e.g., "https://example.com/coffee-guide"). These are the pages you want to rewrite—up to 25 at once.
    </li>
  </Typography>

  {/* Customizing Your Content */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Customizing Your Content
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Tailor your rewritten articles with these options:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
  <li style={{ marginBottom: '12px' }}>
      <strong>Pick an AI model:</strong> Choose a model from the "AI MODEL" selector to generate text. Models vary in quality—test different ones to match your review style (e.g., detailed or concise).
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Add a featured image:</strong> Enable the "Include Featured Image" option. Provide a custom prompt (e.g., "Freshly brewed coffee in a rustic cafe") or leave it blank for the AI to generate one using the article title and the sidebar’s auxiliary AI model. Expand the settings to pick an image model—cheaper models are faster but lower quality, while premium ones deliver crisp visuals.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Include inline images:</strong> Turn on image generation for subheadings by clicking the image icon next to each section in the expanded view (if available). Add a specific prompt (e.g., "Pour-over coffee process") or let the AI create one. Customize style and model quality in the expanded settings.
    </li>
  </Typography>

  {/* Processing Your URLs */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Processing Your URLs
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Generate and monitor your rewritten content:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Start rewriting:</strong> Click "Generate" (or similar) to process your URLs. The tool uses ScrapeOwl to fetch content, then rewrites it sequentially—up to 25 URLs per batch.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Track progress:</strong> Watch the "URL Queue" panel on the right for real-time updates. Each URL’s status (e.g., "Processing," "Completed," "Skipped") will appear as it’s handled.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Handle skips:</strong> If a site blocks access (e.g., via robots.txt), that URL will be skipped—check the queue for details and adjust your list if needed.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Review and save:</strong> Completed articles save as WordPress drafts. Log into your dashboard to edit or publish them.
    </li>
  </Typography>

  {/* Best Practices */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Best Practices for Success
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Get the most out of URL Rewriter with these tips:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Verify ScrapeOwl setup:</strong> Ensure your ScrapeOwl subscription is active and API key is saved in Settings - API Keys —without it, this page won’t work.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Use accessible URLs:</strong> Pick publicly available articles (e.g., "https://example.com/tech-tips") to avoid skips due to restricted access.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Choose a strong auxiliary AI model:</strong> The sidebar’s auxiliary AI model enhances writing style and image prompts—select a capable one for polished output.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Optimize image quality:</strong> For featured and inline images, use higher-quality models for professional visuals and cheaper ones for quick tests—adjust in the expanded settings.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Respect content rights:</strong> Only rewrite content you have permission to use, adhering to copyright laws and ownership rules.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Monitor the queue:</strong> Keep an eye on the "URL Queue" to spot issues early and ensure all articles process smoothly.
    </li>
  </Typography>
</DialogContent>
    </Dialog>
      
      {/* Main content */}
      <Grid container spacing={3}>
        {/* Form controls */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Site selection */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>WordPress Site</InputLabel>
                    <Select
                      value={selectedSite}
                      label="WordPress Site"
                      onChange={(e) => setSelectedSite(e.target.value)}
                      disabled={loading}
                    >
                      {sites.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Category selection */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={category}
                      label="Category"
                      onChange={(e) => setCategory(e.target.value)}
                      disabled={loading || !selectedSite}
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Prompt template selection */}
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FormControl fullWidth required>
  <InputLabel>Writing Style Template</InputLabel>
  <Select
    value={selectedPrompt}
    label="Writing Style Template"
    onChange={(e) => setSelectedPrompt(e.target.value)}
    disabled={loading}
  >
    {/* System Templates Section */}
    {prompts.systemTemplates.length > 0 && (
      <ListSubheader>Default Prompts</ListSubheader>
    )}
    {prompts.systemTemplates.map((prompt) => (
      <MenuItem key={prompt.id} value={prompt.id}>
        <Box>
          <Typography variant="subtitle2">{prompt.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {prompt.settings?.description || 'System template'}
          </Typography>
        </Box>
      </MenuItem>
    ))}

    {/* User Templates Section */}
    {prompts.userPrompts.length > 0 && (
      <ListSubheader>My Saved Prompts</ListSubheader>
    )}
    {prompts.userPrompts.map((prompt) => (
      <MenuItem key={prompt.id} value={prompt.id}>
        {prompt.name}
      </MenuItem>
    ))}

    {/* Show message if no prompts available */}
    {!prompts.systemTemplates.length && !prompts.userPrompts.length && (
      <MenuItem disabled>
        No templates available
      </MenuItem>
    )}
  </Select>
</FormControl>
                    {selectedPrompt && (
                      <IconButton 
                        onClick={() => handlePreviewPrompt(selectedPrompt)}
                        sx={{ ml: 1 }}
                      >
                        <Eye />
                      </IconButton>
                    )}
                  </Box>
                </Grid>

                {/* URLs input */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="URLs (one per line, max 25)"
                    multiline
                    rows={5}
                    value={urls}
                    onChange={(e) => setUrls(e.target.value)}
                    onBlur={() => setUrlsTouched(true)}
                    required
                    disabled={loading}
                    placeholder="Enter URLs to rewrite, one per line"
                    error={showUrlError}
                    helperText={showUrlError ? "At least one valid URL is required" : ""}
                  />
                </Grid>

                {/* AI Model selection */}
                {/* Replace the existing AI Model FormControl with */}
<Grid item xs={12}>
  <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
    <Box sx={{ flexGrow: 1 }}>
      <ModelSelector
        value={model}
        onChange={(e) => setModel(e.target.value)}
        required
        tooltip="Choose the AI model that will generate your content. Different models have different strengths and capabilities."
        label="AI Model"
        disabled={loading || modelsLoading}
        showDescription={true}
      />
      {modelsError && (
        <FormHelperText error>
          {modelsError}
        </FormHelperText>
      )}
    </Box>
  </Box>
</Grid>

{/* Add AI Enhancement Toggle */}
<Grid item xs={12}>
  <Box sx={{ bgcolor: 'background.default', borderRadius: 1, mb: 3 }}>
    <FormControlLabel
      control={
        <Switch
          checked={useAIEnhancement}
          onChange={(e) => setUseAIEnhancement(e.target.checked)}
          disabled={loading}
        />
      }
      label="Enhance Image Prompts with AI"
    />
    <FormHelperText>
      AI will automatically enhance image prompts for better results
    </FormHelperText>
  </Box>
</Grid>

               {/* Replace the existing ImageControls section with */}
<Grid item xs={12}>
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    {/* Featured Image Section */}
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={featuredImage}
            onChange={(e) => setFeaturedImage(e.target.checked)}
            disabled={loading}
          />
        }
        label="Generate Featured Image"
      />
      
      {featuredImage && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Featured Image Prompt (optional)"
                placeholder="Leave blank to let AI generate based on article title and content"
                value={featuredImagePrompt}
                onChange={(e) => setFeaturedImagePrompt(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageControls
                model={featuredImageModel}
                setModel={setFeaturedImageModel}
                style={featuredImageStyle}
                setStyle={setFeaturedImageStyle}
                dimension={featuredDimension}
                setDimension={setFeaturedDimension}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>

    {/* Subheading Images Section */}
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={subheadingImages}
            onChange={(e) => setSubheadingImages(e.target.checked)}
            disabled={loading}
          />
        }
        label="Generate Subheading Images"
      />

      {subheadingImages && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Configure settings for subheading images. A new image will be generated for each subheading.
              </Typography>
            </Grid>
            
            {/* Image Generation Controls */}
            <Grid item xs={12}>
              <ImageControls
                model={subheadingImageModel}
                setModel={setSubheadingImageModel}
                style={subheadingImageStyle}
                setStyle={setSubheadingImageStyle}
                dimension={subheadingDimension}
                setDimension={setSubheadingDimension}
                loading={loading}
              />
            </Grid>

            {/* Maximum Images Control */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximum Subheading Images"
                value={maxSubheadingImages}
                onChange={(e) => {
                  const inputValue = e.target.value === '' ? '' : parseInt(e.target.value);
                  if (inputValue === '') {
                    setMaxSubheadingImages('');
                  } else {
                    const limitedValue = Math.min(Math.max(0, inputValue), 10);
                    setMaxSubheadingImages(limitedValue);
                  }
                }}
                InputProps={{ 
                  inputProps: { 
                    min: 0, 
                    max: 10 
                  } 
                }}
                helperText={`You can generate 0 to 10 images`}
              />
            </Grid>

            {/* Section Exclusion Controls */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Exclude Images from Sections
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeIntro}
                      onChange={(e) => setExcludeIntro(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="Skip Introduction Section"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeConclusion}
                      onChange={(e) => setExcludeConclusion(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="Skip Conclusion Section"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeFAQ}
                      onChange={(e) => setExcludeFAQ(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="Skip FAQ Section"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  </Box>
</Grid>
               

                {/* Error display */}
                <Grid item xs={12}>
                  {error && (
                    <Alert severity="error">
                      {typeof error === 'string' ? error : 'An error occurred'}
                    </Alert>
                  )}
                </Grid>

                {/* Submit button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!selectedSite || !category || !selectedPrompt || !hasUrls || loading}
                    fullWidth
                  >
                    {loading ? 'Processing URLs...' : 'Process URLs'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        {/* URL Queue */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              URL Queue
            </Typography>
            {queuedUrls.length === 0 ? (
              <Box sx={{ textAlign: 'center', py: 8 }}>
                <Typography color="textSecondary">
                  No URLs in queue. Add URLs and click "Process URLs" to begin.
                </Typography>
              </Box>
            ) : (
              <List>
                {queuedUrls.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      '&:last-child': {
                        borderBottom: 'none'
                      },
                      py: 2
                    }}
                  >
                    <ListItemIcon>
                      {getStatusIcon(item)}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.url}
                      secondary={item.message}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        sx: { wordBreak: 'break-all' }
                      }}
                      secondaryTypographyProps={{
                        fontSize: '0.875rem'
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {previewPrompt?.name}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {previewPrompt?.prompt_text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UrlRewriter;