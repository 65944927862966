import React, { useState, useEffect } from 'react';
import { config } from '../config';
import ImageControls from './ImageControls';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  FormHelperText,
  Switch,
  Divider,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Pending as PendingIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Divide, Eye, HelpCircle, X } from 'lucide-react';
import { FileText } from 'lucide-react';
import PageHeader from './PageHeader';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import aiClient, { AI_MODELS } from '../api/clients/AiClient';
import imageClient, { IMAGE_MODELS } from '../api/clients/ImageClient';
import WordPressClient from '../utils/WordPressClient';
import { formatContent } from '../utils/contentFormatting';
import { useAIModels } from '../hooks/useAIModels';
import ModelSelector from './ModelSelector';
import DownloadHandler from '../components/DownloadHandler';



const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

const ArticleGenerator = ({ onGeneratingChange }) => {
  
  // Sites and Categories State
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  // Form State
  const [titles, setTitles] = useState('');
  const [model, setModel] = useState('claude');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  
  // Queue State
  const [queuedArticles, setQueuedArticles] = useState([]);
  const [retryCount, setRetryCount] = useState({});
  
  // Image settings
  const [includeImages, setIncludeImages] = useState(false);
  const [featuredImage, setFeaturedImage] = useState(false);
  const [featuredImageModel, setFeaturedImageModel] = useState('fal-ai/flux/schnell');
  const [featuredImageStyle, setFeaturedImageStyle] = useState('realistic_image'); 
  const [subheadingImages, setSubheadingImages] = useState(false);
  const [subheadingImageModel, setSubheadingImageModel] = useState('fal-ai/flux/schnell');
  const [featuredDimension, setFeaturedDimension] = useState('square');
  const [subheadingDimension, setSubheadingDimension] = useState('square');
  const [subheadingImageStyle, setSubheadingImageStyle] = useState('realistic_image');
  const [maxSubheadings, setMaxSubheadings] = useState(5);
  const [imageFrequency, setImageFrequency] = useState('every');
  const [featuredImagePrompt, setFeaturedImagePrompt] = useState('');
  const [useAIEnhancement, setUseAIEnhancement] = useState(false);

  // Navigation Protection State
  
  const [navigationTarget, setNavigationTarget] = useState(null);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [navDialogOpen, setNavDialogOpen] = useState(false);




  // Prompt State
  const [prompts, setPrompts] = useState({
    systemTemplates: [],
    userPrompts: []
  });
  const [selectedPrompt, setSelectedPrompt] = useState('');
  

  // Validation State
  const [titlesTouched, setTitlesTouched] = useState(false);
  const hasTitles = titles.trim().length > 0;
  const showTitleError = titlesTouched && !hasTitles;

  // Preview State
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState(null);
  const [openPromptDialog, setOpenPromptDialog] = useState(false);
  const [tempPrompt, setTempPrompt] = useState('');
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const [excludeIntro, setExcludeIntro] = useState(false);
  const [excludeConclusion, setExcludeConclusion] = useState(false);
  const [excludeFAQ, setExcludeFAQ] = useState(false);
  const [maxSubheadingImages, setMaxSubheadingImages] = useState(5);

 

  // Auth Context
  const { authenticatedRequest, error: authError, loading: authLoading, isAuthenticated } = useAuth();
  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);

  const isQueueCompleted = (articles) => {
    return articles.length > 0 && articles.every(article => article.status === 'completed');
  };

  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };

  const NavigationDialog = () => (
    <Dialog
      open={navDialogOpen}
      onClose={() => setNavDialogOpen(false)}
      aria-labelledby="nav-dialog-title"
      aria-describedby="nav-dialog-description"
    >
      <DialogTitle id="nav-dialog-title">
        Leave Page?
      </DialogTitle>
      <DialogContent>
        <Typography id="nav-dialog-description">
          Articles are still being generated. If you leave now, all progress will be lost and remaining articles will not be generated.
        </Typography>
        <Typography color="error" sx={{ mt: 2 }}>
          Are you sure you want to leave this page?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNavDialogOpen(false)}>
          Stay on Page
        </Button>
        <Button
          onClick={() => {
            setIsGenerating(false);
            setNavDialogOpen(false);
            window.history.back();
          }}
          color="error"
          variant="contained"
        >
          Leave Page
        </Button>
      </DialogActions>
    </Dialog>
  );

   

  
  useEffect(() => {
    if (!authLoading) {
      console.log('Starting initialization...');
      
      const initialize = async () => {
        try {
          // Initialize clients
          await initializeClients();
          
          // Load initial data
          const [sitesData, promptsData] = await Promise.all([
            authenticatedRequest(() => apiClient.getWordPressSites()),
            authenticatedRequest(() => apiClient.getSavedPrompts())
          ]);
  
          // Set initial data
          setSites(sitesData);
          // if (sitesData.length > 0) {
          //   setSelectedSite(sitesData[0].id);
          // }
  
          if (promptsData) {
            setPrompts({
              systemTemplates: promptsData.system_templates || [],
              userPrompts: promptsData.user_prompts || []
            });
          }
        } catch (error) {
          console.error('Initialization error:', error);
          setError('Failed to initialize: ' + error.message);
        }
      };
  
      initialize();
    }
  }, [authLoading]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isGenerating) {
        // Prevent default navigation
        event.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        // Show our custom dialog
        setNavDialogOpen(true);
      }
    };

    if (isGenerating) {
      window.history.pushState(null, '', window.location.pathname);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isGenerating]);

  useEffect(() => {
    if (selectedSite && selectedSite !== 'local') {
      fetchCategories();
    }
  }, [selectedSite]);

  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isGenerating) {
        // Show custom dialog for refresh
        e.preventDefault();
        setNavDialogOpen(true);
        // The returnValue is still needed for some browsers
        e.returnValue = 'Articles are still being generated. Are you sure you want to leave?';
        return e.returnValue;
      }
    };
  
    const handleUnload = (e) => {
      if (isGenerating) {
        // Show custom dialog for tab/window close
        e.preventDefault();
        setNavDialogOpen(true);
        return false;
      }
    };
  
    const handlePopState = (event) => {
      if (isGenerating) {
        // Prevent default navigation
        event.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        // Show custom dialog
        setNavDialogOpen(true);
      }
    };
  
    // Add all event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    window.addEventListener('popstate', handlePopState);
    
    if (isGenerating) {
      window.history.pushState(null, '', window.location.pathname);
    }
  
    onGeneratingChange?.(isGenerating);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('popstate', handlePopState);
      onGeneratingChange?.(false);
    };
  }, [isGenerating, onGeneratingChange]);

  useEffect(() => {
    if (queuedArticles.length > 0 && queuedArticles.every(article => 
      article.status === 'completed' || article.status === 'error'
    )) {
      // All articles are either completed or errored
      setIsGenerating(false);
      setLoading(false);
    }
  }, [queuedArticles]);
  

  useEffect(() => {
    if (availableModels.length > 0) {
      try {
        const initializeAI = async () => {
          const keys = await authenticatedRequest(() => apiClient.getApiKeys());
          
          // Initialize AI client
          aiClient.setApiKeys(keys);
          aiClient.updateModelConfigs(availableModels);
          
          // Initialize image client
          imageClient.setAvailableModels(availableModels);
          imageClient.setAiClient(aiClient);
          imageClient.setApiKeys(keys);
          
          // Set initial model if none selected
          if (!model && availableModels.length > 0) {
            const defaultModel = availableModels[0].value;
            console.log('Setting default model:', defaultModel);
            setModel(defaultModel);
          }
        };
  
        initializeAI();
      } catch (error) {
        console.error('Error initializing AI services:', error);
        setError('Failed to initialize AI services');
      }
    }
  }, [availableModels, authenticatedRequest, model]);
  

  const LeaveConfirmationDialog = ({ open, onClose, onConfirm }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Leave Page?</DialogTitle>
      <DialogContent>
        <Typography>
          Articles are still being generated. If you leave now, all progress will be lost and remaining articles will not be generated.
        </Typography>
        <Typography color="error" sx={{ mt: 2 }}>
          Are you sure you want to leave this page?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Stay on Page
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Leave Page
        </Button>
      </DialogActions>
    </Dialog>
  );

  const shouldExcludeHeading = (headingText) => {
    const text = headingText.toLowerCase();
    if (excludeIntro && (text.includes('introduction') || text.includes('overview'))) return true;
    if (excludeConclusion && (text.includes('conclusion') || text.includes('summary'))) return true;
    if (excludeFAQ && (text.includes('faq') || text.includes('frequently asked'))) return true;
    return false;
  };

  
  const handleNavigation = (target) => {
    if (isGenerating) {
      setNavigationTarget(target);
      setLeaveDialogOpen(true);
      return false;
    }
    return true;
  };

  const initializeClients = async () => {
    try {
        console.log("Starting client initialization...");
        const keys = await authenticatedRequest(() => apiClient.getApiKeys());
        console.log("Keys received:", {
            openai: keys.openai ? "present" : "missing",
            anthropic: keys.anthropic ? "present" : "missing",
            fal: keys.fal ? "present" : "missing"
        });
        
        if (!keys.openai && !keys.anthropic && !keys.openrouter) {
            throw new Error("No AI service keys configured. Please add OpenAI or Anthropic API key in settings.");
        }

        aiClient.setApiKeys(keys);
        imageClient.setApiKeys(keys);
        console.log("Clients initialized successfully");
    } catch (error) {
        console.error("Failed to initialize AI services:", error);
        setError(error.message || 'Failed to initialize AI services. Please check your API keys.');
    }
};

const fetchInitialData = async () => {
  try {
    const [sitesData, promptsData] = await Promise.all([
      authenticatedRequest(() => apiClient.getWordPressSites()),
      authenticatedRequest(() => apiClient.getSavedPrompts())
    ]);

    setSites(sitesData);

    if (promptsData) {
      setPrompts({
        systemTemplates: promptsData.system_templates || [],
        userPrompts: promptsData.user_prompts || []
      });
    }

    return sitesData; 
  } catch (err) {
    setError('Failed to load initial data');
    console.error('Error in fetchInitialData:', err);
    setSites([]);
    setPrompts({
      systemTemplates: [],
      userPrompts: []
    });
    return [];
  }
};

const handlePreviewPrompt = (promptId) => {
  const prompt = [
    ...prompts.systemTemplates,
    ...prompts.userPrompts
  ].find(p => p.id === promptId);
  
  if (prompt) {
    setPreviewPrompt(prompt);
    setPreviewOpen(true);
  }
};


  const fetchCategories = async () => {
    if (!selectedSite) return;
    
    try {
      const categoriesData = await authenticatedRequest(() => 
        apiClient.getCategories(selectedSite)
      );
      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to load categories');
      console.error('Error fetching categories:', err);
    }
  };

  const initializeQueue = (titleList) => {
    const newQueue = titleList.map(title => ({
      title,
      status: 'queued',
      message: 'Waiting to start...'
    }));
    setQueuedArticles(newQueue);
  };

  const updateArticleStatus = (title, status, message, data = {}) => {
    console.log('Updating article status:', { title, status, message, data }); // Add this for debugging
    setQueuedArticles(prev =>
      prev.map(article =>
        article.title === title
          ? { 
              ...article, 
              status, 
              message,
              ...data, 
              content: data.content,
              generatedContent: data.generatedContent
            }
          : article
      )
    );
  };

  const getModelId = (selectedModel) => {
    // Map the UI model selection to the actual model ID
    switch (selectedModel) {
      case 'claude':
        return 'claude-3-sonnet-20240222';
      case 'gpt-4':
        return 'gpt-4o-mini';
      default:
        return selectedModel; // For OpenRouter models, use the value directly
    }
  };

  const handleGenerationError = async (err, title, retryAttempt) => {
    console.error('Error generating article:', err);
    const currentRetries = retryCount[title] || 0;
  
    if (retryAttempt < MAX_RETRIES && isRetryableError(err)) {
      updateArticleStatus(
        title,
        'retrying',
        `Attempt ${retryAttempt + 1}/${MAX_RETRIES}... (${err.message})`
      );
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, retryAttempt)));
      return generateArticle(title, retryAttempt + 1);
    }
  
    setRetryCount(prev => ({ ...prev, [title]: currentRetries + 1 }));
    updateArticleStatus(title, 'error', err.message || 'Failed to create article');
  };


  const generateArticle = async (title, retryAttempt = 0, wpClient) => {
    try {
      updateArticleStatus(title, 'processing', 'Initializing...');
       
      const prompt = [...prompts.systemTemplates, ...prompts.userPrompts].find(p => p.id === selectedPrompt);
      if (!prompt) throw new Error('Please select a writing style template');
       
      const structuredPrompt = `${prompt.prompt_text}
    
    Article Title: ${title}
    
    -Use proper heading hierarchy (h2 for main sections)
    - Each section should be well-developed
    - Include relevant examples and details
    - Ensure logical flow between sections
    - Make content engaging and informative`;
    
      // Generate initial content
      updateArticleStatus(title, 'processing', 'Generating content...');
      const content = await aiClient.generateArticleContent({
        title,
        modelId: getModelId(model),
        customPrompt: structuredPrompt
      });
  
      let processedContent = formatContent(content);
      let images = [];
      let featuredMediaId = null;
  
      // Handle featured image for both WordPress and local
      if (featuredImage) {
        try {
          updateArticleStatus(title, 'processing', 'Generating featured image...');
          const imageData = await imageClient.generateImage({
            prompt: featuredImagePrompt || title,
            model: featuredImageModel,
            dimension: featuredDimension,
            style: featuredImageStyle,
            enhancePrompt: useAIEnhancement,
            context: {
              articleTitle: title
            }
          });
  
          if (imageData?.image_url) {
            if (selectedSite === 'local') {
              // For local files, store the image URL and add to content
              images.push({
                type: 'featured',
                url: imageData.image_url,
                alt: title,
                title: title
              });
              // Insert featured image at the top of content
              processedContent = `<figure class="featured-image">
                <img src="${imageData.image_url}" alt="${title}" title="${title}" 
                  style="max-width: 100%; height: auto; margin: 20px 0;">
              </figure>\n${processedContent}`;
            } else {
              // WordPress upload
              const response = await fetch(imageData.image_url);
              const blob = await response.blob();
              const formData = new FormData();
              formData.append('file', blob, `featured-${title.replace(/[^a-z0-9]/gi, '-')}.png`);
              formData.append('siteId', selectedSite);
  
              const uploadResult = await authenticatedRequest(() => 
                apiClient.uploadMedia(formData)
              );
              featuredMediaId = uploadResult?.media_id;
            }
          }
        } catch (error) {
          console.error('Featured image error:', error);
          updateArticleStatus(title, 'processing', 'Featured image failed, continuing...');
        }
      }
  
      
      let imagesGenerated = 0;
  
      if (subheadingImages) {
        try {
          const parser = new DOMParser();
          const doc = parser.parseFromString(processedContent, 'text/html');
          const headings = Array.from(doc.querySelectorAll('h2'));
          let imagesGenerated = 0;
      
          console.log('Processing subheading images:', {
            totalHeadings: headings.length,
            maxImages: maxSubheadingImages
          });
      
          for (const heading of headings) {
            if (imagesGenerated >= maxSubheadingImages) break;
            if (shouldExcludeHeading(heading.textContent)) {
              console.log('Skipping excluded heading:', heading.textContent);
              continue;
            }
      
            try {
              updateArticleStatus(title, 'processing', `Generating image for section: ${heading.textContent}`);
              
              const imageData = await imageClient.generateImage({
                prompt: heading.textContent,
                model: subheadingImageModel,
                dimension: subheadingDimension,
                style: subheadingImageStyle,
                enhancePrompt: useAIEnhancement,
                context: {
                  articleTitle: title
                }
              });
      
              if (imageData?.image_url) {
                if (selectedSite === 'local') {
                  // Store image data for local download
                  images.push({
                    type: 'subheading',
                    url: imageData.image_url,
                    alt: heading.textContent,
                    title: heading.textContent,
                    section: heading.textContent
                  });
      
                  // Add image directly into the content
                  const imgHtml = `<figure class="section-image">
                    <img src="${imageData.image_url}" alt="${heading.textContent}" />
                    <figcaption>${heading.textContent}</figcaption>
                  </figure>`;
      
                  const temp = doc.createElement('div');
                  temp.innerHTML = imgHtml;
                  heading.parentNode.insertBefore(temp.firstChild, heading.nextSibling);
                  imagesGenerated++;
                } else {
                  // Existing WordPress upload code
                  const response = await fetch(imageData.image_url);
                  const blob = await response.blob();
                  const uploadResult = await wpClient.uploadMedia(
                    blob,
                    `subheading-${heading.textContent.replace(/[^a-z0-9]/g, '-')}.png`
                  );
      
                  if (uploadResult) {
                    const imgHtml = `<figure class="wp-block-image size-full">
                      <img src="${uploadResult.url}" 
                           alt="${heading.textContent}" 
                           class="wp-image-${uploadResult.id}"/>
                      <figcaption>${heading.textContent}</figcaption>
                    </figure>`;
      
                    const temp = doc.createElement('div');
                    temp.innerHTML = imgHtml;
                    heading.parentNode.insertBefore(temp.firstChild, heading.nextSibling);
                    imagesGenerated++;
                  }
                }
              }
            } catch (error) {
              console.error(`Failed to process image for heading "${heading.textContent}":`, error);
              updateArticleStatus(
                title, 
                'processing',
                `Failed to generate image for "${heading.textContent}", continuing...`
              );
            }
          }
      
          processedContent = doc.body.innerHTML;
          
          console.log('Completed subheading images:', {
            imagesGenerated,
            contentLength: processedContent.length
          });
        } catch (error) {
          console.error('Subheading images error:', error);
          updateArticleStatus(title, 'processing', 'Subheading images failed, continuing...');
        }
      }
  
      if (selectedSite === 'local') {
        // Store content and metadata for local download
        updateArticleStatus(title, 'completed', 'Ready for download', {
          content: content, 
          processedContent: processedContent, 
          images: images,
          title: title,
          timestamp: new Date().toISOString(),
          metadata: {
            generated: new Date().toISOString(),
            model: getModelId(model),
            imageSettings: {
              featured: featuredImage ? {
                model: featuredImageModel,
                style: featuredImageStyle,
                dimension: featuredDimension
              } : null,
              subheadings: subheadingImages ? {
                model: subheadingImageModel,
                style: subheadingImageStyle,
                dimension: subheadingDimension,
                count: imagesGenerated
              } : null
            }
          }
        });
      } else {
        // Create WordPress post
        updateArticleStatus(title, 'processing', 'Creating WordPress post...');
        const post = await wpClient.createPost({
          title,
          content: processedContent,
          categoryId: category,
          featuredMediaId,
          status: 'draft'
        });
  
        updateArticleStatus(title, 'completed', `Created successfully (Post ID: ${post.id})`);
      }
  
    } catch (error) {
      console.error('Error generating article:', error);
      await handleGenerationError(error, title, retryAttempt);
    } finally {
      if (queuedArticles.every(article => 
        article.status === 'completed' || article.status === 'error'
      )) {
        setIsGenerating(false);
      }
    }
  };

  const handleRetry = async (title) => {
    const currentRetries = retryCount[title] || 0;
    if (currentRetries < MAX_RETRIES) {
      setRetryCount(prev => ({ ...prev, [title]: 0 }));
      await generateArticle(title, 0);
    }
  };

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000; 

  const isRetryableError = (error) => {
    const message = (error.message || '').toLowerCase();
    return (
      message.includes('rate limit') ||
      message.includes('timeout') ||
      message.includes('too many requests') ||
      message.includes('service unavailable') ||
      message.includes('network') ||
      message.includes('connection')
    );
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRetryCount({});
    setIsGenerating(true);
  
    const titleList = titles.split('\n').filter(title => title.trim());
    
    if (titleList.length === 0) {
      setError('Please enter at least one title');
      setLoading(false);
      setIsGenerating(false);
      return;
    }
  
    try {
      let wpClient = null;
      
      // Only initialize WordPress client if not saving locally
      if (selectedSite !== 'local') {
        const site = sites.find(s => String(s.id) === String(selectedSite));
        if (!site) {
          console.log('Available sites:', sites);  // For debugging
          console.log('Selected site:', selectedSite);  // For debugging
          throw new Error('Selected site not found');
        }
        
        wpClient = new WordPressClient(site);
        const credentials = await authenticatedRequest(() => 
          apiClient.getWordPressCredentials(selectedSite)
        );
        wpClient.setCredentials(credentials.username, credentials.password);
      }
  
      initializeQueue(titleList);
  
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
  
      for (const title of titleList) {
        await generateArticle(title, 0, wpClient);
      }
    } catch (error) {
      console.error('Error generating articles:', error);
      setError('Error generating articles: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const getStatusIcon = (article) => {
    switch (article.status) {
      case 'completed':
        return <CheckCircleIcon color="success" />;
      case 'error':
        const retries = retryCount[article.title] || 0;
        if (retries < MAX_RETRIES) {
          return (
            <Tooltip title="Click to retry">
              <IconButton
                size="small"
                onClick={() => handleRetry(article.title)}
                sx={{ color: 'error.main' }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          );
        }
        return <ErrorIcon color="error" />;
      case 'processing':
      case 'retrying':
        return <CircularProgress size={24} />;
      default:
        return <PendingIcon color="disabled" />;
    }
  };

  
  const handleSavePrompt = () => {
    if (tempPrompt.trim()) {
      setOpenPromptDialog(false);
    }
  };

 
  if (authLoading) {
    return (
      <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (!isAuthenticated) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="warning">Please log in to continue</Alert>
      </Box>
    );
  }
  

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
       <LeaveConfirmationDialog
  open={leaveDialogOpen}
  onClose={() => setLeaveDialogOpen(false)}
  onConfirm={() => {
    setIsGenerating(false); // Stop generation
    setLeaveDialogOpen(false);
    if (navigationTarget) {
      window.location.href = navigationTarget;
    }
  }}
/>
      
      {/* Page Header with Help Button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
       <PageHeader 
  category="Content Creation"
  title="Bulk Article Generator" 
  description="Create multiple high-quality articles in one go! Just input your titles, choose a prompt template, and let AI generate engaging content with optional AI-generated images for each article. Perfect for quickly building up your blog or website with original content."
  icon={<FileText size={24} color="#E04C16" />} 
/>
        <Button
          onClick={() => setHelpDialogOpen(true)}
          startIcon={<HelpCircle size={18} />}
          sx={{
            bgcolor: '#E04C16',
            color: 'white',
            borderRadius: '20px',
            px: 3,
            '&:hover': {
              bgcolor: '#c43d0f'
            }
          }}
        >
          How to Use
        </Button>
      </Box>

      {/* Help Dialog */}
      <Dialog 
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        PaperProps={{
          sx: {
            position: 'fixed',
            right: 32,
            top: '50%',
            transform: 'translateY(-50%)',
            m: 0,
            width: 400,
            maxWidth: '90vw',
            maxHeight: '80vh',
            borderRadius: 2
          }
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
            How to Use Article Generator
          </DialogTitle>
          <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
            <X size={18} />
          </IconButton>
        </Box>
        <DialogContent>
  {/* Getting Started */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Getting Started with Bulk Article Generator
  </Typography>
  <Typography variant="body2" sx={{ mb: 4, lineHeight: 1.6 }}>
    Welcome to the Bulk Article Generator! This powerful tool lets you create multiple high-quality articles in one go—perfect for building a blog, populating a website, or growing a content library. It combines advanced AI writing with optional image generation to streamline your workflow. Whether you’re saving directly to WordPress or downloading files locally, this feature saves time while delivering consistent, customizable content.
  </Typography>

  {/* Step-by-Step Guide */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Step-by-Step Guide
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Follow these steps to create your batch of articles:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Choose your destination:</strong> Decide where your articles will go—either save them as drafts to your WordPress site or download them as text/HTML files to your computer. For WordPress, select a category (e.g., "Technology" or "Lifestyle") to organize your posts.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Select a writing style template:</strong> Pick a template that matches your desired tone and format (e.g., "Informal Blog Post" or "SEO-Optimized Guide"). These ensure consistency across all articles. Click the eye icon next to a template to preview its structure and see how your content will look.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Enter article titles:</strong> In the Article Titles box, type each title on a new line (e.g., "Top 10 Remote Work Tips" or "Why Solar Energy Matters"). Make titles clear and descriptive—the AI uses them to generate relevant content and subheadings, powered by the auxiliary AI model selected in the sidebar.
    </li>
  </Typography>

  {/* Customizing Your Content */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Customizing Your Content
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Tailor your articles with these options:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Choose an AI model:</strong> Select an AI model for article generation from the available options. Each model varies in capability—some are faster but less detailed, while others produce richer content. Experiment with different models to find the best fit for your needs (e.g., a simpler model for quick drafts, a robust one for in-depth articles).
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Add a featured image:</strong> Enable the "Featured Image" option to include a header image for each article. Choose an image model—cheaper models are faster but may lack quality, while higher-quality models deliver sharper, more professional visuals.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Enable subheading images:</strong> Turn on "Subheading Images" to add visuals throughout your articles. Click the image icon next to each subheading to enable it, then expand the section to choose an image model or enter a custom prompt. AI-generated image prompts use the auxiliary AI model from the sidebar for relevance. Adjust style and frequency to suit your content.
    </li>
  </Typography>

  {/* Generating and Managing Articles */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Generating and Managing Articles
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Launch and oversee the process with these steps:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ mb: 4, pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Generate your articles:</strong> Click "Generate Articles" to start the process. Watch the Article Queue panel on the right to track progress for each title—statuses will update as articles are created.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Manage WordPress drafts:</strong> If saving to WordPress, articles appear as drafts in your chosen category. Log into your WordPress dashboard to review, edit, or publish them.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Download locally:</strong> For local saves, choose text or HTML format after generation. Each file includes the article content and any generated images, ready for use or upload elsewhere.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Review and retry:</strong> After generation, download individual articles, retry any that didn’t meet expectations, or access them in WordPress. Always review and tweak the content to ensure it aligns with your goals before publishing.
    </li>
  </Typography>

  {/* Best Practices */}
  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
    Best Practices for Great Results
  </Typography>
  <Typography variant="body2" sx={{ mb: 1, lineHeight: 1.6 }}>
    Optimize your bulk generation with these tips:
  </Typography>
  <Typography component="ul" variant="body2" sx={{ pl: 2, lineHeight: 1.6 }}>
    <li style={{ marginBottom: '12px' }}>
      <strong>Craft descriptive titles:</strong> Use specific titles like "How to Boost Remote Work Productivity" instead of vague ones like "Productivity Tips" to guide the AI effectively.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Select the right auxiliary AI model:</strong> Subheading generation and AI image prompts rely on the auxiliary AI model chosen in the sidebar. Pick a capable model for accurate subheadings and relevant images—test a few to see what works best.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Balance image quality and cost:</strong> For featured and subheading images, choose higher-quality models for professional visuals (e.g., for key articles) and cheaper ones for quick drafts. Test different models to find your sweet spot.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Preview templates:</strong> Use the eye icon to check writing style templates before starting—this ensures the tone and structure match your audience.
    </li>
    <li style={{ marginBottom: '12px' }}>
      <strong>Monitor the queue:</strong> Keep an eye on the Article Queue to catch any issues early and retry if needed during generation.
    </li>
  </Typography>
</DialogContent>
      </Dialog>
      

      {authError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {authError}
        </Alert>
      )}
      <Grid container spacing={3}>
        {/* Left Column - Form Controls */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <FormControl fullWidth required>
  <InputLabel id="wordpress-site-label">Select Output Type</InputLabel>
  <Select
    labelId="wordpress-site-label"
    value={selectedSite || ''}
    label="Output Location"
    onChange={(e) => {
      setSelectedSite(e.target.value);
      if (e.target.value === 'local') {
        setCategory('');
      }
    }}
    disabled={loading}
  >
    
    <MenuItem value="local">Save As Text/HTML</MenuItem>
    <Divider />
    <ListSubheader sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
     Save To WordPress Site
    </ListSubheader>
    {sites.map((site) => (
      <MenuItem key={site.id} value={site.id}>
        {site.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>
                </Grid>

                <Grid item xs={12}>
                {selectedSite !== 'local' && (
  <FormControl
    fullWidth
    required
    sx={{
      '& .MuiInputLabel-root': {
        marginTop: '-8px'
      },
      '& .MuiInputLabel-shrink': {
        marginTop: 0
      }
    }}
  >
    <InputLabel id="category-label">Select Category</InputLabel>
    <Select
      labelId="category-label"
      value={category}
      label="Select Category"
      onChange={(e) => setCategory(e.target.value)}
      disabled={loading || !selectedSite}
    >
      {categories.map((cat) => (
        <MenuItem key={cat.id} value={cat.id}>
          {cat.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)}
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FormControl fullWidth required>
  <InputLabel>Choose A Prompt</InputLabel>
  <Select
    value={selectedPrompt}
    label="Choose A Prompt"
    onChange={(e) => setSelectedPrompt(e.target.value)}
    disabled={loading}
  >
    {/* System Templates Section */}
    {prompts.systemTemplates.length > 0 && (
      <ListSubheader 
        sx={{ 
          fontWeight: 600,
          fontSize: '0.875rem'
        }}
      >
        Default Prompts
      </ListSubheader>
    )}
    {prompts.systemTemplates.map((prompt) => (
      <MenuItem 
        key={prompt.id} 
        value={prompt.id}
      >
        <Box>
          <Typography variant="subtitle2">{prompt.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {prompt.settings?.description || 'System template'}
          </Typography>
        </Box>
      </MenuItem>
    ))}

    {/* User Templates Section */}
    {prompts.userPrompts.length > 0 && (
      <ListSubheader 
        sx={{ 
          fontWeight: 600,
          fontSize: '0.875rem'
        }}
      >
        My Saved Prompts
      </ListSubheader>
    )}
    {prompts.userPrompts.map((prompt) => (
      <MenuItem key={prompt.id} value={prompt.id}>
        {prompt.name}
      </MenuItem>
    ))}

    {/* Show message if no prompts available */}
    {!prompts.systemTemplates.length && !prompts.userPrompts.length && (
      <MenuItem disabled>
        No templates available
      </MenuItem>
    )}
  </Select>
</FormControl>
                    {selectedPrompt && (
                      <IconButton 
                        onClick={() => handlePreviewPrompt(selectedPrompt)}
                        sx={{ ml: 1 }}
                      >
                        <Eye />
                      </IconButton>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Article Titles"
                    multiline
                    rows={5}
                    value={titles}
                    onChange={(e) => setTitles(e.target.value)}
                    onBlur={() => setTitlesTouched(true)}
                    required
                    disabled={loading}
                    placeholder="Enter article titles (one per line)"
                    error={showTitleError}
                    helperText={
                      showTitleError
                        ? "Please enter valid article titles"
                        : "Enter one title per line. Each title should be 10-150 characters. Maximum 100 titles."
                    }
                   
                  />
                </Grid>

                <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
          <Box sx={{ flexGrow: 1 }}>
            <ModelSelector
              value={model}
              onChange={(e) => setModel(e.target.value)}
              required
              tooltip="Choose the AI model that will generate your article content. Different models have different strengths and capabilities."
              label="AI MODEL 2"
              disabled={loading || modelsLoading}
              showDescription={true}
            />
            {modelsError && (
              <FormHelperText error>
                {modelsError}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {/* AI Enhancement Toggle */}
<Box sx={{ bgcolor: 'background.default', borderRadius: 1, mb: 3 }}>
  <FormControlLabel
    control={
      <Switch
        checked={useAIEnhancement}
        onChange={(e) => setUseAIEnhancement(e.target.checked)}
        disabled={loading}
      />
    }
    label="Enhance Image Prompts with AI"
  />
  <FormHelperText>
    AI will automatically enhance image prompts for better results
  </FormHelperText>
</Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* Featured Image Section */}
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={featuredImage}
              onChange={(e) => setFeaturedImage(e.target.checked)}
              disabled={loading}
            />
          }
          label="Generate Featured Image"
        />
        
        {featuredImage && (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Featured Image Prompt (optional)"
                  placeholder="Leave blank to let AI generate based on article title and content"
                  value={featuredImagePrompt}
                  onChange={(e) => setFeaturedImagePrompt(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageControls
                  model={featuredImageModel}
                  setModel={setFeaturedImageModel}
                  style={featuredImageStyle}
                  setStyle={setFeaturedImageStyle}
                  dimension={featuredDimension}
                  setDimension={setFeaturedDimension}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      {/* Subheading Images Section */}
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={subheadingImages}
              onChange={(e) => setSubheadingImages(e.target.checked)}
              disabled={loading}
            />
          }
          label="Generate Subheading Images"
        />

{subheadingImages && (
  <Box sx={{ mt: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Configure settings for subheading images. A new image will be generated for each subheading.
        </Typography>
      </Grid>
      
      {/* Image Generation Controls */}
      <Grid item xs={12}>
        <ImageControls
          model={subheadingImageModel}
          setModel={setSubheadingImageModel}
          style={subheadingImageStyle}
          setStyle={setSubheadingImageStyle}
          dimension={subheadingDimension}
          setDimension={setSubheadingDimension}
          loading={loading}
        />
      </Grid>

      {/* Maximum Images Control */}
      <Grid item xs={12} md={6}>
      <TextField
    fullWidth
    type="number"
    label="Maximum Subheading Images"
    value={maxSubheadingImages}
    onChange={(e) => {
      const inputValue = e.target.value === '' ? '' : parseInt(e.target.value);
      if (inputValue === '') {
        setMaxSubheadingImages('');
      } else {
        const limitedValue = Math.min(Math.max(0, inputValue), 10);
        setMaxSubheadingImages(limitedValue);
      }
    }}
    
    InputProps={{ 
      inputProps: { 
        min: 0, 
        max: 10 
      } 
    }}
    helperText={`You can generate 0 to 10 images`}
  />
      </Grid>

      {/* Section Exclusion Controls */}
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
          Exclude Images from Sections
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeIntro}
                onChange={(e) => setExcludeIntro(e.target.checked)}
                disabled={loading}
              />
            }
            label="Skip Introduction Section"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeConclusion}
                onChange={(e) => setExcludeConclusion(e.target.checked)}
                disabled={loading}
              />
            }
            label="Skip Conclusion Section"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeFAQ}
                onChange={(e) => setExcludeFAQ(e.target.checked)}
                disabled={loading}
              />
            }
            label="Skip FAQ Section"
          />
        </Box>
      </Grid>
    </Grid>
  </Box>
)}
      </Box>
    </Box>
  </Grid>
                
                

                <Grid item xs={12}>
                  {error && (
                    <Alert severity="error">
                      {typeof error === 'string' ? error : 'An error occurred'}
                    </Alert>
                  )}
                </Grid>
            
                <Grid item xs={12}>
                <Button
  type="submit"
  variant="contained"
  disabled={
    (selectedSite !== 'local' && (!selectedSite || !category)) || 
    !selectedPrompt || 
    !hasTitles || 
    loading
  }
  fullWidth
>
  {loading ? 'Generating Articles...' : 'Generate Articles'}
</Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        {/* Right Column - Article Queue */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Article Queue
            </Typography>
            {queuedArticles.length === 0 ? (
              <Box sx={{ textAlign: 'center', py: 8 }}>
                <Typography color="textSecondary">
                  No articles in queue. Add titles and click "Generate Articles" to begin.
                </Typography>
              </Box>
            ) : (
              <List>
                {queuedArticles.map((article, index) => (
                  <ListItem
                  key={index}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    '&:last-child': {
                      borderBottom: 'none'
                    },
                    py: 2
                  }}
                >
                  <ListItemIcon>
                    {getStatusIcon(article)}
                  </ListItemIcon>
                  <ListItemText
                    primary={article.title}
                    secondary={article.message}
                    primaryTypographyProps={{
                      fontWeight: 500
                    }}
                    secondaryTypographyProps={{
                      fontSize: '0.875rem'
                    }}
                  />
                    {selectedSite === 'local' &&
                      article.status === 'completed' && (
                        <Box sx={{ ml: 2 }}>
                          <DownloadHandler
                            content={article.content}
                            processedContent={article.processedContent}
                            title={article.title}
                            images={article.images}  // Add this line
                            onDownload={(format) => {
                              console.log('Downloading article:', article);
                              updateArticleStatus(
                                article.title,
                                'completed',
                                `Downloaded as ${format.toUpperCase()}`
                              );
                            }}
                          />
                        </Box>
                      )}
                </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {previewPrompt?.name}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {previewPrompt?.prompt_text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Prompt Edit Dialog */}
      <Dialog
        open={openPromptDialog}
        onClose={() => setOpenPromptDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Prompt Template</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={15}
            value={tempPrompt}
            onChange={(e) => setTempPrompt(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPromptDialog(false)}>Cancel</Button>
          <Button onClick={handleSavePrompt} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <NavigationDialog />
    </Box>
  );
}
export default ArticleGenerator;