import React from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import { Copy, CheckCircle } from 'lucide-react';
import bg1 from '../assets/facebookpostbg1.jpg';
import bg2 from '../assets/facebookpostbg2.jpg';
import bg3 from '../assets/facebookpostbg3.jpg';
import bg4 from '../assets/facebookpostbg4.jpg';

const FacebookPostCard = ({ idea, index, copiedIndex, onCopy }) => {
 
const backgrounds = [bg1, bg2, bg3, bg4];
  
const bgImage = backgrounds[index % backgrounds.length];

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'relative',
        height: '250px',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.2)'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: 3,
        cursor: 'pointer',
        borderRadius: 2,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: 2
        }
      }}
    >
      <Typography 
        variant="h6" 
        align="center"
        sx={{ 
          position: 'relative',
          zIndex: 1,
          fontWeight: '600',
          fontSize: '1.25rem',
          lineHeight: 1.4,
          color: 'white',
          wordBreak: 'break-word',
          textShadow: '0 1px 4px rgba(0,0,0,0.4)'
        }}
      >
        {idea}
      </Typography>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onCopy(index);
        }}
        size="small"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white',
          backgroundColor: 'rgba(255,255,255,0.1)',
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.2)'
          },
          transition: 'all 0.2s ease',
          backdropFilter: 'blur(4px)',
          zIndex: 2
        }}
      >
        {copiedIndex === index ? (
          <CheckCircle size={20} />
        ) : (
          <Copy size={20} />
        )}
      </IconButton>
    </Paper>
  );
};

export default FacebookPostCard;