import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
} from '@mui/material';
import { X, HelpCircle, FileImage, FileCode, Scale } from 'lucide-react';

const CompressionDialog = ({
  open,
  onClose,
  onConfirm,
  action = 'download',
  wordPressSites = [],
  selectedSite,
  setSelectedSite,
  uploadLoading = false,
  uploadProgress = { current: 0, total: 0 }
}) => {
  const [compressionType, setCompressionType] = React.useState('none');
  const [quality, setQuality] = React.useState(80);
  const [maxSize, setMaxSize] = React.useState(1);
  const [outputFormat, setOutputFormat] = React.useState('png');


  const handleConfirm = () => {
    const settings = {
      type: compressionType,
      options: compressionType === 'lossy' ? {
        maxSizeMB: maxSize,
        initialQuality: quality / 100,
        outputFormat: outputFormat
      } : {
        outputFormat: outputFormat
      }
    };
    onConfirm(settings);
  };

  const CompressOption = ({ value, label, description, icon: Icon }) => (
    <Paper 
      elevation={0}
      sx={{
        p: 2,
        mb: 1,
        border: '1px solid',
        borderColor: compressionType === value ? 'primary.main' : 'divider',
        borderRadius: 2,
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        backgroundColor: compressionType === value ? 'primary.50' : 'background.paper',
        '&:hover': {
          borderColor: 'primary.main',
          backgroundColor: compressionType === value ? 'primary.50' : 'primary.50'
        }
      }}
      onClick={() => setCompressionType(value)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Radio 
          checked={compressionType === value}
          value={value}
          sx={{ p: 0 }}
        />
        <Icon size={20} />
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            {label}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {description}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );

  const FormatOption = ({ value, label }) => (
    <Button
      variant={outputFormat === value ? "contained" : "outlined"}
      onClick={() => setOutputFormat(value)}
      sx={{
        minWidth: 100,
        borderRadius: 2,
        py: 1,
        textTransform: 'none',
        backgroundColor: outputFormat === value ? 'primary.main' : 'transparent',
        borderColor: outputFormat === value ? 'primary.main' : 'divider',
        color: outputFormat === value ? 'white' : 'text.primary',
        '&:hover': {
          backgroundColor: outputFormat === value ? 'primary.dark' : 'primary.50',
          borderColor: 'primary.main'
        }
      }}
    >
      {label}
    </Button>
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        p: 3,
        pb: 0
      }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {action === 'download' ? 'Download To Device' : 'Upload To Wordpress'}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Choose image format and customise image compression
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            color: 'text.secondary',
            '&:hover': { backgroundColor: 'primary.50' }
          }}
        >
          <X size={20} />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 3 }}>
        {action === 'upload' && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
              Choose A WordPress Site
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedSite}
                onChange={(e) => setSelectedSite(e.target.value)}
                disabled={uploadLoading}
                sx={{ borderRadius: 2 }}
              >
                {wordPressSites.map((site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      {action === 'upload' && uploadLoading && uploadProgress.total > 0 && (
  <Box sx={{ mt: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress 
          variant="determinate" 
          value={uploadProgress.total > 0 ? (uploadProgress.current / uploadProgress.total) * 100 : 0} 
        />
      </Box>
      <Box sx={{ minWidth: 65 }}>
        <Typography variant="body2" color="text.secondary">
          {`${uploadProgress.current}/${uploadProgress.total}`}
        </Typography>
      </Box>
    </Box>
    <Typography variant="caption" color="text.secondary">
      {`Uploading ${uploadProgress.total} image${uploadProgress.total !== 1 ? 's' : ''} to WordPress...`}
    </Typography>
  </Box>
)}
<Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
              Choose A Compression Type
            </Typography>
        <Box sx={{ mb: 4 }}>
          <CompressOption
            value="none"
            label="No Compression"
            description="Original image size, no modifications"
            icon={FileImage}
          />
          <CompressOption
            value="lossless"
            label="Lossless Compression"
            description="Reduce file size while maintaining exact quality"
            icon={FileCode}
          />
          <CompressOption
            value="lossy"
            label="Lossy Compression"
            description="Maximum size reduction with slight quality loss"
            icon={Scale}
          />
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
            Select An Output Format
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormatOption value="png" label="PNG" />
            <FormatOption value="jpeg" label="JPEG" />
            <FormatOption value="webp" label="WebP" />
          </Box>
        </Box>

        {compressionType === 'lossy' && (
          <Box sx={{ mb: 4 }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                Quality Level
              </Typography>
              <Slider
                value={quality}
                onChange={(_, value) => setQuality(value)}
                valueLabelDisplay="auto"
                min={50}
                max={90}
                marks={[
                  { value: 50, label: '50%' },
                  { value: 70, label: '70%' },
                  { value: 90, label: '90%' }
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    width: 20,
                    height: 20,
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: '0 0 0 8px rgba(224, 76, 22, 0.16)'
                    }
                  },
                  '& .MuiSlider-track': {
                    height: 6,
                    backgroundColor: 'primary.main'
                  },
                  '& .MuiSlider-rail': {
                    height: 6,
                    backgroundColor: 'grey.200'
                  }
                }}
              />
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                Maximum File Size (MB)
              </Typography>
              <Slider
                value={maxSize}
                onChange={(_, value) => setMaxSize(value)}
                valueLabelDisplay="auto"
                min={0.1}
                max={2}
                step={0.1}
                marks={[
                  { value: 0.1, label: '0.1' },
                  { value: 1, label: '1.0' },
                  { value: 2, label: '2.0' }
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    width: 20,
                    height: 20,
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: '0 0 0 8px rgba(224, 76, 22, 0.16)'
                    }
                  },
                  '& .MuiSlider-track': {
                    height: 6,
                    backgroundColor: 'primary.main'
                  },
                  '& .MuiSlider-rail': {
                    height: 6,
                    backgroundColor: 'grey.200'
                  }
                }}
              />
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            minWidth: 100
          }}
        >
          Cancel
        </Button>
        <Button 
          variant="contained"
          onClick={handleConfirm}
          disabled={action === 'upload' && !selectedSite}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            minWidth: 100,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'primary.dark'
            }
          }}
        >
          {action === 'download' ? 'Download' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompressionDialog;