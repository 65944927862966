import React, { useState } from 'react';
import { Wand2, ChevronDown, ChevronUp } from 'lucide-react';

const InlineMemeGenerator = ({ onGenerate, isGenerating, disabled }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState('classic');
  const [tone, setTone] = useState('funny');
  const [customNotes, setCustomNotes] = useState('');
  const [inputMode, setInputMode] = useState('image'); // Add inputMode state
  const [textDescription, setTextDescription] = useState(''); // Add textDescription state

  const memeStyles = [
    { value: 'classic', label: 'Classic Meme', description: 'Traditional impact-font style memes' },
    { value: 'modern', label: 'Modern Twitter', description: 'Contemporary social media style captions' },
    { value: 'wholesome', label: 'Wholesome', description: 'Positive and uplifting messages' },
    { value: 'surreal', label: 'Surreal/Abstract', description: 'Weird, abstract, or nonsensical humor' },
    { value: 'gen-z', label: 'Gen Z', description: 'Ultra-modern with current slang and references' }
  ];

  const memeTones = [
    { value: 'funny', label: 'Funny', description: 'General humor and jokes' },
    { value: 'sarcastic', label: 'Sarcastic', description: 'Dry humor and irony' },
    { value: 'relatable', label: 'Relatable', description: 'Everyday situations everyone understands' },
    { value: 'dramatic', label: 'Dramatic', description: 'Over-the-top reactions' },
    { value: 'clever', label: 'Clever', description: 'Intelligent wordplay and puns' }
  ];

  const inputModes = [
    { value: 'image', label: 'Analyze Image', description: 'Let AI analyze the uploaded image' },
    { value: 'text', label: 'Describe Image', description: 'Provide your own description for the AI' }
  ];

  const handleGenerate = () => {
    onGenerate({ style, tone, customNotes, inputMode, textDescription });
    setCustomNotes('');
    if (inputMode === 'text') setTextDescription(''); // Reset description only if in text mode
  };

  if (disabled) return null;

  return (
    <div className="mb-6 bg-white rounded-lg border border-gray-200">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full p-4 flex items-center justify-between text-left hover:bg-gray-50 transition-colors rounded-lg"
      >
        <div className="flex items-center gap-2">
          <Wand2 className={`h-5 w-5 ${isGenerating ? 'text-gray-400' : 'text-[#E04D16]'}`} />
          <span className="font-medium">Add Meme Text</span>
        </div>
        {isExpanded ? (
          <ChevronUp className="h-5 w-5 text-gray-400" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-400" />
        )}
      </button>

      {isExpanded && (
        <div className="p-4 border-t border-gray-200 space-y-4">
          {/* Input Mode Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Input Mode
            </label>
            <select
              value={inputMode}
              onChange={(e) => setInputMode(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#E04D16] focus:border-transparent bg-white"
            >
              {inputModes.map((mode) => (
                <option key={mode.value} value={mode.value}>
                  {mode.label}
                </option>
              ))}
            </select>
            <p className="text-sm text-gray-500">
              {inputModes.find(m => m.value === inputMode)?.description}
            </p>
          </div>

          {/* Text Description (only for 'text' mode) */}
          {inputMode === 'text' && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Image Description
              </label>
              <textarea
                value={textDescription}
                onChange={(e) => setTextDescription(e.target.value)}
                placeholder="Describe the image for the AI to generate captions..."
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#E04D16] focus:border-transparent min-h-[80px] resize-none"
              />
            </div>
          )}

          {/* Style Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Meme Style
            </label>
            <select
              value={style}
              onChange={(e) => setStyle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#E04D16] focus:border-transparent bg-white"
            >
              {memeStyles.map((style) => (
                <option key={style.value} value={style.value}>
                  {style.label}
                </option>
              ))}
            </select>
            <p className="text-sm text-gray-500">
              {memeStyles.find(s => s.value === style)?.description}
            </p>
          </div>

          {/* Tone Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Tone
            </label>
            <select
              value={tone}
              onChange={(e) => setTone(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#E04D16] focus:border-transparent bg-white"
            >
              {memeTones.map((tone) => (
                <option key={tone.value} value={tone.value}>
                  {tone.label}
                </option>
              ))}
            </select>
            <p className="text-sm text-gray-500">
              {memeTones.find(t => t.value === tone)?.description}
            </p>
          </div>

          {/* Custom Notes */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Additional Context (Optional)
            </label>
            <textarea
              value={customNotes}
              onChange={(e) => setCustomNotes(e.target.value)}
              placeholder="Add specific references, themes, or context you want included..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#E04D16] focus:border-transparent min-h-[80px] resize-none"
            />
          </div>

          {/* Generate Button */}
          <button
            onClick={handleGenerate}
            disabled={isGenerating || (inputMode === 'text' && !textDescription.trim())}
            className={`w-full py-2 px-4 rounded-lg text-white font-medium ${
              isGenerating || (inputMode === 'text' && !textDescription.trim())
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#E04D16] hover:bg-[#C43E12] transition-colors'
            }`}
          >
            {isGenerating ? 'Generating...' : 'Generate Meme Text'}
          </button>
        </div>
      )}
    </div>
  );
};

export default InlineMemeGenerator;