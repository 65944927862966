import { config } from '../config';
import axios from 'axios';



export class ApiClient {
  

  constructor() {
    this.instance = axios.create({
      baseURL: config.api.baseUrl,
      timeout: 120000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    this.cache = new Map(); 
    this.CACHE_DURATION = 12 * 60 * 60 * 1000;
  }
  
  configureRequest(config) {
    return {
      ...config,
      withCredentials: true,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };
  }

  async request(config) {
    const maxRetries = 3;
    let retries = 0;
  
    while (retries < maxRetries) {
      try {
        const isFormData = config.data instanceof FormData;
        
       
        const configToUse = isFormData ? {
          ...config,
          withCredentials: true
        } : this.configureRequest(config);
  
        const response = await this.instance(configToUse);
        return response.data;
      } catch (error) {
        
        retries++;
        
        if (error.code === 'ECONNABORTED' && retries < maxRetries) {
          
          await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retries)));
          continue;
        }
  
        if (error.response) {
          const errorDetail = error.response.data?.detail || 
                            error.response.data?.error || 
                            error.message;
          throw new Error(errorDetail);
        }
        throw error;
      }
    }
  }

  setAuthToken(token) {
    if (token) {
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete this.instance.defaults.headers.common['Authorization'];
      // Clear cache when token is removed
      this.clearCache();
    }
  }

  // Cache management methods
  setCached(key, data) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  getCached(key) {
    const cached = this.cache.get(key);
    if (!cached) return null;

    // Check if cache is expired
    if (Date.now() - cached.timestamp > this.CACHE_DURATION) {
      this.cache.delete(key);
      return null;
    }

    return cached.data;
  }

  clearCache() {
    this.cache.clear();
  }

  async scrapeUrl(url, prompt_id) {
    try {
        // Send as separate parameters, not nested
        const response = await this.request({
            method: 'POST',
            url: '/api/user/scrape-url',
            data: {
                url,       
                prompt_id   
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        if (!response.success) {
            throw new Error(response.error || 'Failed to scrape URL');
        }

        return response;

    } catch (error) {
        const errorMessage = error.response?.data?.detail || error.message || 'Failed to scrape URL';
        throw new Error(errorMessage);
    }
}



  async getApiKeys() {
    const cachedKeys = this.getCached('apiKeys');
    if (cachedKeys) {
      return cachedKeys;
    }

    const keys = await this.request({
      method: 'GET',
      url: '/api/user/api-keys'
    });

    this.setCached('apiKeys', keys);
    return keys;
  }

  async getSavedPrompts() {
    const cachedPrompts = this.getCached('savedPrompts');
    if (cachedPrompts) {
      return cachedPrompts;
    }

    const prompts = await this.request({
      method: 'GET',
      url: '/api/user/prompts'
    });

    this.setCached('savedPrompts', prompts);
    return prompts;
  }

  async getWordPressSites() {
    const cachedSites = this.getCached('wpSites');
    if (cachedSites) {
      return cachedSites;
    }

    const sites = await this.request({
      method: 'GET',
      url: '/api/user/wordpress-sites'
    });

    this.setCached('wpSites', sites);
    return sites;
  }

  async getAvailableModels() {
    const cachedModels = this.getCached('availableModels');
    if (cachedModels) {
      return cachedModels;
    }

    const models = await this.request({
      method: 'GET',
      url: '/api/user/available-models'
    });

    this.setCached('availableModels', models);
    return models;
  }

  async createPrompt(data) {
    const result = await this.request({
      method: 'POST',
      url: '/api/user/prompts',
      data
    });
    this.cache.delete('savedPrompts');
    return result;
  }

  async updatePrompt(promptId, data) {
    const result = await this.request({
      method: 'PUT',
      url: `/api/user/prompts/${promptId}`,
      data
    });
    this.cache.delete('savedPrompts');
    return result;
  }

  async deletePrompt(promptId) {
    const result = await this.request({
      method: 'DELETE',
      url: `/api/user/prompts/${promptId}`
    });
    this.cache.delete('savedPrompts');
    return result;
  }

  
  async togglePromptFavorite(promptId) {
    return this.request({
      method: 'PUT',
      url: `/api/user/prompts/${promptId}/favorite`
    });
  }
  
  async duplicatePrompt(promptId) {
    return this.request({
      method: 'POST',
      url: `/api/user/prompts/${promptId}/duplicate`
    });
  }

  async getPrompt(promptId) {
    const cachedPrompt = this.getCached(`prompt-${promptId}`);
    if (cachedPrompt) {
      return cachedPrompt;
    }

    const prompt = await this.request({
      method: 'GET',
      url: `/api/user/prompts/${promptId}`
    });

    this.setCached(`prompt-${promptId}`, prompt);
    return prompt;
  }
  
  async generatePrompt(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-prompt',
      data
    });
  }

  async getWordPressSites() {
    return this.request({
      method: 'GET',
      url: '/api/user/wordpress-sites'
    });
  }

  async getWordPressCredentials(siteId) {
    return this.request({
      method: 'GET',
      url: `/api/user/wordpress-sites/${siteId}/credentials`
    });
  }

  async getCategories(siteId) {
    return this.request({
      method: 'GET',
      url: `/api/user/wordpress-sites/${siteId}/categories`
    });
  }

  async generateArticle(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-article',
      data
    });
  }

  async generateImage(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-image',
      data
    });
  }

  async uploadMedia(formData) {
    return this.request({
      method: 'POST',
      url: '/api/user/upload-media',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async getAvailableModels() {
   
    return this.request({
      method: 'GET',
      url: '/api/user/available-models'
    });
  }

  

  async getAvailableImageModels() {
    return this.request({
      method: 'GET', 
      url: '/api/user/available-image-models'
    });
  }

  async searchProducts(params) {
    return this.request({
      method: 'POST',
      url: '/api/user/search-products',
      data: {
        query: params.query,
        maxResults: params.maxResults,
        region: params.region
      }
    });
  }

  async validateAndGatherProducts(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/validate-and-gather',
      data
    });
  }


  async generateProductReview(data) {
    try {
      if (!data || !data.settings) {
        throw new Error('Invalid data structure: missing settings');
      }
  
     
      if (data.settings?.productDisplay?.images?.enabled) {
        
        
        if (!Array.isArray(data.products)) {
          throw new Error('Products must be an array');
        }
  
        try {
          
          const processedProducts = await Promise.all(
            data.products.map(async (product) => {
             
              
             
              if (product.wordpress_image_url) {
                return product;
              }
  
             
              if (!product.image_url) {
                console.warn(`No image URL for product ${product.asin}`);
                return product;
              }
  
              try {
                // Download the image
                const response = await fetch(product.image_url);
                if (!response.ok) {
                  throw new Error(`Failed to fetch image: ${response.statusText}`);
                }
  
                const blob = await response.blob();
                
                // Create filename from product title
                const safeTitle = product.title
                  .replace(/[^a-z0-9]/gi, '-')
                  .toLowerCase()
                  .slice(0, 50);
                const filename = `product-${product.asin}-${safeTitle}.jpg`;
  
                const formData = new FormData();
                formData.append('file', blob, filename);
                formData.append('siteId', data.siteId);
  
                // Upload to WordPress
                const uploadResult = await this.request({
                  method: 'POST',
                  url: '/api/user/upload-media',
                  data: formData,
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                });
  
                if (!uploadResult || !uploadResult.url) {
                  throw new Error('Invalid upload response');
                }
  
                
              
                return {
                  ...product,
                  wordpress_image_url: uploadResult.url,
                  wordpress_image_id: uploadResult.media_id
                };
              } catch (imageError) {
                console.error(`Failed to process image for ${product.asin}:`, imageError);
                // Continue with product but without image
                return {
                  ...product,
                  image_error: imageError.message
                };
              }
            })
          );
  
          // Update data with processed products
          data = {
            ...data,
            products: processedProducts,
            settings: {
              ...data.settings,
              productDisplay: {
                ...data.settings.productDisplay,
                images: {
                  ...data.settings.productDisplay.images,
                  processedProducts: processedProducts
                    .filter(p => p.wordpress_image_url)
                    .map(p => ({
                      asin: p.asin,
                      imageUrl: p.wordpress_image_url,
                      imageId: p.wordpress_image_id
                    }))
                }
              }
            }
          };
  
        } catch (processError) {
          console.error('Error processing product images:', processError);
          throw new Error(`Failed to process product images: ${processError.message}`);
        }
      }
  
      // Make the final API request
      return await this.request({
        method: 'POST',
        url: '/api/user/generate-product-review',
        data,
        timeout: 180000 // 3 minutes
      });
  
    } catch (error) {
      console.error('Product review generation error:', {
        message: error.message,
        stack: error.stack,
        response: error.response?.data
      });
  
      throw new Error(
        error.response?.data?.detail || 
        error.message || 
        'Failed to generate product review'
      );
    }
  }
  

  // Create WordPress post through the generate-article endpoint
  async createWordPressPost(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-article',
      data: {
        title: data.title,
        content: data.content,
        siteId: data.siteId,
        category: data.category,
        featured_media: data.featured_media,
        model: 'claude', 
        selectedPrompt: data.promptId 
      }
    });
  }
}

// Create a singleton instance
const apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000');

export default apiClient;