import React from 'react';
import { Box, Toolbar, Typography, List, ListItem, ListItemIcon, ListItemText, Tooltip, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { 
  TestTubeDiagonal, 
  FileText, 
  Star, 
  ListOrdered, 
  FileEdit,
  ImageIcon, 
  Settings,
  CreditCard,
  Sparkles,
  PenLine, 
  Link2,
  ChevronLeft,
  ChevronRight,
  Facebook
} from 'lucide-react';
import GlobalModelSelector from './GlobalModelSelector';

const DrawerContent = ({ isGenerating = false, isCollapsed, setIsCollapsed }) => {
  const location = useLocation();
  
  const isCurrentPath = (path) => location.pathname === path;

  const iconColors = {
    '/articles/single': '#3B82F6',
    '/articles/standard': '#10B981',
    '/articles/reviews': '#F59E0B',
    '/articles/lists': '#8B5CF6',
    '/url-rewriter': '#4F46E5',
    '/articles/rewrites': '#6B7280',
    '/prompts/new': '#E04C16',
    '/prompts/saved': '#14B8A6',
    '/social/facebook': '#2563EB',
    '/social/facebookideas': '#EA580C',
    '/images': '#EC4899',
    '/subscription': '#059669',
    '/dashboard': '#4B5563',
  };

  const MenuLink = ({ to, icon, text, indent = false }) => (
    <Tooltip 
      title={isCollapsed ? text : (isGenerating ? "Please wait until article generation is complete" : "")}
      placement="right"
    >
      <ListItem
        component={Link}
        to={to}
        disabled={isGenerating}
        onClick={(e) => {
          if (isGenerating) {
            e.preventDefault();
          }
        }}
        sx={{
          pl: isCollapsed ? 2 : 3,  
          py: 0.75,
          color: isGenerating ? 'text.disabled' : '#4B5563',
          textDecoration: 'none',
          borderRadius: '8px',
          mr: 1,  
          backgroundColor: isCurrentPath(to) ? 'rgba(224, 76, 22, 0.3)' : 'transparent',
          boxShadow: isCurrentPath(to) ? '0 0 12px rgba(224, 76, 22, 0.5)' : 'none',
          '&:hover': {
            backgroundColor: isGenerating ? 'transparent' : (isCurrentPath(to) ? 'rgba(224, 76, 22, 0.35)' : 'rgba(0, 0, 0, 0.05)'),
            transform: isGenerating ? 'none' : 'scale(1.02)',
            boxShadow: isGenerating ? 'none' : `0 0 12px ${iconColors[to] || '#E04C16'}30`,
          },
          pointerEvents: isGenerating ? 'none' : 'auto',
          opacity: isGenerating ? 0.5 : 1,
          transition: 'all 0.3s ease-in-out',
          minWidth: 0,  
          whiteSpace: 'nowrap',  
          overflow: 'hidden',  
        }}
      >
        <ListItemIcon sx={{ 
          minWidth: isCollapsed ? 0 : 40,
          justifyContent: 'center'
        }}>
          {React.cloneElement(icon, { 
            size: 22,
            color: isGenerating ? '#999999' : (isCurrentPath(to) ? iconColors[to] : iconColors[to] || '#E04C16'),
            strokeWidth: 1, // Thinner lines for menu icons
          })}
        </ListItemIcon>
        {!isCollapsed && (
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              variant: 'body1',
              sx: {
                fontSize: '1rem',
                fontWeight: isCurrentPath(to) ? 600 : 500,  
                color: isGenerating ? 'text.disabled' : (isCurrentPath(to) ? '#E04C16' : '#4B5563'),
                letterSpacing: '0.02em',
                fontFamily: 'Poppins, Inter, sans-serif',
              }
            }}
          />
        )}
      </ListItem>
    </Tooltip>
  );

  const SectionTitle = ({ text }) => {
    if (isCollapsed) return null;
    return (
      <Typography
        variant="overline"
        sx={{
          pl: 3,
          mb: 1,
          mt: 2,
          display: 'block',
          color: isGenerating ? '#999999' : '#6B7280',
          fontSize: '0.85rem',
          fontWeight: 600,
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          fontFamily: 'Poppins, Inter, sans-serif',
          opacity: 0.8,
        }}
      >
        {text}
      </Typography>
    );
  };

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative',
      width: '100%',  
      overflowX: 'hidden',
      background: 'linear-gradient(180deg, #F9FAFB 0%, #F3F4F6 100%)',
      boxShadow: '2px 0 10px rgba(0, 0, 0, 0.05)',
    }}>
      
      {/* Collapse Toggle Button */}
      <IconButton
        onClick={() => setIsCollapsed(!isCollapsed)}
        sx={{
          position: 'absolute',
          right: 8,  
          top: '50%',
          transform: 'translate(50%, -50%)',  
          backgroundColor: '#FFFFFF',
          border: '1px solid #E5E7EB',
          width: 32,
          height: 32,
          padding: 0,
          zIndex: 1200,
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#F3F4F6',
            borderColor: '#D1D5DB',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            transform: 'translate(50%, -50%) scale(1.05)',
          },
          transition: 'all 0.3s ease-in-out',
        }}
      >
        {isCollapsed ? 
          <ChevronRight size={20} color="#6B7280" strokeWidth={1} /> : // Thinner chevrons too
          <ChevronLeft size={20} color="#6B7280" strokeWidth={1} />
        }
      </IconButton>

      {/* Logo Section */}
      <Toolbar sx={{ 
        px: 2,
        justifyContent: isCollapsed ? 'center' : 'flex-start',
        minHeight: '64px !important',
        position: 'relative',
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          pl: isCollapsed ? 0 : -0.5,
        }}>
          <Box sx={{
            width: 36,
            height: 36,
            borderRadius: '50%',
            background: 'linear-gradient(135deg, #E04C16 0%, #8B5CF6 50%, #3B82F6 100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            animation: 'pulse-sparkle-bg 3s infinite ease-in-out',
          }}>
            <Sparkles 
              size={24} 
              color="#FFFFFF"
              strokeWidth={1} // Thinner lines for Sparkles
              className="animate-pulse-sparkle"
            />
          </Box>
          {!isCollapsed && (
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Poppins, Inter, sans-serif',
                  fontSize: '1.75rem',
                  fontWeight: 700,
                  color: '#1F2937',
                  letterSpacing: '-0.02em',
                }}
              >
                Scripti
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  top: '55%',
                  right: -45,
                  backgroundColor: '#E04C16',
                  color: 'white',
                  fontSize: '0.6rem',
                  padding: '2px 6px',
                  borderRadius: '10px',
                  fontWeight: 600,
                  letterSpacing: '0.02em',
                  transform: 'translateY(-50%)',
                  fontFamily: 'Poppins, Inter, sans-serif',
                  boxShadow: '0 0 8px rgba(224, 76, 22, 0.5)',
                }}
              >
                BETA
              </Box>
            </Box>
          )}
        </Box>
      </Toolbar>

      {/* Menu Items */}
      <List sx={{ px: 1, py: 1, flex: 1 }}>
        <SectionTitle text="CONTENT CREATION" />
        <MenuLink to="/articles/single" icon={<FileText />} text="Single Article Creator" />
        <MenuLink to="/articles/standard" icon={<FileText />} text="Bulk Article Creator" />
        <MenuLink to="/articles/reviews" icon={<Star />} text="Product Reviews" />
        <MenuLink to="/articles/lists" icon={<ListOrdered />} text="Listicle Articles" />
        <MenuLink to="/url-rewriter" icon={<Link2 />} text="URL Rewriter" /> 
        <MenuLink to="/articles/rewrites" icon={<FileEdit />} text="Article Rewrites" />
        <SectionTitle text="PROMPT LAB" />
        <MenuLink to="/prompts/new" icon={<PenLine />} text="New Prompt" />
        <MenuLink to="/prompts/saved" icon={<TestTubeDiagonal />} text="Saved Prompts" />
        <SectionTitle text="SOCIAL CONTENT" />
        <MenuLink to="/social/facebook" icon={<Facebook />} text="Facebook Images" />
        <MenuLink to="/social/facebookideas" icon={<Facebook />} text="Facebook Ideas" />
        <SectionTitle text="IMAGE TOOLS" />
        <MenuLink to="/images" icon={<ImageIcon />} text="Image Generator" />
      </List>

      {/* Bottom Section */}
      <Box sx={{ 
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        pt: 2,
        px: 1,
        opacity: isGenerating ? 0.5 : 1,
        pointerEvents: isGenerating ? 'none' : 'auto',
        backgroundColor: '#F3F4F6',
      }}>
        {!isCollapsed && (
          <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px', p: 1, mb: 1, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)' }}>
            <GlobalModelSelector />
          </Box>
        )}
        <MenuLink to="/subscription" icon={<CreditCard />} text="Subscription" />
        <MenuLink to="/dashboard" icon={<Settings />} text="Settings" />
      </Box>
    </Box>
  );
};

export default DrawerContent;